import { createSlice, createAction, createAsyncThunk, AsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import * as models from 'api/models/growing-locations';
import { ProblemDetails } from 'utils/problem-details';
import { sortBy, sortSizeName } from 'utils/sort';
import { RootState } from 'app/store';
import { growingLocationApi, GrowingLocationIndexResponse as GrowingLocationListResponse, WishListProductsResponse } from 'api/growing-location-service';
import { contains } from 'utils/equals';

interface GrowingLocationState {
  locations: models.GrowingLocationListItem[] | null;
  wishListProducts: models.WishListProduct[] | null;
  search: string;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: GrowingLocationState = {
  locations: null,
  wishListProducts: null,
  search: '',
  loading: false,
  error: null
};

export const getGrowingLocations: AsyncThunk<GrowingLocationListResponse, number, {state: RootState}> = createAsyncThunk(
  'growing-locations/getGrowingLocations',
  async (year, {rejectWithValue}) => {
    try {
      return await growingLocationApi.getAll(year);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const getWishListProducts: AsyncThunk<WishListProductsResponse, number, {state: RootState}> = createAsyncThunk(
  'growing-locations/getWishListProducts',
  async (year, {rejectWithValue}) => {
    try {
      return await growingLocationApi.wishListProducts(year);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getGrowingLocationsPending = createAction(getGrowingLocations.pending.type),
  getGrowingLocationsFulfilled = createAction<GrowingLocationListResponse>(getGrowingLocations.fulfilled.type),
  getGrowingLocationsRejected = createAction<ProblemDetails>(getGrowingLocations.rejected.type),
  getWishListProductsFulfilled = createAction<WishListProductsResponse>(getWishListProducts.fulfilled.type),
  getWishListProductsRejected = createAction<ProblemDetails>(getWishListProducts.rejected.type);

export const growingLocationSlice = createSlice({
  name: 'growing-locations',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
      state.wishListProducts = null;
    },
    clearState(state) {
      state.locations = null;
      state.wishListProducts = null;
      state.search = '';
      state.error = null;
      state.loading = false;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getGrowingLocationsPending, state => {
        state.loading = true;
        state.error = null
      })
      .addCase(getGrowingLocationsFulfilled, (state, action) => {
        state.loading = false;
        const {locations} = action.payload;
        state.locations = locations.filter(l => l.id > 0);
      })
      .addCase(getGrowingLocationsRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getWishListProductsFulfilled, (state, action) => {
        state.wishListProducts = action.payload.products;
      })
      .addCase(getWishListProductsRejected, (state, action) => {
        state.error = action.payload;
      })
  }
});

export const { clearError, clearState, setSearch } = growingLocationSlice.actions;

export const selectSearch = (state: RootState) => state.growingLocationList.search;
export const selectLocations = createSelector(
  selectSearch,
  (state: RootState) => state.growingLocationList.locations,
  (search, locations) => {
    if(!search || !locations) {
      return locations;
    }
    const searchTerms = search.split(' ');

    return locations.filter(l => searchTerms.every(st => contains(l.name, st)) || searchTerms.every(st => contains(l.products, st)));
  }
);
export const selectWishListProducts = (state: RootState) => state.growingLocationList.wishListProducts;
export const selectLoading = (state: RootState) => state.growingLocationList.loading;
export const selectError = (state: RootState) => state.growingLocationList.error;

export interface GrowingLocationWishListProductSize {
  key: string;
  wishListProductId: number;
  sizeId: number;
  productName: string;
  sizeName: string;
}

const sortByProductName = sortBy('productName');

export function makeKey(product: models.WishListProduct, size: models.WishListProductSize) {
  return `${product.id}|${size.sizeId}`;
}

export function parseKey(key: string) {
  const [a, b] = key.split('|'),
    wishListProductId = parseInt(a) || 0,
    sizeId = parseInt(b) || 0;

  return {wishListProductId, sizeId};
}

export const selectWishListProductSizes = createSelector(
  (state: RootState) => state.growingLocationList.wishListProducts,
  allProducts =>
    (allProducts || [])
      .reduce((memo, product) => {
        product.sizes.forEach(size => {
          const key = makeKey(product, size);
          if(!memo.some(s => s.key === key)) {
            memo.push({key, wishListProductId: product.id, sizeId: size.sizeId, sizeName: size.sizeName, productName: product.productName});
          }
        });
        return memo;
      }, [] as GrowingLocationWishListProductSize[])
      .sort((a, b) => {
        const productSort = sortByProductName(a, b);
        if(productSort !== 0) {
          return productSort;
        }

        return sortSizeName(a.sizeName, b.sizeName)
      })
)

export default growingLocationSlice.reducer;
