import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

const year = new Date().getFullYear();

export interface PropagationState {
  year: number;
}

const initialState: PropagationState = {
  year
};

export const propagationSlice = createSlice({
  name: 'propagation',
  initialState,
  reducers: {
    setYear(state, action: PayloadAction<number>) {
      state.year = action.payload;
    }
  }
});

export const {setYear} = propagationSlice.actions;

export const selectYear = (state: RootState) => state.propagation.year;
export const selectYears = createSelector(
  selectYear,
  year => [year - 2, year - 1, year, year + 1, year + 2]
);

export default propagationSlice.reducer;
