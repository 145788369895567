import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { Profile } from 'oidc-client';
import { RootState } from '../../app/store';

export interface AuthState {
    user: Profile | null;
}

const initialState: AuthState = {
    user: null
};

export interface SetAuthenticationArgs {
    user: Profile | null;
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticated: (state, action: PayloadAction<SetAuthenticationArgs>) => {
            state.user = action.payload.user;
        }
    }
});

export const {setAuthenticated} = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const selectAuthenticated = createSelector(
    selectUser,
    user => !!user
);

export const selectPermissions = createSelector(
    selectUser,
    (user): string[] => {
        if(user && Array.isArray(user?.permission)) {
            return user.permission;
        } else if(typeof user?.permission === 'string') {
            return [user.permission];
        }

        return [];
    }
)

export default authSlice.reducer;
