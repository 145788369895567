import { createSlice, AsyncThunk, createAsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import * as models from 'api/models/wish-list';
import { setProduct, setDirty, selectSize, selectProduct, setSpaceAllocated, sortSize, setSpaceAvailable } from '../product-detail-slice';
import { refreshSeasonalAllocations } from './seasonal-wish-list';
import * as capacity from './seasonal-capacity';
import { formatNumber } from 'utils/format';
import { sortBy, sortSizeName } from 'utils/sort';
import { growingLocationApi, GrowingLocationAvailabilitiesModel } from 'api/growing-location-service';

const sortByGrowingLocationPriorityTight = sortBy('growingLocationPriorityTight'),
  sortByGrowingLocationPrioritySpaced = sortBy('growingLocationPrioritySpaced'),
  sortByWishListProductPriority = sortBy('wishListProductPriority');

export interface WishListProductSeasonalState {
  activeWeek: AllocationSizeWeek | null;
};

const initialState: WishListProductSeasonalState = {
  activeWeek: null
};

export interface SetProductSizeValueArgs<T> {
  value: T;
  productSizeId: number;
}

export interface SetProductSizeWeekValueArgs<T> extends SetProductSizeValueArgs<T> {
  weekId: number;
}

export interface SetProductSizeColourValueArgs<T> extends SetProductSizeValueArgs<T> {
  colourId: number;
}

export type WishListProductSizeProperty = keyof models.WishListProductSize;
type PropType<TProp extends WishListProductSizeProperty> = models.WishListProductSize[TProp] | null;

export interface SetProductSizeConfigurationValueArgs extends SetProductSizeValueArgs<PropType<WishListProductSizeProperty>> {
  property: WishListProductSizeProperty;
}

export const refreshAllocations: AsyncThunk<void, void, {state: RootState}> = createAsyncThunk(
  'wish-list-seasonal/refreshAllocations',
  async (_, {getState, dispatch}) => {
    const rootState = getState(),
      {spaceAvailable, spaceAllocated} = rootState.wishListProductDetail,
      product = rootState.wishListProductDetail.product,
      weeks = rootState.wishListHome.weeks || [];
      
    if(product) {
      const allocated = refreshSeasonalAllocations(product, spaceAvailable, spaceAllocated, weeks);
      dispatch(setSpaceAllocated(allocated));

      dispatch(setDirty(true));
    }
  }
);

export const setConfigurationValue: AsyncThunk<void, SetProductSizeConfigurationValueArgs, {state: RootState}> = createAsyncThunk(
  'wish-list-seasonal/setConfigurationValue',
  async (args, {getState, dispatch}) => {
    const rootState = getState(),
      state = rootState.wishListProductDetail,
      {spaceAvailable, spaceAllocated} = rootState.wishListProductDetail,
      weeks = rootState.wishListHome.weeks || [],
      {productSizeId, property, value} = args;
      
    if(state.product) {
      const product = {...state.product},
        sizes = product.sizes.map(s => ({...s})),
        size = sizes.find(s => s.id === productSizeId);

      if(size && property in size) {
        // @ts-ignore
        size[property] = value;

        if(property === 'plantWeekId' && size.salesStartWeekId > size.plantWeekId) {
          size.productionWeeks = size.salesStartWeekId - size.plantWeekId;
        } else if(property === 'defaultTightLocationId') {
          const growingLocation = rootState.wishListHome.growingLocations?.find(l => l.id === size.defaultTightLocationId);
          if(growingLocation?.isHangingLocation) {
            size.potsPerSquareFootTight = 1;
          }
        } else if(property === 'defaultSpacedLocationId') {
          const growingLocation = rootState.wishListHome.growingLocations?.find(l => l.id === size.defaultSpacedLocationId);
          if(growingLocation?.isHangingLocation) {
            size.potsPerSquareFootSpaced = 1;
          }
        } else if(property === 'productionWeeks') {
          size.plantWeekId = size.salesStartWeekId - size.productionWeeks;
        }

        const allocated = refreshSeasonalAllocations(product, spaceAvailable, spaceAllocated, weeks);
        dispatch(setSpaceAllocated(allocated));

        product.sizes = sizes;
        dispatch(setProduct(product));

        dispatch(setDirty(true));
      }
    }
  }
);

export const setSalesStartEndWeekId: AsyncThunk<void, SetProductSizeConfigurationValueArgs, {state: RootState}> = createAsyncThunk(
  'wish-list-seasonal/setSalesStartEndWeekId',
  async (args, {getState, dispatch}) => {
    const rootState = getState(),
      state = rootState.wishListProductDetail,
      {spaceAvailable, spaceAllocated} = rootState.wishListProductDetail,
      weeks = rootState.wishListHome.weeks || [],
      {productSizeId, property, value} = args;
      
    if(state.product) {
      const product = {...state.product},
        sizes = product.sizes.map(s => ({...s})),
        size = sizes.find(s => s.id === productSizeId);

      if(size && property in size) {
        // @ts-ignore
        size[property] = value;

        let id = size.sales.reduce((min, s) => Math.min(min, s.id), 0) - 1;

        const existingSalesWeeks = size.sales.reduce((memo, s) => memo.set(s.weekId, s), new Map<number, models.WishListProductSizeSalesWeek>()),
          sales = weeks
            .filter(w => w.id >= size.salesStartWeekId && w.id <= size.salesEndWeekId)
            .map(w => existingSalesWeeks.get(w.id) || { id: id--, weekId: w.id, percentSold: null});

        size.sales = sales;

        if(size.plantWeekId && size.salesStartWeekId > size.plantWeekId) {
          size.productionWeeks = size.salesStartWeekId - size.plantWeekId
        }

        const allocated = refreshSeasonalAllocations(product, spaceAvailable, spaceAllocated, weeks);
        dispatch(setSpaceAllocated(allocated));

        product.sizes = sizes;
        dispatch(setProduct(product));

        dispatch(setDirty(true));
      }
    }
  }
);

export const setSalesPercentSold: AsyncThunk<void, SetProductSizeWeekValueArgs<number | null>, {state: RootState}> = createAsyncThunk(
  'wish-list-seasonal/setSalesPercentSold',
  async (args, {getState, dispatch}) => {
    const rootState = getState(),
      state = rootState.wishListProductDetail,
      {spaceAvailable, spaceAllocated} = rootState.wishListProductDetail,
      weeks = rootState.wishListHome.weeks || [],
      {productSizeId, weekId, value} = args;
      
    if(state.product) {
      const product = {...state.product},
        sizes = product.sizes.map(s => ({...s})),
        size = sizes.find(s => s.id === productSizeId);

      if(size) {
        const sales = size.sales.map(s => ({...s})),
          salesItem = sales.find(s => s.weekId === weekId);

        if(salesItem) {
          salesItem.percentSold = value;
          size.sales = sales;

          const allocated = refreshSeasonalAllocations(product, spaceAvailable, spaceAllocated, weeks);
          dispatch(setSpaceAllocated(allocated));

          product.sizes = sizes;
          dispatch(setProduct(product));

          dispatch(setDirty(true));
        }
      }
    }
  }
);

export const setColourWishListQuantity: AsyncThunk<void, SetProductSizeColourValueArgs<number>, {state: RootState}> = createAsyncThunk(
  'wish-list-seasonal/setColourWishListQuantity',
  async (args, {getState, dispatch}) => {
    const rootState = getState(),
      state = rootState.wishListProductDetail,
      {spaceAvailable, spaceAllocated} = rootState.wishListProductDetail,
      weeks = rootState.wishListHome.weeks || [],
      {productSizeId, colourId, value} = args;
      
    if(state.product) {
      const product = {...state.product},
        sizes = product.sizes.map(s => ({...s})),
        size = sizes.find(s => s.id === productSizeId);

      if(size) {
        const colours = size.colours.map(c => ({...c})),
          colour = colours.find(c => c.colourId === colourId);

        if(colour) {
          colour.wishListQuantity = value;
        }
        
        size.colours = colours;

        product.sizes = sizes;
        dispatch(setProduct(product));

        const allocated = refreshSeasonalAllocations(product, spaceAvailable, spaceAllocated, weeks);
        dispatch(setSpaceAllocated(allocated));

        dispatch(setDirty(true));
      }
    }
  }
);

export const addGrowingLocationAvailabilities: AsyncThunk<void, GrowingLocationAvailabilitiesModel, {state: RootState}> = createAsyncThunk(
  'wish-list-seasonal/addGrowingLocationAvailabilities',
  async (model, {getState, dispatch}) => {
    try {

      const response = await growingLocationApi.addAvailabilities(model),
        rootState = getState(),
        { spaceAvailable, product, spaceAllocated } = rootState.wishListProductDetail,
        weeks = rootState.wishListHome.weeks || [],
        available = spaceAvailable.concat(response.available);

      dispatch(setSpaceAvailable(available));

      if(product) {
        const allocated = refreshSeasonalAllocations(product, available, spaceAllocated, weeks);
        dispatch(setSpaceAllocated(allocated));

        dispatch(setDirty(true));
      }

    } catch(e) {
      throw e;
    }
  }
);

export const seasonalSlice = createSlice({
  name: 'wish-list-detail-seasonal',
  initialState,
  reducers: {
    setActiveWeek(state, action: PayloadAction<AllocationSizeWeek | null>) {
      state.activeWeek = action.payload;
    }
  }
});

export const { setActiveWeek } = seasonalSlice.actions;

export default seasonalSlice.reducer;

interface GrowingLocationWeekAllocationDisplay {
  productSizeName: string;
  squareFeet: number;
}

export interface WishListProductSizeSummary {
  id: number;
  sizeId: number;
  sizeName: string;
  roundName: string;
  roundNumber: number;
  wishListQuantity: number;
  maxSpaceTight: number;
  maxSpace: number;
  minimumTight: WishListProductSizeSummaryMinimum | null;
  minimumSpaced: WishListProductSizeSummaryMinimum | null;
}

export interface WishListProductSizeSummaryMinimum {
  week: models.Week;
  requiredSpace: number;
  availableLocations: string[];
  totalAvailableSpace: number;
  usedSpace: number;
  available: number;
  remaining: number;
  otherSizes: string[];
  isHangingLocation: boolean;
}

const sortByRoundNumber = sortBy('roundNumber');
export const sortProductSizes = (a: models.WishListProductSize, b: models.WishListProductSize) => sortByRoundNumber(a, b) || sortSizeName(a.sizeName, b.sizeName);

export interface WishListAllocationUsed {
  productSize: string;
  space: number;
}

export interface WishListAllocationDetail {
  wishlistProductSizeId: number;
  wishListProductId: number;
  sizeId: number;
  week: models.Week;
  totalAvailableSpace: number;
  requiredSpace: number;
  availableSpace: number;
  usedSpace: number;
  used: WishListAllocationUsed[];
  remainingSpace: number;
  isHangingLocation: boolean;
}

export const selectActiveWeek = (state: RootState) => state.wishListSeasonalProduct.activeWeek;
export const selectSpaceAllocated = (state: RootState) => state.wishListProductDetail.spaceAllocated;
export const selectSpaceAvailable = (state: RootState) => state.wishListProductDetail.spaceAvailable;
const selectWeeks = (state: RootState) => state.wishListHome.weeks || [];

export const selectDetails = createSelector(
  selectProduct,
  selectWeeks,
  selectSpaceAvailable,
  selectSpaceAllocated,
  (product, allWeeks, spaceAvailable, spaceAllocated) => {
    if(!product || !allWeeks) {
      return [];
    }

    const sizes = product.sizes.map(s => ({...s})).sort(sortSize),
      start = sizes.reduce((min, size) => Math.min(min, size.plantWeekId), Number.MAX_VALUE),
      end = sizes.reduce((endWeek, size) => Math.max(endWeek, size.sales.reduce((max, s) => Math.max(max, s.weekId), 0)), 0),
      details: WishListAllocationDetail[] = [],
      availableByWeek = spaceAvailable
        .reduce((memo, a) => {
          const availableForWeek = memo.get(a.weekId) || [];

          if(a.wishListProductId === product.id) {
            const isAvailable = sizes.some(size => {
              const isTightlySpaced = capacity.isTightlySpaced(size, a.weekId),
                isProductionSpaced = capacity.isSpaced(size, a.weekId);

              return (isTightlySpaced && a.allowTight) || (isProductionSpaced && a.allowSpaced);
            });

            if(isAvailable) {
              availableForWeek.push(a);
              }
          } else {
            availableForWeek.push(a);
          }

          return memo.set(a.weekId, availableForWeek);

        }, new Map<number, models.WishListSpaceAvailable[]>()),
      allocatedByWeek = spaceAllocated
        .reduce((memo, a) => {
          const allocatedForWeek = memo.get(a.weekId) || [];
          allocatedForWeek.push(a);
          return memo.set(a.weekId, allocatedForWeek);
        }, new Map<number, models.WishListSpaceAllocated[]>()),
      weeks = allWeeks.filter(w => w.id >= start && w.id <= end);

      weeks.forEach(week => {
        const allocatedForWeek = (allocatedByWeek.get(week.id) || []),
          availableForWeek = (availableByWeek.get(week.id) || []),
          availableLocationProducts = availableForWeek
            .reduce((memo, a) => {
              if(!memo.some(m => m.growingLocationId === a.growingLocationId && m.wishListProductId === a.wishListProductId)) {
                memo.push(a);
              }
              return memo;
            }, [] as models.WishListSpaceAvailable[]),
          availableLocations = availableLocationProducts
            .reduce((memo, a) => {
              if(!memo.some(m => m.growingLocationId === a.growingLocationId)) {
                memo.push(a);
              }
              return memo;
            }, [] as models.WishListSpaceAvailable[])
            .sort((a, b) => sortByGrowingLocationPriorityTight(a, b) || sortByGrowingLocationPrioritySpaced(a, b)), //<== Need to move this into the size loop
          totalAvailableSpace = availableLocations.reduce((total, a) => total + a.space, 0);
  
        availableLocations.forEach(location => {
          let availableInLocation = location.space,
            usedProductSizes: models.WishListSpaceAvailable[] = [];
  
          const wishListProductPriorities = availableLocationProducts
            .filter(a => a.growingLocationId === location.growingLocationId)
            .reduce((memo, a) => {
              if(!memo.some(m => m.wishListProductId === a.wishListProductId)) {
                memo.push(a);
              }
              return memo;
            }, [] as models.WishListSpaceAvailable[])
            .sort((a, b) => {
              if(a.wishListProductId === product.id && b.wishListProductId === product.id) {
                return sortByRoundNumber(a, b) || sortSizeName(a.sizeName, b.sizeName);
              }
  
              return sortByWishListProductPriority(a, b);
            });
  
          wishListProductPriorities.forEach(wishListProduct => {
            if(wishListProduct.wishListProductId === product.id) {
              sizes.forEach(size => {
                const requiredSpace = capacity.squareFeet(size, week.id),
                  usedSpace = Math.max(0, Math.min(requiredSpace, availableInLocation)),
                  remainingSpace = availableInLocation - requiredSpace,
                  availableSpace = availableInLocation,
                  used = Array.from(usedProductSizes.reduce((memo, a) => {
                      const name = `${a.sizeName} ${a.productName}`,
                        quantity = memo.get(name) || 0;
                      return memo.set(name, quantity + a.space);
                    }, new Map<string, number>())
                    .entries())
                    .map(entry => {
                      const allocationUsed: WishListAllocationUsed = {productSize: entry[0], space: entry[1]};
                      return allocationUsed
                    });
  
                if(requiredSpace) {
                  const detail: WishListAllocationDetail = {
                    wishlistProductSizeId: size.id,
                    wishListProductId: product.id,
                    sizeId: size.sizeId,
                    week,
                    totalAvailableSpace,
                    requiredSpace,
                    availableSpace,
                    usedSpace,
                    used,
                    remainingSpace,
                    isHangingLocation: location.isHangingLocation
                  };

                  details.push(detail);
  
                  if(usedSpace) {
                    usedProductSizes.push(wishListProduct);
                    availableInLocation -= usedSpace;
                  }
                }
              });
            } else {
              const wishListProductSizes = allocatedForWeek.filter(a => a.wishListProductId === wishListProduct.wishListProductId && a.sizeId === wishListProduct.sizeId);
  
              if(wishListProductSizes.length) {
                const wishListProductSpace = wishListProductSizes.reduce((total, a) => total + a.space, 0),
                  space = Math.max(0, Math.min(wishListProductSpace, availableInLocation));
  
                if(space) {
                  availableInLocation -= space;
                  usedProductSizes.push(wishListProduct);
                }
              }
            }
          });
        });
      });

    return details;
  });

  export interface AllocationSize {
    weeks: AllocationSizeWeek[];
  }

  export interface AllocationSizeWeek {
    week: models.Week;
    isHangingLocation: boolean;
    locations: AllocationSizeWeekLocation[];
    quantityInInventory: number;
    squareFeetTight: number;
    squareFeetSpaced: number;
    totalSquareFeet: number;
    allocatedSpace: number;
    unallocatedSpace: number;
  }

  export interface AllocationSizeWeekLocation {
    weekId: number;
    growingLocationId: number;
    growingLocationName: string;
    growingLocationPriorityTight: number;
    growingLocationPrioritySpaced: number;
    isHangingLocation: boolean;
    totalSpace: number;
    used: WishListAllocationUsed[];
    usedSpace: number;
    availableSpace: number;
    squareFeet: number;
    remainingSpace: number;
  }

  export const selectAllocationSize = createSelector(
    selectProduct,
    selectSize,
    selectWeeks,
    selectSpaceAvailable,
    selectSpaceAllocated,
    (product, size, allWeeks, spaceAvailable, spaceAllocated) => {
      if(!product || !size || !allWeeks) {
        return {weeks: []};
      }

      const start = size.plantWeekId,
        end = size.sales.reduce((max, s) => Math.max(max, s.weekId), 0),
        allocations: AllocationSizeWeek[] = [],
        wishListProductPriorities = spaceAvailable.reduce((memo, a) => {
          if(!memo.has(a.wishListProductId)) {
            memo.set(a.wishListProductId, a.wishListProductPriority);
          }
          return memo;
        }, new Map<number, number>()),
        productPriority = spaceAvailable.find(a => a.wishListProductId === product.id)?.wishListProductPriority || 1,
        roundNumbers = product.sizes.reduce((memo, s) => {
          if(!memo.has(s.id)) {
            memo.set(s.id, s.roundNumber);
          }
          return memo;
        }, new Map<number, number>()),
        sizeRoundNumber = size.roundNumber;

        if(start && end && start <= end) {
          const availableByWeek = spaceAvailable
            .reduce((memo, a) => {
              const availableForWeek = memo.get(a.weekId) || [];
              availableForWeek.push(a);
              return memo.set(a.weekId, availableForWeek);
            }, new Map<number, models.WishListSpaceAvailable[]>()),
          allocatedByWeek = spaceAllocated
            .reduce((memo, a) => {
              const allocatedForWeek = memo.get(a.weekId) || [];
              allocatedForWeek.push(a);
              return memo.set(a.weekId, allocatedForWeek);
            }, new Map<number, models.WishListSpaceAllocated[]>()),
          weeks = allWeeks.filter(w => w.id >= start && w.id <= end);
          
          weeks.forEach(week => {
            const allocatedForWeek = (allocatedByWeek.get(week.id) || []),
              availableForWeek = (availableByWeek.get(week.id) || []),
              isTight = capacity.isTightlySpaced(size, week.id),
              availableLocations = availableForWeek
                .filter(a => a.wishListProductId === product.id)
                .reduce((memo, a) => {
                  if(!memo.some(m => m.growingLocationId === a.growingLocationId)) {
                    memo.push(a);
                  }
                  return memo;
                }, [] as {growingLocationId: number, growingLocationName: string, isHangingLocation: boolean, growingLocationPriorityTight: number, growingLocationPrioritySpaced: number, space: number}[])
                .sort(isTight ? sortByGrowingLocationPriorityTight : sortByGrowingLocationPrioritySpaced);

            const totalSquareFeet = capacity.squareFeet(size, week.id),
              squareFeetTight = capacity.isTightlySpaced(size, week.id) ? totalSquareFeet : 0,
              squareFeetSpaced = squareFeetTight ? 0 : totalSquareFeet,
              totalQuantity = size.colours.reduce((total, c) => total + c.wishListQuantity, 0),
              sold = size.sales.filter(s => s.weekId < week.id).reduce((total, s) => total + ((s.percentSold || 0) / 100) * totalQuantity, 0),
              allocatedForSize = allocatedForWeek
                .filter(a => a.wishListProductSizeId === size.id),
              unallocatedSpace = allocatedForSize
                .filter(a => a.growingLocationId === 0)
                .reduce((total, a) => total + a.space, 0),
              allocatedSpace = allocatedForSize
                .filter(a => a.growingLocationId > 0)
                .reduce((total, a) => total + a.space, 0),
              locations = availableLocations.map(location => {
                const {growingLocationId, growingLocationName, isHangingLocation, space: totalSpace, growingLocationPriorityTight, growingLocationPrioritySpaced} = location,
                  locationAllocations = allocatedForWeek
                    .filter(a => a.growingLocationId === location.growingLocationId),
                  sizeAllocations = locationAllocations.filter(a => a.wishListProductSizeId === size.id),
                  otherAllocations = locationAllocations.filter(a => {
                    if(a.wishListProductSizeId === size.id) {
                      return false;
                    }
                    if(a.wishListProductId === product.id) {
                      return (roundNumbers.get(a.wishListProductSizeId) ?? 1) <= sizeRoundNumber;
                    } else {
                      return (wishListProductPriorities.get(a.wishListProductId) ?? 1) <= productPriority;
                    }
                  }),
                  squareFeet = sizeAllocations.reduce((total, a) => total + a.space, 0),
                  usedSpace = otherAllocations.reduce((total, a) => total + a.space, 0),
                  used = Array.from(otherAllocations
                    .reduce((memo, a) => {
                      const name = `${a.sizeName} ${a.productName}`,
                        space = a.space,
                        quantity = memo.get(name) || 0;
                      return memo.set(name, quantity + space);
                    }, new Map<string, number>())
                    .entries())
                    .map(entry => {
                      const allocationUsed: WishListAllocationUsed = {productSize: entry[0], space: entry[1]};
                      return allocationUsed
                    }),
                  availableSpace = totalSpace - usedSpace,
                  remainingSpace = availableSpace - squareFeet,
                  allocationSizeWeekLocation: AllocationSizeWeekLocation = {weekId: week.id, growingLocationId, growingLocationName, growingLocationPriorityTight, growingLocationPrioritySpaced, isHangingLocation, totalSpace, availableSpace, used, usedSpace, remainingSpace, squareFeet};
    
                return allocationSizeWeekLocation;
              }).sort(sortBy('growingLocationName')),
              allocationSizeWeek: AllocationSizeWeek = {
                week,
                isHangingLocation: availableLocations.some(l => l.isHangingLocation),
                locations,
                totalSquareFeet,
                squareFeetTight,
                squareFeetSpaced,
                quantityInInventory: Math.max(0, totalQuantity - sold),
                allocatedSpace,
                unallocatedSpace
              };

              allocations.push(allocationSizeWeek);
            });
        }

      const allocationSize: AllocationSize = {weeks: allocations};
      return allocationSize;
    }
  )

  export const selectSummary = createSelector(
    selectProduct,
    selectWeeks,
    selectSpaceAvailable,
    selectSpaceAllocated,
    (product, allWeeks, spaceAvailable, spaceAllocated) => {
      if(!product || !allWeeks) {
        return [];
      }

      const summary = product.sizes
        .map(s => ({...s}))
        .sort(sortProductSizes)
        .map(size => {
          const wishListQuantity = size.colours.reduce((total, c) => total + c.wishListQuantity, 0),
            maxSpaceTight = size.potsPerSquareFootTight ? Math.ceil(wishListQuantity / size.potsPerSquareFootTight) : 0,
            maxSpace = size.potsPerSquareFootSpaced ? Math.ceil(wishListQuantity / size.potsPerSquareFootSpaced) : 0,
            initial: WishListProductSizeSummary = {
              id: size.id,
              sizeId: size.sizeId,
              sizeName: size.sizeName,
              roundName: size.roundName || '',
              roundNumber: size.roundNumber || 0,
              wishListQuantity,
              maxSpace,
              maxSpaceTight,
              minimumTight: null,
              minimumSpaced: null
            },
            wishListProductPriorities = spaceAvailable.reduce((memo, a) => {
              if(!memo.has(a.wishListProductId)) {
                memo.set(a.wishListProductId, a.wishListProductPriority);
              }
              return memo;
            }, new Map<number, number>()),
            availableByWeek = spaceAvailable              
              .reduce((memo, a) => {
                const available = memo.get(a.weekId) || [];
                available.push(a);
                return memo.set(a.weekId, available);
              }, new Map<number, models.WishListSpaceAvailable[]>()),
            allocatedForSize = spaceAllocated
              .filter(a => a.wishListProductSizeId === size.id)
              .reduce((memo, a) => {
                const allocated = memo.get(a.weekId) || [];
                allocated.push(a);
                return memo.set(a.weekId, allocated);
              }, new Map<number, models.WishListSpaceAllocated[]>()),
            allocatedForOther = spaceAllocated
              .filter(a => a.wishListProductSizeId !== size.id && a.growingLocationId > 0)
              .reduce((memo, a) => {
                const allocated = memo.get(a.weekId) || [];
                allocated.push(a);
                return memo.set(a.weekId, allocated);
              }, new Map<number, models.WishListSpaceAllocated[]>()),
            minimumWeek = Array.from(allocatedForSize.entries()).reduce((memo, kvp) => {
              const [weekId, all] = kvp,
                isTight = capacity.isTightlySpaced(size, weekId),
                week = allWeeks.find(w => w.id === weekId);
              if(week) {
                const availableForWeek = availableByWeek.get(weekId) || [],
                  wishListProductPriority = availableForWeek[0]?.wishListProductPriority || 1,
                  availableForSize = availableForWeek.filter(a => a.wishListProductId === product.id && a.sizeId === size.sizeId && ((isTight && a.allowTight) || (!isTight && a.allowSpaced))),
                  availableLocationsForWeek = availableForSize
                    .reduce((memo, a) => {
                      if(!memo.some(m => m.growingLocationId === a.growingLocationId)) {
                        memo.push(a);
                      }
                      return memo;
                    }, [] as models.WishListSpaceAvailable[]),
                  availableLocationIds = availableLocationsForWeek.map(a => a.growingLocationId),
                  availableLocations = availableLocationsForWeek.map(a => `${a.growingLocationName}: ${formatNumber(a.space)}`),
                  totalAvailableSpace = availableForSize.reduce((total, a) => total + a.space, 0),
                  otherAllocationsForWeek = (allocatedForOther.get(weekId) || [])
                    .filter(a => availableLocationIds.indexOf(a.growingLocationId) !== -1)
                    .filter(a => {
                      if(a.wishListProductId === product.id) {
                        const roundNumber = product.sizes.find(s => s.id === a.wishListProductSizeId)?.roundNumber || 1;
                        return roundNumber < size.roundNumber;
                      }
                      return (wishListProductPriorities.get(a.wishListProductId) || 1) < wishListProductPriority;
                      
                    }),
                  otherSizes = Array.from(otherAllocationsForWeek                    
                    .reduce((memo, a) => {
                      const key = `${a.sizeName} ${a.productName}`,
                        space = (memo.get(key) || 0) + a.space;
                      return memo.set(key, space);
                    }, new Map<string, number>())
                    .entries())
                    .map(kvp => `${kvp[0]}: ${formatNumber(kvp[1])}`),
                  isHangingLocation = all.some(a => a.isHangingLocation),
                  requiredSpace = capacity.squareFeet(size, weekId),
                  usedSpace = otherAllocationsForWeek
                    .reduce((total, a) => total + a.space, 0),
                  unallocated = all.filter(a => a.growingLocationId === 0).reduce((total, a) => total + a.space, 0),                
                  prop = isTight ? 'minimumTight' : 'minimumSpaced',
                  minimum = memo[prop],
                  available = Math.max(0, totalAvailableSpace - usedSpace),
                  remaining = unallocated > 0 ? -unallocated : available - requiredSpace;
                if(requiredSpace && (!minimum || remaining < minimum.remaining)) {
                  memo[prop] = {week, isHangingLocation, otherSizes, requiredSpace, usedSpace, available, remaining, availableLocations, totalAvailableSpace};
                }
              }
              return memo;
            }, initial);

            return minimumWeek;
          });

      return summary;
    }
  );

export const selectAllocationWeeks = createSelector(
  selectWeeks,
  selectSize,
  selectSpaceAllocated,
  selectSpaceAvailable,
  (weeks, size, spaceAllocated, spaceAvailable) => {
    const weekMap = new Map<number, AllocationWeek>();

    if(weeks && size) {
      const allocatedForSize = spaceAllocated.filter(a => a.wishListProductSizeId === size.id);

      weeks
      .filter(w => w.id >= size.plantWeekId && w.id < size.salesEndWeekId)
      .forEach(week => {
        const detailWeek = new AllocationWeek(size, week, allocatedForSize, spaceAvailable);
        weekMap.set(week.id, detailWeek);
      });
    }
    
    return weekMap;
  }
);

export interface WishListProductSizeQuantity {
  productSizeId: number;
  wishListQuantity: number;
  maxSpace: number;
  maxSpaceTight: number;
  minimumTight: WishListProductSizeSummaryMinimum | null;
  minimumSpaced: WishListProductSizeSummaryMinimum | null;
}

export const selectQuantities = createSelector(
  (state: RootState) => state.wishListProductDetail.product?.sizes || [],
  selectSummary,
  (sizes, summaries) => {
    const quantities = sizes.reduce((sizeMap, size) => {
      const summary = summaries.find(s => s.id === size.id),
        item: WishListProductSizeQuantity = {
        productSizeId: size.id,
        wishListQuantity: size.colours.reduce((total, c) => total + c.wishListQuantity, 0),
        maxSpace: summary?.maxSpace || 0,
        maxSpaceTight: summary?.maxSpaceTight || 0,
        minimumTight: summary?.minimumTight || null,
        minimumSpaced: summary?.minimumSpaced || null
      };

    if(!sizeMap.has(size.id)) {
      sizeMap.set(size.id, item);
    }

      return sizeMap;
    }, new Map<number, WishListProductSizeQuantity>());

    return quantities;
  }
);

export class AllocationWeek {
  weekId: number;
  weekNumber: number;
  quantityInInventory = 0;
  squareFeetTight = 0;
  squareFeetSpaced = 0;
  totalSquareFeet = 0;
  spacePerGrowingLocation = new Map<number, number>();
  totalSpace = 0;
  isHangingLocation: boolean | null = null;

  constructor(size: models.WishListProductSize, week: models.Week,
      allocated: models.WishListSpaceAllocated[], available: models.WishListSpaceAvailable[]) {
    this.weekId = week.id;
    this.weekNumber = week.weekNumber;
    const quantity = size.colours.reduce((total, c) => total + c.wishListQuantity, 0),
      sold = size.sales.filter(s => s.weekId < week.id).reduce((total, s) => total + ((s.percentSold || 0) / 100) * quantity, 0),
      allocatedThisWeek = allocated.filter(a => a.weekId === week.id),
      squareFeet = allocatedThisWeek.reduce((total, a) => total + a.space, 0),
      availableThisWeek = available.filter(a => a.weekId === week.id),
      isHangingLocation = !!availableThisWeek[0]?.isHangingLocation;
    this.quantityInInventory = Math.max(0, quantity - sold);
    this.totalSquareFeet = squareFeet;
    this.isHangingLocation = isHangingLocation;

    this.squareFeetTight = allocatedThisWeek.reduce((total, a) => total + capacity.tightSquareFeet(size, week.id), 0);
    this.squareFeetSpaced = allocatedThisWeek.reduce((total, a) => total + capacity.spacedSquareFeet(size, week.id), 0);

    availableThisWeek.forEach(a => {
      const space = (this.spacePerGrowingLocation.get(a.growingLocationId) || 0) + a.space;
      this.spacePerGrowingLocation.set(a.growingLocationId, space);
      this.totalSpace = Array.from(this.spacePerGrowingLocation.values()).reduce((total, space) => total + space, 0);
    });
  }
}

interface GrowingLocationWeekAllocationDisplay {
  wishListProductSizeId: number;
  productSizeName: string;
  squareFeet: number;
}

export interface GrowingLocationWeek {
  growingLocation: models.WishListGrowingLocation;
  weekId: number;
  revision: number;
  uniqueId: string;
  productSizeAllocations: {[index: string]: number};
  productSizeAllocationDiplay: GrowingLocationWeekAllocationDisplay[];
  totalAllocated: number;
}
