import { createSlice, createAsyncThunk, AsyncThunk, createAction, PayloadAction } from '@reduxjs/toolkit';
import { ProblemDetails } from 'utils/problem-details';
import { RootState } from 'app/store';
import * as models from 'api/models/wish-list';
import { WishListProductsResponse, WishListProductAdminCreateResponse, wishListApi } from 'api/wish-list-service';
import { createWishListProduct } from './product-detail-slice';

export interface WishListProductState {
  wishListProducts: models.WishListProductListItem[] | null;
  products: models.Product[];
  error: ProblemDetails | null;
}

const initialState: WishListProductState = {
  wishListProducts: null,
  products: [],
  error: null
};

export const getProducts: AsyncThunk<WishListProductsResponse, number, {state: RootState}> = createAsyncThunk(
  'wish-list-product-admin/getProducts',
  async (year, {rejectWithValue}) => {
    try {
      return await wishListApi.adminProducts(year);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);


const getProductsPending = createAction(getProducts.pending.type),
  getProductsFulfilled = createAction<WishListProductsResponse>(getProducts.fulfilled.type),
  getProductsRejected = createAction<ProblemDetails>(getProducts.rejected.type),
  createWishListProductFulfilled = createAction<WishListProductAdminCreateResponse>(createWishListProduct.fulfilled.type);

export const wishListProductAdminSlice = createSlice({
  name: 'wish-list-product-admin',
  initialState,
  reducers: {
    clearState(state) {
      state.wishListProducts = null;
      state.products = [];
      state.error = null;
    },
    setError(state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(getProductsPending, state => {
        state.error = null;
      })
      .addCase(getProductsFulfilled, (state, action) => {
        const {wishListProducts, products} = action.payload;
        state.wishListProducts = wishListProducts;
        state.products = products;
      })
      .addCase(getProductsRejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(createWishListProductFulfilled, (state, action) => {
        if(state.wishListProducts) {
          state.wishListProducts.push(action.payload.product);
        }
      })
});

export const {setError, clearState} = wishListProductAdminSlice.actions;

export const selectWishListProducts = (state: RootState) => state.wishListProductAdmin.wishListProducts;
export const selectProducts = (state: RootState) => state.wishListProductAdmin.products;
export const selectError = (state: RootState) => state.wishListProductAdmin.error;

export default wishListProductAdminSlice.reducer;

