import * as models from 'api/models/wish-list';
import { WishListProductSizeSummaryMinimum } from './wish-list-product-seasonal-slice';

export function isTightlySpaced(size: models.WishListProductSize, weekId: number) {
  const plantWeekId = size.plantWeekId,
    spaceWeekId = plantWeekId + size.weeksTightlySpaced;

  return weekId >= plantWeekId && weekId < spaceWeekId;
}

export function isPlantWeek(size: models.WishListProductSize, weekId: number) {
  return weekId === size.plantWeekId;
}

export function isSpaceWeek(size: models.WishListProductSize, weekId: number) {
  if(size.potsPerSquareFootSpaced === size.potsPerSquareFootTight) {
    return false;
  }

  const plantWeekId = size.plantWeekId,
    spaceWeekId = plantWeekId + size.weeksTightlySpaced;

  return weekId === spaceWeekId;
}

export function isSpaced(size: models.WishListProductSize, weekId: number) {
  const plantWeekId = size.plantWeekId,
    spaceWeekId = plantWeekId + size.weeksTightlySpaced;

  return weekId >= spaceWeekId && weekId < size.salesEndWeekId;
}

export function isInInventory(size: models.WishListProductSize, weekId: number) {
  return weekId >= size.plantWeekId && weekId < size.salesEndWeekId;
}

export function tightSquareFeet(size: models.WishListProductSize, weekId: number) {
  if(!size.potsPerSquareFootTight || !isTightlySpaced(size, weekId)) {
    return 0;
  }

  const quantity = size.colours.reduce((total, c) => total + c.wishListQuantity, 0),
    sold = quantitySoldBy(size, weekId),
    quantityRemaining = Math.max(quantity - sold, 0);

  return Math.ceil(quantityRemaining / size.potsPerSquareFootTight);
}

export function spacedSquareFeet(size: models.WishListProductSize, weekId: number) {
  if(!size.potsPerSquareFootSpaced || !isSpaced(size, weekId)) {
    return 0;
  }

  const quantity = size.colours.reduce((total, c) => total + c.wishListQuantity, 0),
    sold = quantitySoldBy(size, weekId),
    quantityRemaining = Math.max(quantity - sold, 0);

  return Math.ceil(quantityRemaining / size.potsPerSquareFootSpaced);
}

export function squareFeet(size: models.WishListProductSize, weekId: number) {
  const isTight = isTightlySpaced(size, weekId),
    isProductionSpaced = isSpaced(size, weekId),
    quantity = size.colours.reduce((total, c) => total + c.wishListQuantity, 0),
    sold = quantitySoldBy(size, weekId),
    quantityRemaining = Math.max(quantity - sold, 0),
    potsPerSquareFoot = isTight ? size.potsPerSquareFootTight : (isProductionSpaced ? size.potsPerSquareFootSpaced : 0),
    squareFeet = potsPerSquareFoot === 0 ? 0 : Math.ceil(quantityRemaining / potsPerSquareFoot);

  return squareFeet;
}

function quantitySoldBy(size: models.WishListProductSize, weekId: number) {
  const percentSoldBy = size.sales.filter(s => s.weekId <= weekId).reduce((percent, s) => percent + (s.percentSold || 0), 0),
    quantity = size.colours.reduce((total, c) => total + c.wishListQuantity, 0),
    quantitySold = Math.round(quantity * percentSoldBy / 100);

  return quantitySold;  
}


export function minimumError(minimum: WishListProductSizeSummaryMinimum | null, space: number) {
  return minimum && minimum.remaining < 0 && Math.abs(minimum.remaining) / space > 0.05;
}

export function minimumWarning(minimum: WishListProductSizeSummaryMinimum | null) {
  return minimum && minimum.remaining < 0;
}
