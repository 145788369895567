import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api-base';
import * as models from './models/propagation';
import { WishListColour as SalesColour } from './models/wish-list';

export const propagationApi = createApi({
  reducerPath: 'propagation-api',
  baseQuery: axiosBaseQuery('/api/propagation/'),
  tagTypes: ['PropagationProducts', 'PropagationProduct', 'Colours'],
  endpoints: builder => ({
    productList: builder.query<ProductListResponse, number>({
      query: year => ({url: `products?year=${year}`, method: 'GET'}),
      providesTags: ['PropagationProducts']
    }),
    productDetail: builder.query<SeasonalProductDetailResponse | WeeklyProductDetailResponse, number>({
      query: id => ({url: `products/${id}`, method: 'GET'}),
      providesTags: ['PropagationProduct']
    }),
    productDetailUpdate: builder.mutation<models.ProductDetailSeasonal | models.ProductDetailWeekly, ProductDetailUpdate>({
      query: product => ({url: `products/${product.wishListProductId}`, method: 'PUT', data: product}),
      transformResponse: (response: ProductDetailSeasonalUpdateResponse | ProductDetailSeasonalWeeklyResponse) => response.product,
      invalidatesTags: ['PropagationProducts', 'PropagationProduct']
    }),
    productConfiguration: builder.query<ProductConfigurationResponse, number>({
      query: id => ({url: `products/${id}/configuration`, method: 'GET'}),
      providesTags: ['PropagationProduct']
    }),
    productConfigurationUpdate: builder.mutation<models.ProductConfigurationProduct, ProductConfigurationUpdate>({
      query: product => ({url: `products/${product.wishListProductId}/configuration`, method: 'PUT', data: product}),
      transformResponse: (response: ProductConfigurationUpdateResponse) => response.product,
      invalidatesTags: ['PropagationProducts', 'PropagationProduct']
    }),
    colours: builder.query<GerberaColourListResponse, number>({
      query: year => ({url: `gerbera-colours?year=${year}`, method: 'GET'}),
      providesTags: ['Colours']
    }),
    colourUpdate: builder.mutation<void, GerberaColourUpdateModel>({
      query: data => ({url: `gerbera-colours/${data.id}`, method: 'PUT', data}),
      invalidatesTags: ['Colours']
    }),
    massColourUpdate: builder.mutation<void, GerberaColourMassUpdateModel>({
      query: data => ({url: 'gerbera-colours', method: 'POST', data}),
      invalidatesTags: ['Colours']
    })
  })
});

export interface ProductListResponse {
  products: models.ProductListItem[];
}

export interface SeasonalProductDetailResponse {
  product: models.ProductDetailSeasonal;
  suppliers: models.Supplier[];
  purchaseOrderGroups: models.PurchaseOrderGroup[];
  weeks: models.PurchaseOrderWeek[];
}

export interface WeeklyProductDetailResponse {
  product: models.ProductDetailWeekly;
  suppliers:models.Supplier[];
  purchaseOrderGroups: models.PurchaseOrderGroup[];
  weeks: models.PurchaseOrderWeek[];
  colours: models.Colour[];
  sowPeriods: models.SowPeriod[];
}

export interface ProductDetailUpdate {
  wishListProductId: number;
  varietySizes: models.ProductDetailUpdateVarietySize[];
  varietySowPeriods: models.WishListProductSowPeriodVarietyUpdate[];
  groupWishListProductSizes: models.ProductDetailPurchaseOrderGroupWishListProductSizeUpdate[];
  purchaseOrderGroups: models.ProductDetailPurchaseOrderGroupUpdate[];
  purchaseOrders: models.ProductDetailPurchaseOrderUpdate[];
  purchaseOrderItems: models.ProductDetailPurchaseOrderItemUpdate[];
}

export interface ProductDetailSeasonalUpdateResponse {
  product: models.ProductDetailSeasonal;
}

export interface ProductDetailSeasonalWeeklyResponse {
  product: models.ProductDetailWeekly;
}

export interface ProductConfigurationResponse {
  product: models.ProductConfigurationProduct;
  colours: models.Colour[];
  suppliers: models.Supplier[];
  startElements: models.StartElement[];
  trays: models.PropagationTray[];
  weeks: models.PurchaseOrderWeek[];
}

export interface ProductConfigurationUpdate {
  wishListProductId: number;  

  sizes: ProductConfigurationUpdateSize[];
  varieties: ProductConfigurationUpdateVariety[];
  sowPeriods: ProductConfigurationUpdateSowPeriod[];
}

export interface ProductConfigurationUpdateSize {
  wishListProductSizeId: number;
  propagationDisplayName: string | null;
  propagationTimeInWeeks: number;
  timeBeforeGradingInWeeks: number;
  propagationTrayId: number | null;
  hasSecondPropagationStep: boolean;
  propagationTimeInWeeks2: number;
  timeBeforeGradingInWeeks2: number;
  propagationTrayId2: number | null;
  propagationElementId: number;
  plugsPerPot: number;
  noOverage: boolean;
}

export interface ProductConfigurationUpdateVariety {
  id: number;
  name: string;
  colourId: number;
  defaultSupplierId: number | null;
  overage: number;
  isTrial: boolean;
  lotNumber: string | null;
}

export interface ProductConfigurationUpdateSowPeriod {
  id: number;
  startWeekId: number;
  endWeekId: number;
}

export interface ProductConfigurationUpdateResponse {
  product: models.ProductConfigurationProduct;
}

export interface ProductConfigurationResponse {
  product: models.ProductConfigurationProduct;
  colours: models.Colour[];
  suppliers: models.Supplier[];
  startElements: models.StartElement[];
  trays: models.PropagationTray[];
}

export interface GerberaColourListResponse {
  colours: models.GerberaColour[];
  salesColours: SalesColour[];
  varieties: models.ProductDetailWeeklyVariety
}

export interface GerberaColourUpdateModel {
  id: number;
  name: string;
  lotNumber: string;
  isActive: boolean;
  salesColourId: number | null;
}

export interface GerberaColourMassUpdateModel {
  colours: GerberaColourMassUpdateItem[];
}

export interface GerberaColourMassUpdateItem {
  colourId: number;
  salesColourId: number;
}

export const { useProductListQuery, useProductDetailQuery, useLazyProductDetailQuery,
  useProductDetailUpdateMutation, useProductConfigurationQuery,
  useProductConfigurationUpdateMutation,
  useColoursQuery, useColourUpdateMutation, useMassColourUpdateMutation } = propagationApi;
