import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api-base';
import * as models from './models/propagation';

export const propagationSupplierApi = createApi({
  reducerPath: 'propagation-supplier-api',
  baseQuery: axiosBaseQuery('/api/propagation/suppliers/'),
  tagTypes: ['Suppliers'],
  endpoints: builder => ({
    supplierList: builder.query<SupplierListResponse, void>({
      query: () => ({url: '', method: 'GET'}),
      providesTags: ['Suppliers']
    }),
    supplierCreate: builder.mutation<CreateSupplierResponse, CreateSupplierModel>({
      query: data => ({url: '', method: 'POST', data}),
      invalidatesTags: ['Suppliers']
    }),
    supplierUpdate: builder.mutation<UpdateSupplierResponse, UpdateSupplierModel>({
      query: data => ({url: data.id.toString(), method: 'PUT', data}),
      invalidatesTags: ['Suppliers']
    })
  })
})

export interface SupplierListResponse {
  suppliers: models.Supplier[];
  brokers: models.Broker[];
}

export interface CreateSupplierResponse {
  supplier: models.Supplier;
}

export interface CreateSupplierModel {
  name: string;
  minimumOrderQuantity: number | null;
  brokerId: number | null;
}

export interface UpdateSupplierResponse {
  supplier: models.Supplier;
}

export interface UpdateSupplierModel {
  id: number;
  name: string;
  minimumOrderQuantity: number | null;
  brokerId: number | null;
}

export const { useSupplierListQuery, useSupplierCreateMutation, useSupplierUpdateMutation } = propagationSupplierApi;
