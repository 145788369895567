import { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import 'boot';
import { NavMenu } from 'app/NavMenu';
import { routes } from './routes';
import { AuthorizeRoute } from 'features/auth/AuthorizeRoute';
import { AuthRoutes } from 'features/auth/AuthRoutes';
import { ApplicationPaths } from 'features/auth/auth-constants';
import './App.scss';
import { Loading } from './Loading';

export default function App() {
  return (
    <div className="App">
      <NavMenu />
      <Suspense fallback={<Loading />}>
        <Switch>
            <AuthorizeRoute exact path={routes.home.path} component={routes.home.component} />
            <AuthorizeRoute exact path={routes.apps.path} component={routes.apps.component} />
            <AuthorizeRoute path={routes.admin.path} component={routes.admin.component} roles={routes.admin.roles} />
            <AuthorizeRoute path={routes.billing.path} component={routes.billing.component} roles={routes.billing.roles} />
            <AuthorizeRoute exact path={routes.wishList.routes.allocations.path} component={routes.wishList.routes.allocations.component} />
            <AuthorizeRoute path={routes.wishList.path} component={routes.wishList.component} />
            <AuthorizeRoute exact path={routes.growingLocations.path} component={routes.growingLocations.component} />
            <AuthorizeRoute exact path={routes.growingLocations.routes.detail.path} component={routes.growingLocations.routes.detail.component} />
            <AuthorizeRoute exact path={routes.propagation.routes.productList.path} component={routes.propagation.routes.productList.component} />
            <AuthorizeRoute exact path={routes.propagation.routes.productDetail.path} component={routes.propagation.routes.productDetail.component} />
            <AuthorizeRoute exact path={routes.propagation.routes.productConfiguration.path} component={routes.propagation.routes.productConfiguration.component} />
            <AuthorizeRoute exact path={routes.propagation.routes.supplierList.path} component={routes.propagation.routes.supplierList.component} />
            <AuthorizeRoute exact path={routes.propagation.routes.supplierDetail.path} component={routes.propagation.routes.supplierDetail.component} />
            <AuthorizeRoute exact path={routes.propagation.routes.purchaseOrderList.path} component={routes.propagation.routes.purchaseOrderList.component} />
            <AuthorizeRoute exact path={routes.propagation.routes.incomingPurchaseOrderList.path} component={routes.propagation.routes.incomingPurchaseOrderList.component} />
            <AuthorizeRoute exact path={routes.propagation.routes.purchaseOrderNew.path} component={routes.propagation.routes.purchaseOrderNew.component} />
            <AuthorizeRoute exact path={routes.propagation.routes.purchaseOrderDetail.path} component={routes.propagation.routes.purchaseOrderDetail.component} />
            <AuthorizeRoute exact path={routes.propagation.routes.gerberaColours.path} component={routes.propagation.routes.gerberaColours.component} />
            <AuthorizeRoute exact path={routes.logs.path} component={routes.logs.component} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={AuthRoutes} />
        </Switch>
      </Suspense>
    </div>
  );  
}
