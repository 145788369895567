import { createSlice, createAsyncThunk, createSelector, PayloadAction, AsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { adminApi } from 'api/admin-service';
import { AdminCustomer } from 'api/models/admin';
import { ProblemDetails } from 'utils/problem-details';

export interface CustomerState {
  customers: AdminCustomer[] | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: CustomerState = {
  customers: null,
  loading: false,
  error: null
};

export const getCustomers: AsyncThunk<AdminCustomer[], void, {state: RootState}> = createAsyncThunk(
  'admin/getCustomers',
  async (_, {rejectWithValue}) => {
    try {
      return await adminApi.customerList();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const createCustomer: AsyncThunk<AdminCustomer, AdminCustomer, {state: RootState}> = createAsyncThunk(
  'admin/createCustomer',
  async (customer, {rejectWithValue}) => {
    try {
      return await adminApi.customerCreate(customer);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateCustomer: AsyncThunk<AdminCustomer, AdminCustomer, {state: RootState}> = createAsyncThunk(
  'admin/updateCustomer',
  async (customer, {rejectWithValue}) => {
    try {
      return await adminApi.customerUpdate(customer);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteCustomer: AsyncThunk<AdminCustomer, number, {state: RootState}> = createAsyncThunk(
  'admin/deleteCustomer',
  async (id, {rejectWithValue}) => {
    try {
      return await adminApi.customerDelete(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    }
  },
  extraReducers: {
    [getCustomers.pending.type]: (state) => {
      state.customers = [];
      state.error = null;
      state.loading = true;
    },
    [getCustomers.fulfilled.type]: (state, action: PayloadAction<AdminCustomer[]>) => {
      state.customers = action.payload;
      state.error = null;
      state.loading = false;
    },
    [getCustomers.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createCustomer.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [createCustomer.fulfilled.type]: (state, action: PayloadAction<AdminCustomer>) => {
      state.error = null;
      state.loading = false;
      if(state.customers) {
        state.customers.push(action.payload);
      }
    },
    [createCustomer.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateCustomer.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [updateCustomer.fulfilled.type]: (state, action: PayloadAction<AdminCustomer>) => {
      state.error = null;
      state.loading = false;

      if(state.customers) {
        const customer = action.payload,
          customers = state.customers.slice(),
          index = customers.findIndex(c => c.id === customer.id);

        if(index !== -1) {
          customers.splice(index, 1, customer);
        }

        state.customers = customers;
      }
    },
    [updateCustomer.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteCustomer.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [deleteCustomer.fulfilled.type]: (state, action: PayloadAction<AdminCustomer>) => {
      state.error = null;
      state.loading = false;

      if(state.customers) {
        const customer = action.payload,
          customers = state.customers.slice(),
          index = customers.findIndex(c => c.id === customer.id);

        if(index !== -1) {
          customers.splice(index, 1);
        }

        state.customers = customers;
      }
    },
    [deleteCustomer.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setError } = customerSlice.actions;

export const selectCustomers = (state: RootState) => state.customers.customers;
export const selectLoading = (state: RootState) => state.customers.loading;
export const selectError = (state: RootState) => state.customers.error;

export const selectSortedCustomers = createSelector(
  selectCustomers,
  customers => customers?.slice().sort((a, b) => a.name.localeCompare(b.name)) || null
);

export default customerSlice.reducer;
