import * as models from 'api/models/growing-locations';
import * as wishListModels from 'api/models/wish-list';

export interface UnallocatedProductSize {
  wishListProductId: number;
  sizeId: number;
  displayName: string;
  unallocatedSpace: number;
}

export interface AvailableProductSize {
  wishListProductId: number;
  sizeId: number;
}

export interface AllocationItemAllocation {
  displayName: string;
  space: number;
}

export class AllocationItem {
  
  available = 0;
  used = 0;
  allocations: AllocationItemAllocation[] = [];

  get remaining() {
    return this.available - this.used;
  }

  get isOverbooked() {
    return this.used > this.available;
  }

  addSpace(space: models.GrowingLocationWeekSpace) {
    this.available += space.space;
  }

  addAllocation(allocation: wishListModels.WishListSpaceAllocated) {
    this.used += allocation.space;
    const displayName = `${allocation.sizeName} ${allocation.productName}`,
      index = this.allocations.findIndex(a => a.displayName.indexOf(displayName) !== -1);
    if(index === -1) {
      const item = {displayName, space: allocation.space};
      this.allocations.push(item);
    } else {
      this.allocations[index].space += allocation.space;
    }
  }
}

export class AllocationsMap {
  private allocationMap = new Map<string, AllocationItem>();
  private unallocatedMap = new Map<string, UnallocatedProductSize[]>();

  addSpace(space: models.GrowingLocationWeekSpace[], weekId: number) {
    space.forEach(s => {
      const key = getKey(s.growingLocationId, weekId),
        value = this.allocationMap.get(key) || new AllocationItem();

      value.addSpace(s);

      this.allocationMap.set(key, value);
    });    
  }

  addAllocations(allocations: wishListModels.WishListSpaceAllocated[], weekId: number) {
    allocations.forEach(a => {
      const key = getKey(a.growingLocationId, weekId),
        value = this.allocationMap.get(key) || new AllocationItem();

      value.addAllocation(a);

      this.allocationMap.set(key, value);
    });
  }

  addUnallocated(available: wishListModels.WishListSpaceAvailable[], unallocated: wishListModels.WishListSpaceAllocated[], weekId: number) {
    available.forEach(a => {
      const key = getKey(a.growingLocationId, weekId),
        value = this.unallocatedMap.get(key) || [],
        existing = value.find(v => v.wishListProductId === a.wishListProductId && v.sizeId === a.sizeId),
        unallocatedSpace = unallocated
          .filter(al => a.wishListProductId === al.wishListProductId && a.sizeId === al.sizeId)
          .reduce((total, a) => total + a.space, 0);

      if(unallocatedSpace) {
        if(existing) {
          existing.unallocatedSpace += unallocatedSpace;
        } else {
          value.push({wishListProductId: a.wishListProductId, sizeId: a.sizeId, displayName: `${a.sizeName} ${a.productName}`, unallocatedSpace});
        }
      }

      this.unallocatedMap.set(key, value);
    });
  }

  getAvailable(locationId: number, weekId: number) {
    const key = getKey(locationId, weekId),
      value = this.allocationMap.get(key),
      available = value?.available || 0;

    return available;
  }

  getUsed(locationId: number, weekId: number) {
    const key = getKey(locationId, weekId),
      value = this.allocationMap.get(key),
      used = value?.used || 0;

    return used;
  }

  getAllocations(locationId: number, weekId: number) {
    const key = getKey(locationId, weekId),
      allocations = this.allocationMap.get(key)?.allocations || [];

    return allocations
  }

  getUnallocated(locationId: number, weekId: number) {
    const key = getKey(locationId, weekId),
      unallocated = this.unallocatedMap.get(key) || [];

    return unallocated;
  }

  hasUnallocated(locationId: number, weekId: number) {
    const key = getKey(locationId, weekId),
      unallocated = this.unallocatedMap.get(key) || [];

    return !!unallocated.length;
  }

  isOverbooked(locationId: number, weekId: number) {
    const key = getKey(locationId, weekId),
      overbooked = this.allocationMap.get(key)?.isOverbooked || false;

    return overbooked;
  }

  hasRemaining(locationId: number, weekId: number) {
    const key = getKey(locationId, weekId),
      remaining = this.allocationMap.get(key)?.remaining || 0;

    return remaining > 0;
  }

  isZero(locationId: number, weekId: number) {
    const key = getKey(locationId, weekId),
      value = this.allocationMap.get(key),
      space = value?.available || 0,
      used = value?.used || 0;

    return !space && !used;
  }
}

function getKey(locationId: number, weekId: number) {
  return JSON.stringify([locationId, weekId]);
}
