import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface WeeklyState {
  sizeTabId: number;
}

const initialState: WeeklyState = {
  sizeTabId: 0
};

export const weeklySlice = createSlice({
  name: 'propagation/weeklyProductDetail',
  initialState,
  reducers: {
    setSizeTabId(state, {payload}: PayloadAction<number>) {
      state.sizeTabId = payload;
    }
  }
});

export const { setSizeTabId } = weeklySlice.actions;

export const selectSizeTabId = (state: RootState) => state.propagationProductDetailWeekly.sizeTabId;

export default weeklySlice.reducer;
