import React from 'react';
import {Route} from 'react-router-dom';
import {Login} from './Login';
import {Logout} from './Logout';
import {Profile} from './Profile';
import {ApplicationPaths, LoginActions, LogoutActions} from './auth-constants';

export function AuthRoutes() {
  return (
    <>
      <Route path={ApplicationPaths.Login} render={() => loginAction(LoginActions.Login)} />
      <Route path={ApplicationPaths.LoginFailed} render={() => loginAction(LoginActions.LoginFailed)} />
      <Route path={ApplicationPaths.LoginCallback} render={() => loginAction(LoginActions.LoginCallback)} />
      <Route path={ApplicationPaths.Profile} component={Profile} />
      <Route path={ApplicationPaths.LogOut} render={() => logoutAction(LogoutActions.Logout)} />
      <Route path={ApplicationPaths.LogOutCallback} render={() => logoutAction(LogoutActions.LogoutCallback)} />
      <Route path={ApplicationPaths.LoggedOut} render={() => logoutAction(LogoutActions.LoggedOut)} />
    </>
  )
}

function loginAction(name: string){
  return (<Login action={name}></Login>);
}

function logoutAction(name: string) {
  return (<Logout action={name}></Logout>);
}
