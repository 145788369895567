import { createSlice, createAsyncThunk, createSelector, PayloadAction, AsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { adminApi } from 'api/admin-service';
import { AdminSalesTax } from 'api/models/admin';
import { ProblemDetails } from 'utils/problem-details';

export interface SalesTaxState {
  salesTaxes: AdminSalesTax[] | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: SalesTaxState = {
  salesTaxes: null,
  loading: false,
  error: null
};

export const getSalesTaxes: AsyncThunk<AdminSalesTax[], void, {state: RootState}> = createAsyncThunk(
  'admin/getSalesTaxes',
  async (_, {rejectWithValue}) => {
    try {
      return await adminApi.salesTaxList();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const createSalesTax: AsyncThunk<AdminSalesTax, AdminSalesTax, {state: RootState}> = createAsyncThunk(
  'admin/createSalesTax',
  async (salesTax, {rejectWithValue}) => {
    try {
      return await adminApi.salesTaxCreate(salesTax);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateSalesTax: AsyncThunk<AdminSalesTax, AdminSalesTax, {state: RootState}> = createAsyncThunk(
  'admin/updateSalesTax',
  async (salesTax, {rejectWithValue}) => {
    try {
      return await adminApi.salesTaxUpdate(salesTax);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteSalesTax: AsyncThunk<AdminSalesTax, string, {state: RootState}> = createAsyncThunk(
  'admin/deleteSalesTax',
  async (id, {rejectWithValue}) => {
    try {
      return await adminApi.salesTaxDelete(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const salesTaxSlice = createSlice({
  name: 'salesTaxes',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    }
  },
  extraReducers: {
    [getSalesTaxes.pending.type]: (state) => {
      state.salesTaxes = [];
      state.error = null;
      state.loading = true;
    },
    [getSalesTaxes.fulfilled.type]: (state, action: PayloadAction<AdminSalesTax[]>) => {
      state.salesTaxes = action.payload;
      state.error = null;
      state.loading = false;
    },
    [getSalesTaxes.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createSalesTax.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [createSalesTax.fulfilled.type]: (state, action: PayloadAction<AdminSalesTax>) => {
      state.error = null;
      state.loading = false;
      if(state.salesTaxes) {
        state.salesTaxes.push(action.payload);
      }
    },
    [createSalesTax.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateSalesTax.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [updateSalesTax.fulfilled.type]: (state, action: PayloadAction<AdminSalesTax>) => {
      state.error = null;
      state.loading = false;

      if(state.salesTaxes) {
        const salesTax = action.payload,
          salesTaxes = state.salesTaxes.slice(),
          index = salesTaxes.findIndex(c => c.taxId === salesTax.taxId);

        if(index !== -1) {
          salesTaxes.splice(index, 1, salesTax);
        }

        state.salesTaxes = salesTaxes;
      }
    },
    [updateSalesTax.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteSalesTax.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [deleteSalesTax.fulfilled.type]: (state, action: PayloadAction<AdminSalesTax>) => {
      state.error = null;
      state.loading = false;

      if(state.salesTaxes) {
        const salesTax = action.payload,
          salesTaxes = state.salesTaxes.slice(),
          index = salesTaxes.findIndex(c => c.taxId === salesTax.taxId);

        if(index !== -1) {
          salesTaxes.splice(index, 1);
        }

        state.salesTaxes = salesTaxes;
      }
    },
    [deleteSalesTax.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setError } = salesTaxSlice.actions;

export const selectSalesTaxes = (state: RootState) => state.salesTaxes.salesTaxes;
export const selectLoading = (state: RootState) => state.salesTaxes.loading;
export const selectError = (state: RootState) => state.salesTaxes.error;

export const selectSortedSalesTaxes = createSelector(
  selectSalesTaxes,
  salesTaxes => salesTaxes?.slice().sort((a, b) => a.taxId.localeCompare(b.taxId)) || null
);

export default salesTaxSlice.reducer;
