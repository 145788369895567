import * as models from 'api/models/wish-list';
import * as capacity from './weekly-capacity';
import { sortBy, sortSizeName } from 'utils/sort';

const sortByGrowingLocationPriorityTight = sortBy('growingLocationPriorityTight'),
  sortByGrowingLocationPrioritySpaced = sortBy('growingLocationPrioritySpaced'),
  sortByWishListProductPriority = sortBy('wishListProductPriority'),
  sortByRoundNumber = sortBy('roundNumber');

type WishListProductSizeLocation = {
  growingLocationId: number;
  space: number;
}

export function refreshWeeklyAllocations(product: models.WishListProduct, available: models.WishListSpaceAvailable[], allocated: models.WishListSpaceAllocated[], allWeeks: models.Week[]) {
  const allocations: models.WishListSpaceAllocated[] = [],
    sizes = product.sizes.map(s => ({...s})).sort((a, b) => sortByRoundNumber(a, b)),
    start = sizes.reduce((min, size) => Math.min(min, size.plantWeekId), Number.MAX_VALUE),
    end = sizes.reduce((endWeek, size) => Math.max(endWeek, size.sales.reduce((max, s) => Math.max(max, s.weekId), 0), 0), 0);

  if(start && end && start <= end) {
    const wishListProductSizeLocations = sizes.reduce((memo, size) => {
        return memo.set(size.id, []);
      }, new Map<number, WishListProductSizeLocation[]>()),
      availableByWeek = available
      .reduce((memo, a) => {
        const availableForWeek = memo.get(a.weekId) || [];

        if(a.wishListProductId === product.id) {
          const isAvailable = sizes.some(size => {
            const isTightlySpaced = capacity.isTightlySpaced(size, a.weekId),
              isProductionSpaced = capacity.isProductionSpaced(size, a.weekId);

            return (isTightlySpaced && a.allowTight) || (isProductionSpaced && a.allowSpaced);
          });

          if(isAvailable) {
            availableForWeek.push(a);
            }
        } else {
          availableForWeek.push(a);
        }

        return memo.set(a.weekId, availableForWeek);

      }, new Map<number, models.WishListSpaceAvailable[]>()),
    allocatedByWeek = allocated
      .reduce((memo, a) => {
        const allocatedForWeek = memo.get(a.weekId) || [];
        allocatedForWeek.push(a);
        return memo.set(a.weekId, allocatedForWeek);
      }, new Map<number, models.WishListSpaceAllocated[]>()),
    weeks = allWeeks.filter(w => w.id >= start && w.id <= end);

    weeks.forEach(week => {
      // clear this out on Space Week
      sizes
        .filter(sz => capacity.isSpaceWeek(sz, week.id - 1))
        .forEach(sz => wishListProductSizeLocations.set(sz.id, []));

      const allocatedForWeek = (allocatedByWeek.get(week.id) || []),
        availableForWeek = (availableByWeek.get(week.id) || []),
        // for each product size, aggregate the space.
        // for this product, calculate it
        // then keep track of total available & total allocated
        productSizeAllocationMap = allocatedForWeek
          .filter(a => a.wishListProductId !== product.id)
          .reduce((memo, allocation) => {
            const existing = memo.get(allocation.wishListProductSizeId);

            if(existing) {
              existing.squareFeet += allocation.space;
            } else {
              const available = availableForWeek
                .filter(av => av.wishListProductId === allocation.wishListProductId && av.sizeId === allocation.sizeId)
                .reduce((memo2, av) => {
                  if(memo2.findIndex(m => m.growingLocationId === av.growingLocationId) === -1) {
                    memo2.push(av);
                  }
                  return memo2;
                }, [] as models.WishListSpaceAvailable[])
                .reduce((total, av) => total + av.space, 0),
                {space: squareFeet, wishListProductSizeId, wishListProductId, sizeId, sizeName} = allocation,
                value = {wishListProductSizeId, available, allocated: 0, squareFeet, wishListProductId, sizeId, sizeName};
              memo.set(allocation.wishListProductSizeId, value);
            }
            return memo;
          }, new Map<number, {available: number, allocated: number, wishListProductSizeId: number, squareFeet: number, wishListProductId: number, sizeId: number, sizeName: string}>());

        sizes.forEach(wishListProductSize => {
          const {id, sizeId, sizeName} = wishListProductSize,
            squareFeet = capacity.squareFeet(wishListProductSize, week.id),
            available = availableForWeek
              .filter(av => av.wishListProductId === product.id && av.sizeId === wishListProductSize.sizeId)
              .reduce((memo2, av) => {
                if(memo2.findIndex(m => m.growingLocationId === av.growingLocationId) === -1) {
                  memo2.push(av);
                }
                return memo2;
              }, [] as models.WishListSpaceAvailable[])
              .reduce((total, av) => total + av.space, 0);
              productSizeAllocationMap.set(id, {available, allocated: 0, wishListProductSizeId: id, squareFeet, wishListProductId: product.id, sizeId, sizeName});
        });
    
        const wishListProductPriorities = availableForWeek
            .map(a => ({...a}))
            .reduce((memo, a) => {
              // just keep 1 of the current product
              const index = memo.findIndex(m => m.wishListProductId === a.wishListProductId && (m.wishListProductId === product.id || m.sizeId === a.sizeId));
              if(index === -1) {
                memo.push(a);
              } else if(a.wishListProductId !== product.id) {
                memo[index].space += a.space;
              }

              return memo;

            }, [] as models.WishListSpaceAvailable[])
            .sort((a, b) => sortByWishListProductPriority(a, b) || sortSizeName(a.sizeName, b.sizeName) || sortByRoundNumber(a, b)),
          weeklySpaceByGrowingLocation = availableForWeek.reduce((memo, a) => memo.set(a.growingLocationId, a.space), new Map<number, number>());
  
          wishListProductPriorities.forEach(wishListProduct => {
            if(wishListProduct.wishListProductId === product.id) {
              const availableSizesForProduct = availableForWeek
                .map(a => ({...a}))
                .filter(a => a.wishListProductId === product.id)
                .reduce((memo, a) => {
                  if(memo.indexOf(a.sizeId) === -1) {
                    memo.push(a.sizeId);
                  }
                  return memo;
                }, [] as number[]);

              sizes
                .map(size => ({...size}))
                .filter(size => availableSizesForProduct.indexOf(size.sizeId) !== -1)
                .sort((a, b) => {
                  // if it was planted/spaced last week, it will take up the space this week
                  const isFirstWeekTight1 = capacity.isPlantWeek(a, week.id - 1) || (a.isSkipWeek && capacity.isPlantWeek(a, week.id)),
                    isFirstWeekTight2 = capacity.isPlantWeek(b, week.id - 1) || (b.isSkipWeek && capacity.isPlantWeek(b, week.id));

                  if(isFirstWeekTight1 && !isFirstWeekTight2) return 1;
                  if(!isFirstWeekTight1 && isFirstWeekTight2) return -1;

                  const isFirstWeekSpaced1 = capacity.isSpaceWeek(a, week.id - 1) || (a.isSkipWeek && capacity.isSpaceWeek(a, week.id)),
                    isFirstWeekSpaced2 = capacity.isSpaceWeek(b, week.id - 1) || (b.isSkipWeek && capacity.isSpaceWeek(b, week.id));

                    if(isFirstWeekSpaced1 && !isFirstWeekSpaced2) return 1;
                    if(!isFirstWeekSpaced1 && isFirstWeekSpaced2) return -1;

                  return sortByRoundNumber(a, b);
                })
                .forEach(size => {
                  const isTight = capacity.isTightlySpaced(size, week.id - 1) || (size.isSkipWeek && capacity.isTightlySpaced(size, week.id)),
                    isSpaced = capacity.isProductionSpaced(size, week.id - 1) || (size.isSkipWeek && capacity.isProductionSpaced(size, week.id)),
                    isFirstWeekTight = capacity.isPlantWeek(size, week.id - 1) || (size.isSkipWeek && capacity.isPlantWeek(size, week.id)),
                    isFirstWeekSpaced = capacity.isSpaceWeek(size, week.id - 1) || (size.isSkipWeek && capacity.isSpaceWeek(size, week.id)),
                    locations = wishListProductSizeLocations.get(size.id);

                  if(locations) {
                    const availableLocations = availableForWeek
                      .filter(a => a.wishListProductId === product.id && a.sizeId === size.sizeId && ((isTight && a.allowTight) || (isSpaced && a.allowSpaced)))
                      .reduce((memo, a) => {
                        if(!memo.some(m => m.growingLocationId === a.growingLocationId)) {
                          memo.push(a);
                        }
                        return memo;
                      }, [] as {growingLocationId: number, growingLocationName: string, isHangingLocation: boolean, growingLocationPriorityTight: number, growingLocationPrioritySpaced: number, space: number, allowTight: boolean, allowSpaced: boolean}[])
                      .sort(isTight ? sortByGrowingLocationPriorityTight : sortByGrowingLocationPrioritySpaced);

                    availableLocations.forEach(location => {
                      const currentLocation = locations.find(l => l.growingLocationId === location.growingLocationId);
      
                      // we don't want to start into new locations that are lower priority than the current
                      if(currentLocation || isFirstWeekTight || isFirstWeekSpaced) {
                        // if it's the second plant/space week (for skip weeks), we can exceed the quantity planted/spaced last time
                        const currentLocationMax = (currentLocation && (isFirstWeekTight || isFirstWeekSpaced)) ? Number.MAX_SAFE_INTEGER : currentLocation?.space || Number.MAX_SAFE_INTEGER,
                          allocated = productSizeAllocationMap.get(size.id);
                        
                        if(allocated && allocated.allocated < allocated.squareFeet) {
                          const squareFeet = allocated.squareFeet - allocated.allocated,
                            availableInLocation = weeklySpaceByGrowingLocation.get(location.growingLocationId) || 0,
                            space = Math.max(0, Math.min(squareFeet, Math.min(availableInLocation, currentLocationMax)));
      
                          if(space) {
                            const allocation = {
                              growingLocationId: location.growingLocationId,
                              growingLocationName: location.growingLocationName,
                              weekId: week.id,
                              weekNumber: wishListProduct.weekNumber,
                              year: wishListProduct.year,
                              wishListProductId: wishListProduct.wishListProductId,
                              productId: wishListProduct.productId,
                              productName: wishListProduct.productName,
                              sizeId: size.sizeId,
                              sizeName: size.sizeName,
                              space,
                              isHangingLocation: location.isHangingLocation,
                              wishListProductSizeId: size.id,
                              growingLocationPriorityTight: location.growingLocationPriorityTight,
                              growingLocationPrioritySpaced: location.growingLocationPrioritySpaced,
                              wishListProductPriority: wishListProduct.wishListProductPriority
                            };
      
                            allocated.allocated += space;
                            allocations.push(allocation);
                            weeklySpaceByGrowingLocation.set(location.growingLocationId, availableInLocation - space);
      
                            if(!currentLocation) {
                              locations.push({growingLocationId: location.growingLocationId, space});
                              wishListProductSizeLocations.set(size.id, locations);
                            // increased the allocated space during a skip week
                            } else if(currentLocation.space < space) {
                              currentLocation.space = space;
                            }
                          }
                        }
                      }
                  });
                }
              });
            } else {
              const productSizeAllocations = allocatedForWeek
                .filter(a => a.wishListProductId === wishListProduct.wishListProductId && a.sizeId === wishListProduct.sizeId)
                .sort((a, b) => sortByWishListProductPriority(a, b) || sortByRoundNumber(a, b) || sortSizeName(a.sizeName, b.sizeName));

              productSizeAllocations.forEach(productSizeAllocation => {
                const allocated = productSizeAllocationMap.get(productSizeAllocation.wishListProductSizeId),
                  availableLocations = availableForWeek
                  .filter(a => a.wishListProductId === product.id && a.sizeId === wishListProduct.sizeId)
                  .reduce((memo, a) => {
                    if(!memo.some(m => m.growingLocationId === a.growingLocationId)) {
                      memo.push(a);
                    }
                    return memo;
                  }, [] as {growingLocationId: number, growingLocationName: string, isHangingLocation: boolean, growingLocationPriorityTight: number, growingLocationPrioritySpaced: number, space: number, allowTight: boolean, allowSpaced: boolean}[])
                  .sort((a, b) => sortByGrowingLocationPriorityTight(a, b) || sortByGrowingLocationPrioritySpaced(a, b));

                availableLocations.forEach(location => {
  
                  if(allocated && allocated.allocated < allocated.squareFeet) {
                    const squareFeet = allocated.squareFeet - allocated.allocated,
                      availableInLocation = weeklySpaceByGrowingLocation.get(location.growingLocationId) || 0,
                      space = Math.max(0, Math.min(squareFeet, availableInLocation));
  
                    if(space) {
                      const allocation = {
                        growingLocationId: location.growingLocationId,
                        growingLocationName: location.growingLocationName,
                        weekId: week.id,
                        weekNumber: wishListProduct.weekNumber,
                        year: wishListProduct.year,
                        wishListProductId: wishListProduct.wishListProductId,
                        productId: wishListProduct.productId,
                        productName: wishListProduct.productName,
                        sizeId: productSizeAllocation.sizeId,
                        sizeName: productSizeAllocation.sizeName,
                        space,
                        isHangingLocation: location.isHangingLocation,
                        wishListProductSizeId: productSizeAllocation.wishListProductSizeId,
                        growingLocationPriorityTight: location.growingLocationPriorityTight,
                        growingLocationPrioritySpaced: location.growingLocationPrioritySpaced,
                        wishListProductPriority: wishListProduct.wishListProductPriority
                      };
  
                      allocations.push(allocation);
                      weeklySpaceByGrowingLocation.set(location.growingLocationId, availableInLocation - space);
                      allocated.allocated += space;
                    }
                  }
                });
              });
            }
          });
    
          Array.from(productSizeAllocationMap.values())
            .filter(a => a.allocated < a.squareFeet)
            .forEach(a => {
              const unallocated = {
                  growingLocationId: 0,
                  growingLocationName: 'Unallocated',
                  weekId: week.id,
                  weekNumber: week.weekNumber,
                  year: week.year,
                  wishListProductId: a.wishListProductId,
                  productId: product.productId,
                  productName: product.productName,
                  sizeId: a.sizeId,
                  sizeName: a.sizeName,
                  space: a.squareFeet - a.allocated,
                  isHangingLocation: false,
                  wishListProductSizeId: a.wishListProductSizeId,
                  growingLocationPriorityTight: 1,
                  growingLocationPrioritySpaced: 1,
                  wishListProductPriority: 1
                };
              allocations.push(unallocated);
            });
    });
    
  }

  return allocations;
}
