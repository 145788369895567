import { ApiBase } from './api-base';
import * as models from './models/billing';

class BillingService extends ApiBase {
  getOrderList(year: number): Promise<OrderListResponse> {
    return this.get(`/api/billing/orders?year=${year}`);
  }

  getOrderDetail(id: number): Promise<OrderDetailResponse> {
    return this.get(`/api/billing/orders/${id}`);
  }

  getNewOrderDetail(): Promise<NewOrderDetailResponse> {
    return this.get('/api/billing/orders/new');
  }

  createOrder(order: models.BillingOrderDetail): Promise<OrderCreateResponse> {
    return this.post('/api/billing/orders', order);
  }

  updateOrder(order: models.BillingOrderDetail): Promise<OrderUpdateResponse> {
    return this.put(`/api/billing/orders/${order.id}`, order);
  }

  deleteOrder(id: number): Promise<OrderDeleteResponse> {
    return this.delete(`/api/billing/orders/${id}`);
  }

  duplicateOrder(id: number): Promise<OrderDuplicateResponse> {
    return this.post(`/api/billing/orders/${id}/duplicate`);
  }

  commitOrder(id: number, order: models.BillingOrderDetail, commit: boolean): Promise<models.BillingOrderDetail> {
    const query = commit ? '' : '?uncommit=true';
    return this.post(`/api/billing/orders/${id}/commit${query}`, order);
  }

  postOrder(id: number, order: models.BillingOrderDetail, post: boolean): Promise<models.BillingOrderDetail> {
    const query = post ? '' : '?unpost=true';
    return this.post(`/api/billing/orders/${id}/post${query}`, order);
  }

  getBrokers(): Promise<BrokersResponse> {
    return this.get('/api/billing/brokers');
  }

  emailInvoices(orderIds: number[], emailAddress: string, subject: string, body: string, bcc: string | null): Promise<EmailInvoicesReponse> {
    const data = {orderIds, emailAddress, subject, body, bcc}
    return this.post('/api/billing/orders/email/invoices', data);
  }

  emailConfirmations(orderIds: number[], emailAddress: string, subject: string, body: string, bcc: string | null): Promise<EmailConfirmationsReponse> {
    const data = {orderIds, emailAddress, subject, body, bcc}
    return this.post('/api/billing/orders/email/confirmations', data);
  }
}

export interface OrderListResponse {
  orders: models.BillingOrderListItem[];
  emailAddress: string | null;
}

export interface BrokersResponse {
  brokers: models.BillingBroker[];
}

export interface NewOrderDetailResponse {
  brokers: models.BillingBroker[];
  customers: models.BillingCustomer[];
  salesTaxes: models.BillingSalesTax[];
  generalLedgerAccounts: models.BillingGeneralLedgerAccount[];
  deliveryTypes: models.BillingDeliveryType[];
  varieties: models.BillingVariety[];
  colours: models.BillingColour[];
  weeks: models.BillingWeek[];
  paymentTerms: models.PaymentTerm[];
  emailAddress: string | null;
}

export interface OrderDetailResponse extends NewOrderDetailResponse {
  order: models.BillingOrderDetail;
  signoffs: models.SignoffInfo;
}

export interface OrderCreateResponse {
  orderId: number;
}

export interface OrderUpdateResponse {
  order: models.BillingOrderDetail;
}

export interface OrderDuplicateResponse {
  orderId: number;
}

export interface OrderDeleteResponse {
  orderId: number;
}

export interface EmailInvoicesReponse {
  email: models.BillingInvoiceEmail;
}

export interface EmailConfirmationsReponse {
  email: models.BillingConfirmationEmail;
}

export const billingApi = new BillingService();
