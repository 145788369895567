import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateWeeklyVarietySowPeriods, updateAllWeeklyVarietySowPeriods } from './weekly/weekly-functions';
import { propagationApi, WeeklyProductDetailResponse } from 'api/propagation-service';
import * as models from 'api/models/propagation';
import { RootState } from 'app/store';
import { sortBy, sortSizeName } from 'utils/sort';

const sortByColourName = sortBy('colourName'),
  sortByName = sortBy('name');

export interface DetailState {
  seasonalProduct: models.ProductDetailSeasonal | null;
  weeklyProduct: models.ProductDetailWeekly | null;
  purchaseOrderGroups: models.PurchaseOrderGroup[];
  suppliers: models.Supplier[];
  weeks: models.PurchaseOrderWeek[];
  sowPeriods: models.SowPeriod[];
  colours: models.Colour[];
  sowPeriodId: number | null;
}

const initialState: DetailState = {
  seasonalProduct: null,
  weeklyProduct: null,
  purchaseOrderGroups: [],
  suppliers: [],
  weeks: [],
  sowPeriods: [],
  colours: [],
  sowPeriodId: null
};

interface SetSeasonalVarietyWishListSizeQuantityArgs {
  varietyId: number;
  wishListProductSizeId: number;
  quantityInPots: number;
}

interface SetWeeklyVarietyValueArgs {
  varietyId: number;
  value: number;
}

interface SetPurchaseOrderGroupWishListProductSizeArgs {
  purchaseOrderGroupId: number;
  wishListProductSize: models.ProductDetailSeasonalWishListSize;
  selected: boolean;
}

interface PurchaseOrderArgs<T> {
  purchaseOrderGroupId: number;
  purchaseOrderId: number;
  value: T
}

interface RemovePurchaseOrderArgs {
  purchaseOrderGroupId: number;
  purchaseOrderId: number;
}

interface PurchaseOrderItemArgs<T> extends PurchaseOrderArgs<T> {
  varietyId: number;
  purchaseOrderItemId: number;
}

export interface ProductColour {
  id: number;
  name: string;
  applicationColour: string | null;
}

export const detailSlice = createSlice({
  name: 'propagation/productDetail',
  initialState,
  reducers: {
    setSeasonalProduct(state, {payload}: PayloadAction<models.ProductDetailSeasonal>) {
      state.seasonalProduct = payload;
    },
    setWeeklyProduct(state, {payload}: PayloadAction<models.ProductDetailWeekly>) {
      state.weeklyProduct = payload;
    },
    setSeasonalVarietyWishListSizeQuantity(state, {payload}: PayloadAction<SetSeasonalVarietyWishListSizeQuantityArgs>) {
      if(state.seasonalProduct) {
        const {wishListProductSizeId, varietyId, quantityInPots} = payload,
          product = {...state.seasonalProduct},
          varieties = product.varieties.map(v => ({...v})),
          variety = varieties.find(v => v.id === varietyId),
          wishListProductSize = product.wishListSizes.find(s => s.id === wishListProductSizeId);
        if(variety && wishListProductSize) {
          const quantityProductSizes = variety.wishListProductSizeQuantities.map(q => ({...q})),
            index = quantityProductSizes.findIndex(s => s.wishListProductSizeId === wishListProductSizeId);

          if(index === -1) {
            const id = quantityProductSizes.reduce((min, q) => Math.min(min, q.id), 1) - 1,
              quantityProductSize = {id, wishListProductSizeId, varietyId, sizeId: wishListProductSize.sizeId, quantityInPots};
            quantityProductSizes.push(quantityProductSize);
          } else {
            quantityProductSizes[index].quantityInPots = quantityInPots;
          }

          variety.wishListProductSizeQuantities = quantityProductSizes;
          product.varieties = varieties;
          state.seasonalProduct = product;
        }
      }
    },
    setWeeklyVarietyOverage(state, {payload}: PayloadAction<SetWeeklyVarietyValueArgs>) {
      if(state.weeklyProduct) {
        const {varietyId, value} = payload,
          product = {...state.weeklyProduct},
          varieties = product.varieties.map(v => ({...v})),
          variety = varieties.find(v => v.id === varietyId);

        if(variety) {
          const varietySowPeriods = variety.sowPeriods.map(sp => ({...sp})),
            varietySowPeriod = varietySowPeriods.find(sp => sp.wishListProductSowPeriodId === state.sowPeriodId);

          if(varietySowPeriod) {
            varietySowPeriod.overage = value;
            variety.sowPeriods = varietySowPeriods;

            const updated = updateWeeklyVarietySowPeriods(variety, state.sowPeriods, state.purchaseOrderGroups);
            variety.sowPeriods = updated;

            product.varieties = varieties;
            state.weeklyProduct = product;
          }
        }
      }
    },
    setWeeklyVarietyBeginningInventory(state, {payload}: PayloadAction<SetWeeklyVarietyValueArgs>) {
      if(state.weeklyProduct) {
        const {varietyId, value} = payload,
          product = {...state.weeklyProduct},
          varieties = product.varieties.map(v => ({...v})),
          variety = varieties.find(v => v.id === varietyId);

        if(variety) {
          const varietySowPeriods = variety.sowPeriods.map(sp => ({...sp})),
            varietySowPeriod = varietySowPeriods.find(sp => sp.wishListProductSowPeriodId === state.sowPeriodId),
            sowPeriod = state.sowPeriods.find(sp => sp.id === state.sowPeriodId);

          if(varietySowPeriod && sowPeriod) {            
            varietySowPeriod.beginningInventory = value;
            variety.sowPeriods = varietySowPeriods;

            const updated = updateWeeklyVarietySowPeriods(variety, state.sowPeriods, state.purchaseOrderGroups);

            variety.sowPeriods = updated;
            product.varieties = varieties;
            state.weeklyProduct = product;
          }
        }
      }
    },
    addPurchaseOrderGroup(state) {
      let itemId = 0;

      const wishListProductSowPeriodId = state.sowPeriodId,
        sowPeriod = state.sowPeriods.find(sp => sp.id === wishListProductSowPeriodId),
        supplierId = state.suppliers[0]?.id || 0,
        requestedWeekId = sowPeriod?.startWeekId || state.weeks.filter(w => w.year === new Date().getFullYear())[0]?.id || 0,
        purchaseOrderGroups = state.purchaseOrderGroups.map(g => ({...g})),
        id = purchaseOrderGroups.reduce((min, g) => Math.min(min, g.id), 0) - 1,
        items = (state.seasonalProduct?.varieties || []).map(v => ({
          id: itemId--,
          purchaseOrderId: id,
          varietyId: v.id,
          varietyName: v.name,
          sizeName: null,
          colourName: v.colourName,
          applicationColour: v.applicationColour,
          quantityOrdered: 0,
          quantityConfirmed: null,
          lotNumber: null,
          comments: null
        })),
        purchaseOrders = [
          {
            id,
            purchaseOrderNumber: null,
            comments: null,
            internalNotes: null,
            supplierId,
            requestedWeekId,
            actualWeekId: requestedWeekId,
            confirmedDate: null,
            confirmedBy: null,
            orderPlacedDate: null,
            orderPlacedBy: null,
            enteredIntoDPODate: null,
            enteredIntoDPOBy: null,
            startWeek: null,
            endWeek: null,
            items
          }
        ],
        group = {id, wishListProductSowPeriodId, isFlorist: false, wishListProductSizes: [], purchaseOrders};
      purchaseOrderGroups.push(group);

      state.purchaseOrderGroups = purchaseOrderGroups;
    },
    addPurchaseOrder(state, {payload}: PayloadAction<models.PurchaseOrderGroup>) {
      let itemId = 0;

      const wishListProductSowPeriodId = state.sowPeriodId,
        sowPeriod = state.sowPeriods.find(sp => sp.id === wishListProductSowPeriodId),supplierId = state.suppliers[0]?.id || 0,
        requestedWeekId = sowPeriod?.startWeekId || state.weeks.filter(w => w.year === new Date().getFullYear())[0]?.id || 0,
        purchaseOrderGroups = state.purchaseOrderGroups.map(g => ({...g})),
        group = purchaseOrderGroups.find(g => g.id === payload.id);

      if(group) {
        const purchaseOrders = group.purchaseOrders.map(o => ({...o})),
          id = purchaseOrderGroups.reduce((groupMin, group) =>
            Math.min(groupMin, group.purchaseOrders.reduce((poMin, po) => Math.min(poMin, po.id), 0)), 0) - 1,
          items = (state.seasonalProduct?.varieties || []).map(v => ({
            id: itemId--,
            purchaseOrderId: id,
            varietyId: v.id,
            varietyName: v.name,
            sizeName: null,
            colourName: v.colourName,
            applicationColour: v.applicationColour,
            quantityOrdered: 0,
            quantityConfirmed: null,
            lotNumber: null,
            comments: null
          })),
          purchaseOrder = {
            id,
            purchaseOrderNumber: null,
            comments: null,
            internalNotes: null,
            supplierId,
            requestedWeekId,
            actualWeekId: requestedWeekId,
            confirmedDate: null,
            confirmedBy: null,
            orderPlacedDate: null,
            orderPlacedBy: null,
            enteredIntoDPODate: null,
            enteredIntoDPOBy: null,
            startWeek: null,
            endWeek: null,
            items
          };

        purchaseOrders.push(purchaseOrder);
        group.purchaseOrders = purchaseOrders;
        state.purchaseOrderGroups = purchaseOrderGroups;
      }
    },
    removePurchaseOrder(state, {payload}: PayloadAction<RemovePurchaseOrderArgs>) {
      const {purchaseOrderGroupId, purchaseOrderId} = payload,        
        groups = state.purchaseOrderGroups.map(g => ({...g})),
        group = groups.find(g => g.id === purchaseOrderGroupId);
      
      if(group) {
        const purchaseOrders = group.purchaseOrders
          .filter(o => o.id !== purchaseOrderId)  
          .map(o => ({...o}));

        group.purchaseOrders = purchaseOrders;
        state.purchaseOrderGroups = groups;
      }

      if(state.weeklyProduct) {
        state.weeklyProduct = updateAllWeeklyVarietySowPeriods(state.weeklyProduct, state.sowPeriods, state.purchaseOrderGroups);
      }
    },
    setPurchaseOrderRequestedWeekId(state, {payload}: PayloadAction<PurchaseOrderArgs<number>>) {
      const {purchaseOrderGroupId, purchaseOrderId, value} = payload,
        groups = state.purchaseOrderGroups.map(g => ({...g})),
        group = groups.find(g => g.id === purchaseOrderGroupId),
        orders = group?.purchaseOrders.map(o => ({...o})) || [],
        index = orders.findIndex(o => o.id === purchaseOrderId);

      if(group && index !== -1) {
        orders[index].requestedWeekId = value;
        // for new orders, update the actual week as well
        if(orders[index].id <= 0) {
          orders[index].actualWeekId = value;
        }
        group.purchaseOrders = orders;
        state.purchaseOrderGroups = groups;
      }

      if(state.weeklyProduct) {
        state.weeklyProduct = updateAllWeeklyVarietySowPeriods(state.weeklyProduct, state.sowPeriods, state.purchaseOrderGroups);
      }
    },
    setPurchaseOrderSupplierId(state, {payload}: PayloadAction<PurchaseOrderArgs<number>>) {
      const {purchaseOrderGroupId, purchaseOrderId, value} = payload,
        groups = state.purchaseOrderGroups.map(g => ({...g})),
        group = groups.find(g => g.id === purchaseOrderGroupId),
        orders = group?.purchaseOrders.map(o => ({...o})) || [],
        index = orders.findIndex(o => o.id === purchaseOrderId);

      if(group && index !== -1) {
        orders[index].supplierId = value;
        group.purchaseOrders = orders;
        state.purchaseOrderGroups = groups;
      }
    },
    setSeasonalPurchaseOrderItemQuantityOrdered(state, {payload}: PayloadAction<PurchaseOrderItemArgs<number>>) {
      const {purchaseOrderGroupId, purchaseOrderId, purchaseOrderItemId, varietyId, value} = payload,
        groups = state.purchaseOrderGroups.map(g => ({...g})),
        group = groups.find(g => g.id === purchaseOrderGroupId),
        orders = group?.purchaseOrders.map(o => ({...o})) || [],
        order = orders.find(o => o.id === purchaseOrderId),
        items = order?.items.map(i => ({...i})) || [],
        index = items.findIndex(i => i.id === purchaseOrderItemId);

      if(order && group) {
        if(index === -1) {
          const variety = state.seasonalProduct?.varieties.find(v => v.id === varietyId);
          if(variety) {
            const id = items.reduce((min, item) => Math.min(min, item.id), 0) - 1,
              item = {id, purchaseOrderId, varietyId: variety.id, varietyName: variety.name, colourName: variety.colourName, sizeName: null, applicationColour: variety.applicationColour,
                  quantityOrdered: value, quantityConfirmed: null, lotNumber: null, comments: null};
            items.push(item);
          }
        } else {
          items[index].quantityOrdered = value;          
        }

        order.items = items;
        group.purchaseOrders = orders;
        state.purchaseOrderGroups = groups;
      }

      if(state.weeklyProduct) {
        state.weeklyProduct = updateAllWeeklyVarietySowPeriods(state.weeklyProduct, state.sowPeriods, state.purchaseOrderGroups);
      }
    },
    setWeeklyPurchaseOrderItemQuantityOrdered(state, {payload}: PayloadAction<PurchaseOrderItemArgs<number>>) {
      const {purchaseOrderGroupId, purchaseOrderId, purchaseOrderItemId, varietyId, value} = payload,
        groups = state.purchaseOrderGroups.map(g => ({...g})),
        group = groups.find(g => g.id === purchaseOrderGroupId),
        orders = group?.purchaseOrders.map(o => ({...o})) || [],
        order = orders.find(o => o.id === purchaseOrderId),
        items = order?.items.map(i => ({...i})) || [],
        index = items.findIndex(i => i.id === purchaseOrderItemId);

      if(order && group) {
        if(index === -1) {
          const variety = state.weeklyProduct?.varieties.find(v => v.id === varietyId);
          if(variety) {
            const id = items.reduce((min, item) => Math.min(min, item.id), 0) - 1,
              item = {id, purchaseOrderId, varietyId: variety.id, varietyName: variety.name, colourName: variety.colourName, sizeName: null, applicationColour: variety.applicationColour,
                  quantityOrdered: value, quantityConfirmed: null, lotNumber: null, comments: null};
            items.push(item);
          }
        } else {
          items[index].quantityOrdered = value;          
        }

        order.items = items;
        group.purchaseOrders = orders;
        state.purchaseOrderGroups = groups;
      }

      if(state.weeklyProduct) {
        state.weeklyProduct = updateAllWeeklyVarietySowPeriods(state.weeklyProduct, state.sowPeriods, state.purchaseOrderGroups);
      }
    },
    setPurchaseOrderGroupWishListProductSize(state, {payload}: PayloadAction<SetPurchaseOrderGroupWishListProductSizeArgs>) {
      const {purchaseOrderGroupId, wishListProductSize, selected} = payload,
        purchaseOrderGroups = state.purchaseOrderGroups.map(g => ({...g})),
        index = purchaseOrderGroups.findIndex(g => g.id === purchaseOrderGroupId);

      if(index !== -1) {
        const group = purchaseOrderGroups[index];
          
        if(selected) {
          const id = group.wishListProductSizes.reduce((min, ps) => Math.min(min, ps.id), 0) - 1;

          group.wishListProductSizes.push({
            id,
            purchaseOrderGroupId,
            wishListProductSizeId: wishListProductSize.id,
            sizeId: wishListProductSize.sizeId,
            sizeName: wishListProductSize.sizeName,
            roundNumber: wishListProductSize.roundNumber,
            roundName: wishListProductSize.roundName
          })
        } else {
          const varieties = state.seasonalProduct?.varieties || [];

          group.wishListProductSizes = group.wishListProductSizes
            .filter(ps => ps.wishListProductSizeId !== wishListProductSize.id)
            .map(ps => ({...ps}));

          group.purchaseOrders = group.purchaseOrders.map(po => {
            const items = po.items.filter(i => varieties.find(v => v.id === i.varietyId))
              .map(i => ({...i})),
              order = {...po, items};

            return order;
          });
        }

        state.purchaseOrderGroups = purchaseOrderGroups;
      }
    },
    setPurchaseOrderGroupSize(state, {payload}: PayloadAction<SetPurchaseOrderGroupWishListProductSizeArgs>) {
      const {purchaseOrderGroupId, wishListProductSize, selected} = payload,
        purchaseOrderGroups = state.purchaseOrderGroups.map(g => ({...g})),
        index = purchaseOrderGroups.findIndex(g => g.id === purchaseOrderGroupId);

      if(index !== -1) {
        const group = purchaseOrderGroups[index],
          wishListProductSizes = state.seasonalProduct?.wishListSizes.filter(s => s.sizeId === wishListProductSize.sizeId) || [];
          
        if(selected) {
          // add all the product sizes of the selected size
          const id = group.wishListProductSizes.reduce((min, ps) => Math.min(min, ps.id), 0) - 1,
            newSizes = wishListProductSizes
              .map((s, index) => ({
                id: id - index,
                purchaseOrderGroupId,
                wishListProductSizeId: s.id,
                sizeId: s.sizeId,
                sizeName: s.sizeName,
                roundNumber: s.roundNumber,
                roundName: s.roundName
              }));

          group.wishListProductSizes = group.wishListProductSizes.map(s => ({...s})).concat(newSizes);
        } else {
          // remove all the product sizes of the selected size
          const ids = wishListProductSizes.map(s => s.id)
          group.wishListProductSizes = group.wishListProductSizes
            .filter(ps => ids.indexOf(ps.id) === -1)
            .map(ps => ({...ps}));
        }

        state.purchaseOrderGroups = purchaseOrderGroups;
      }
    },
    setPurchaseOrderInternalNotes(state, {payload}: PayloadAction<PurchaseOrderArgs<string | null>>) {
      const {purchaseOrderGroupId, purchaseOrderId, value} = payload,
        groups = state.purchaseOrderGroups.map(g => ({...g})),
        group = groups.find(g => g.id === purchaseOrderGroupId),
        orders = group?.purchaseOrders.map(o => ({...o})) || [],
        index = orders.findIndex(o => o.id === purchaseOrderId);

      if(group && index !== -1) {
        orders[index].internalNotes = value;
        group.purchaseOrders = orders;
        state.purchaseOrderGroups = groups;
      }
    },
    setSowPeriodId(state, {payload}: PayloadAction<number | null>) {
      state.sowPeriodId = payload;
    }
  },
  extraReducers: builder =>
    builder
      .addMatcher(propagationApi.endpoints.productDetail.matchFulfilled, (state, {payload}) => {
        const {product, purchaseOrderGroups, weeks, suppliers} = payload;
        if(product.isWeekly) {
          const weeklyProduct = product as models.ProductDetailWeekly;          
          state.seasonalProduct = null;
          const {sowPeriods, colours} = payload as WeeklyProductDetailResponse;
          state.sowPeriods = sowPeriods;
          state.colours = colours;
          if(!state.sowPeriodId && state.sowPeriods.length) {
            state.sowPeriodId = state.sowPeriods[0].id;
          }

          state.weeklyProduct = updateAllWeeklyVarietySowPeriods(weeklyProduct, sowPeriods, purchaseOrderGroups);

        } else {
          state.seasonalProduct = product as models.ProductDetailSeasonal;
          state.weeklyProduct = null;
        }
        state.purchaseOrderGroups = purchaseOrderGroups;
        state.weeks = weeks;
        state.suppliers = suppliers;
      })
      .addMatcher(propagationApi.endpoints.productDetailUpdate.matchFulfilled, (state, {payload: product}) => {
        if(product.isWeekly) {
          state.weeklyProduct = product as models.ProductDetailWeekly;
        } else {
          state.seasonalProduct = product as models.ProductDetailSeasonal;
        }
      })
});

export const {setSeasonalProduct, setWeeklyProduct, setSeasonalVarietyWishListSizeQuantity, setWeeklyVarietyOverage, setWeeklyVarietyBeginningInventory,
  addPurchaseOrderGroup, addPurchaseOrder, removePurchaseOrder, setPurchaseOrderGroupWishListProductSize,
  setSeasonalPurchaseOrderItemQuantityOrdered, setWeeklyPurchaseOrderItemQuantityOrdered, setPurchaseOrderRequestedWeekId,
  setPurchaseOrderSupplierId, setPurchaseOrderInternalNotes, setSowPeriodId} = detailSlice.actions;

export const selectSeasonalProduct = (state: RootState) => state.propagationProductDetail.seasonalProduct;
export const selectWeeklyProduct = (state: RootState) => state.propagationProductDetail.weeklyProduct;
export const selectAllPurchaseOrderGroups = (state: RootState) => state.propagationProductDetail.purchaseOrderGroups;
export const selectSuppliers = (state: RootState) => state.propagationProductDetail.suppliers;
export const selectWeeks = (state: RootState) => state.propagationProductDetail.weeks;
export const selectSowPeriodId = (state: RootState) => state.propagationProductDetail.sowPeriodId;
export const selectSowPeriods = (state: RootState) => state.propagationProductDetail.sowPeriods;
export const selectColours = (state: RootState) => state.propagationProductDetail.colours;

export const selectSowPeriod = createSelector(
  selectSowPeriods,
  selectSowPeriodId,
  (sowPeriods, sowPeriodId) => sowPeriods.find(p => p.id === sowPeriodId) || null
);

export const selectPurchaseOrderGroups = createSelector(
  selectAllPurchaseOrderGroups,
  selectSowPeriodId,
  (groups, sowPeriodId) => groups.filter(g => sowPeriodId == null || g.wishListProductSowPeriodId === sowPeriodId)
);

export const selectProductColours = createSelector(
  selectSeasonalProduct,
  product => (product?.wishListSizes || [])
    .reduce((memo, s) => 
      memo.concat(
        s.colours
          .filter(c => !memo.some(m => c.colourId === m.id))
          .map(c => ({id: c.colourId, name: c.colourName, applicationColour: c.applicationColour})))
    , [] as ProductColour[])
    .sort(sortByName)
);

export const selectSeasonalProductVarieties = createSelector(
  selectSeasonalProduct,
  product => (product?.varieties || [])
    .map(v => ({...v}))
    .sort((a, b) => sortByColourName(a, b) || sortByName(a, b))
);

export const selectWeeklyProductVarieties = createSelector(
  selectWeeklyProduct,
  product => (product?.varieties || [])
    .map(v => ({...v}))
    .sort((a, b) => sortByColourName(a, b) || sortByName(a, b))
);

export const selectProductVarietiesBySize = createSelector(
  selectWeeklyProduct,
  product => {
    const sizes = (product?.wishListSizes || [])
      .reduce((memo, s) => {
        if(!memo.find(m => m.sizeId === s.sizeId)) {
          memo.push({sizeId: s.sizeId, sizeName: s.sizeName});
        }
        return memo;
      }, [] as ProductVarietyBySizeSize[]),
    varieties = (product?.varieties || [])
      .map(v => ({...v}))
      .reduce((memo, v) => {
        const existing = v.sizeId ? memo.find(s => s.size?.sizeId === v.sizeId) : memo.find(s => s.size == null);
        if(existing) {
          const varieties = existing.varieties.map(v => ({...v}));
          varieties.push(v);
          existing.varieties = varieties.sort((a, b) => sortByColourName(a, b) || sortByName(a, b));
        } else {
          const size = v.sizeId ? (sizes.find(s => s.sizeId === v.sizeId) || null) : null;
          memo.push({size, varieties: [v]});
        }
        return memo;
      }, [] as ProductVarietyBySize[])
      .sort((a, b) => sortSizeName(a.size?.sizeName || '', b.size?.sizeName || ''));

    return varieties;
  });

export interface ProductVarietyBySizeSize {
  sizeId: number, sizeName: string;
}

export interface ProductVarietyBySize {
  size: ProductVarietyBySizeSize | null;
  varieties: models.ProductDetailWeeklyVariety[];
}

export default detailSlice.reducer;
