import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { propagationApi } from 'api/propagation-service';
import * as models from 'api/models/propagation';
import { RootState } from 'app/store';

export interface ConfigurationState {
  product: models.ProductConfigurationProduct | null;
  colours: models.Colour[];
  startElements: models.StartElement[];
  suppliers: models.Supplier[];
  trays: models.PropagationTray[];
  weeks: models.PurchaseOrderWeek[];
}

const initialState: ConfigurationState = {
  product: null,
  colours: [],
  startElements: [],
  suppliers: [],
  trays: [],
  weeks: []
};

export const configurationSlice = createSlice({
  name: 'propagation/configuration',
  initialState,
  reducers: {
    setProduct(state, {payload}: PayloadAction<models.ProductConfigurationProduct>) {
      state.product = payload;
    }
  },
  extraReducers: builder =>
    builder.addMatcher(propagationApi.endpoints.productConfiguration.matchFulfilled, (state, {payload}) => {
      const {product, colours, startElements, suppliers, trays, weeks} = payload;
      state.product = product;
      state.colours = colours;
      state.startElements = startElements;
      state.suppliers = suppliers;
      state.trays = trays;
      state.weeks = weeks;
    })
});

export const { setProduct } = configurationSlice.actions;

export const selectProduct = (state: RootState) => state.propagationProductConfiguration.product;
export const selectColours = (state: RootState) => state.propagationProductConfiguration.colours;
export const selectStartElements = (state: RootState) => state.propagationProductConfiguration.startElements;
export const selectSuppliers = (state: RootState) => state.propagationProductConfiguration.suppliers;
export const selectTrays = (state: RootState) => state.propagationProductConfiguration.trays;
export const selectWeeks = (state: RootState) => state.propagationProductConfiguration.weeks;

export const selectWishListColours = createSelector(
  selectColours,
  colours => colours.filter(c => c.isWishListColour)
);

export default configurationSlice.reducer;
