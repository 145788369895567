import { createSlice, PayloadAction, createAction, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import { ProblemDetails } from 'utils/problem-details';
import * as models from 'api/models/billing';
import { RootState } from 'app/store';
import { billingApi, EmailInvoicesReponse, BrokersResponse } from 'api/billing-service';

export interface EmailInvoicesState {
  loading: boolean;
  error: ProblemDetails | null;
  brokers: models.BillingBroker[];
  broker: models.BillingBroker | null;
  orderIds: number[];
  emailAddress: string;
  subject: string;
  body: string;
  bcc: string | null;
}

const initialState: EmailInvoicesState = {
  loading: false,
  error: null,
  broker: null,
  brokers: [],
  orderIds: [],
  emailAddress: '',
  subject: 'Orchard Park Growers Invoice',
  body: 'Invoices are attached',
  bcc: null
};

export const emailInvoices: AsyncThunk<EmailInvoicesReponse, void, {state: RootState}> = createAsyncThunk(
  'billing/emailInvoices',
  async (_, {getState,rejectWithValue}) => {
    try {
      const state = getState().emailInvoices,
        {orderIds, emailAddress, subject, body, bcc} = state;
      return await billingApi.emailInvoices(orderIds, emailAddress, subject, body, bcc);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const getBrokers: AsyncThunk<BrokersResponse, void, {state: RootState}> = createAsyncThunk(
  'billing/getBrokers',
  async (_, {rejectWithValue}) => {
    try {
      return await billingApi.getBrokers();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const emailInvoicesPending = createAction(emailInvoices.pending.type),
  emailInvoicesFulfilled = createAction(emailInvoices.fulfilled.type),
  emailInvoicesRejected = createAction<ProblemDetails>(emailInvoices.rejected.type),
  getBrokersFulfilled = createAction<BrokersResponse>(getBrokers.fulfilled.type),
  getBrokersRejected = createAction<ProblemDetails>(getBrokers.rejected.type);

export const emailInvoicesSlice = createSlice({
  name: 'billing/emailInvoicesSlice',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setEmailAddress(state, action: PayloadAction<string>) {
      state.emailAddress = action.payload;
    },
    setBcc(state, action: PayloadAction<string | null>) {
      state.bcc = action.payload;
    },
    setSubject(state, action: PayloadAction<string>) {
      state.subject = action.payload;
    },
    setBody(state, action: PayloadAction<string>) {
      state.body = action.payload;
    },
    setBroker(state, action: PayloadAction<models.BillingBroker | null>) {
      state.broker = action.payload;
    },
    addOrderId(state, action: PayloadAction<number>) {
      const orderIds = state.orderIds,
        id = action.payload,
        index = orderIds.indexOf(id);

      if(index === -1) {
        orderIds.push(id);
      }
      state.orderIds = orderIds;
    },
    removeOrderId(state, action: PayloadAction<number>) {
      const orderIds = state.orderIds,
        id = action.payload,
        index = orderIds.indexOf(id);

      if(orderIds.indexOf(id) !== -1) {
        orderIds.splice(index, 1);
      }
      state.orderIds = orderIds;
    },
    clearState(state) {
      
    }
  },
  extraReducers: builder =>
    builder
      .addCase(emailInvoicesPending, state => {
        state.error = null;
        state.loading = true;
      })
      .addCase(emailInvoicesFulfilled, state => {
        state.loading = false;
        state.orderIds = [];
        state.broker = null;
      })
      .addCase(emailInvoicesRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBrokersFulfilled, (state, action) => {
        const brokers = action.payload.brokers,
          broker = brokers[0],
          emailAddress = broker?.emailAddress || '';

        state.brokers = brokers;
        state.broker = broker;
        state.emailAddress = emailAddress;
      })
      .addCase(getBrokersRejected, (state, action) => {
        state.error = action.payload;
      })
});

export const { clearError, setEmailAddress, setSubject, setBcc,
  setBody, setBroker, addOrderId, removeOrderId } = emailInvoicesSlice.actions;

export const selectError = (state: RootState) => state.emailInvoices.error;
export const selectLoading = (state: RootState) => state.emailInvoices.loading;
export const selectOrderIds = (state: RootState) => state.emailInvoices.orderIds;
export const selectBroker = (state: RootState) => state.emailInvoices.broker;
export const selectBrokers = (state: RootState) => state.emailInvoices.brokers;
export const selectEmailAddress = (state: RootState) => state.emailInvoices.emailAddress;
export const selectSubject = (state: RootState) => state.emailInvoices.subject;
export const selectBody = (state: RootState) => state.emailInvoices.body;
export const selectBcc = (state: RootState) => state.emailInvoices.bcc;

export default emailInvoicesSlice.reducer;