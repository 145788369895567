import { createSlice, PayloadAction, createAction, createAsyncThunk, AsyncThunk, createSelector } from '@reduxjs/toolkit';
import { ProblemDetails } from 'utils/problem-details';
import * as models from 'api/models/billing';
import { RootState } from 'app/store';
import { billingApi, EmailConfirmationsReponse, BrokersResponse } from 'api/billing-service';

export interface EmailConfirmationsState {
  loading: boolean;
  error: ProblemDetails | null;
  brokers: models.BillingBroker[];
  broker: models.BillingBroker | null;
  customer: string | null;
  orderIds: number[];
  emailAddress: string;
  subject: string | null;
  body: string | null;
  bcc: string | null;
}

const initialState: EmailConfirmationsState = {
  loading: false,
  error: null,
  broker: null,
  brokers: [],
  customer: null,
  orderIds: [],
  emailAddress: '',
  subject: 'Orchard Park Growers Confirmation',
  body: null,
  bcc: null
};

export const emailConfirmations: AsyncThunk<EmailConfirmationsReponse, void, {state: RootState}> = createAsyncThunk(
  'billing/emailConfirmations',
  async (_, {getState,rejectWithValue}) => {
    try {
      const state = getState().emailConfirmations,
        {orderIds, emailAddress, subject, body, bcc} = state;
      return await billingApi.emailConfirmations(orderIds, emailAddress, subject || '', body || '', bcc);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const getBrokers: AsyncThunk<BrokersResponse, void, {state: RootState}> = createAsyncThunk(
  'billing/getBrokers',
  async (_, {rejectWithValue}) => {
    try {
      return await billingApi.getBrokers();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const emailConfirmationsPending = createAction(emailConfirmations.pending.type),
  emailConfirmationsFulfilled = createAction(emailConfirmations.fulfilled.type),
  emailConfirmationsRejected = createAction<ProblemDetails>(emailConfirmations.rejected.type),
  getBrokersFulfilled = createAction<BrokersResponse>(getBrokers.fulfilled.type),
  getBrokersRejected = createAction<ProblemDetails>(getBrokers.rejected.type);

export const emailConfirmationsSlice = createSlice({
  name: 'billing/emailConfirmationsSlice',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    setEmailAddress(state, action: PayloadAction<string>) {
      state.emailAddress = action.payload;
    },
    setSubject(state, action: PayloadAction<string>) {
      state.subject = action.payload;
    },
    setBody(state, action: PayloadAction<string>) {
      state.body = action.payload;
    },
    setBcc(state, action: PayloadAction<string | null>) {
      state.bcc = action.payload;
    },
    setBroker(state, action: PayloadAction<models.BillingBroker | null>) {
      state.broker = action.payload;
    },
    setCustomer(state, action: PayloadAction<string | null>) {
      state.customer = action.payload;
    },
    addOrderId(state, action: PayloadAction<number>) {
      const orderIds = state.orderIds,
        id = action.payload,
        index = orderIds.indexOf(id);

      if(index === -1) {
        orderIds.push(id);
      }
      state.orderIds = orderIds;
    },
    removeOrderId(state, action: PayloadAction<number>) {
      const orderIds = state.orderIds,
        id = action.payload,
        index = orderIds.indexOf(id);

      if(orderIds.indexOf(id) !== -1) {
        orderIds.splice(index, 1);
      }
      state.orderIds = orderIds;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(emailConfirmationsPending, state => {
        state.error = null;
        state.loading = true;
      })
      .addCase(emailConfirmationsFulfilled, state => {
        state.loading = false;
        state.orderIds = [];
        state.broker = null;
      })
      .addCase(emailConfirmationsRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBrokersFulfilled, (state, action) => {
        const brokers = action.payload.brokers,
          broker = brokers[0],
          emailAddress = broker?.confirmationEmailAddress || '';

        state.brokers = brokers;
        state.broker = broker;
        state.emailAddress = emailAddress;
      })
      .addCase(getBrokersRejected, (state, action) => {
        state.error = action.payload;
      })
});

export const { clearError, setEmailAddress, setSubject,
  setBody, setBcc, setBroker, setCustomer, addOrderId, removeOrderId } = emailConfirmationsSlice.actions;

export const selectError = (state: RootState) => state.emailConfirmations.error;
export const selectLoading = (state: RootState) => state.emailConfirmations.loading;
export const selectOrderIds = (state: RootState) => state.emailConfirmations.orderIds;
export const selectBroker = (state: RootState) => state.emailConfirmations.broker;
export const selectBrokers = (state: RootState) => state.emailConfirmations.brokers;
export const selectCustomer = (state: RootState) => state.emailConfirmations.customer;
export const selectEmailAddress = (state: RootState) => state.emailConfirmations.emailAddress;
export const selectSubject = (state: RootState) => state.emailConfirmations.subject;
export const selectBody = (state: RootState) => state.emailConfirmations.body;
export const selectBcc = (state: RootState) => state.emailConfirmations.bcc;
const selectAllOrders = (state: RootState) => state.orderList.orders || [];

export const selectOrders = createSelector(
  selectAllOrders,
  selectCustomer,
  (orders, customer) => orders.filter(o => !customer || o.customerName === customer)
)

export const selectCustomers = createSelector(
  selectAllOrders,
  orders => orders.map(o => o.customerName).reduce((memo, c) => {
    if(memo.indexOf(c) === -1) {
      memo.push(c);
    }
    return memo;
  }, [] as string[]).sort()
);

export default emailConfirmationsSlice.reducer;
