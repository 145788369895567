import { createSlice, createAsyncThunk, AsyncThunk, createSelector, createAction, PayloadAction } from '@reduxjs/toolkit';
import { ProblemDetails } from 'utils/problem-details';
import { RootState } from 'app/store';
import * as models from 'api/models/wish-list';
import { CopyYearResponse, wishListApi, WishListHomeResponse } from 'api/wish-list-service';
import { getRevisions, createDraft, selectYear } from '../wish-list-slice';
import { sortBy } from 'utils/sort';
import { contains } from 'utils/equals';

export interface WishListHomeState {
  weeks: models.Week[] | null;
  products: models.WishListHomeProduct[] | null;
  growingLocations: models.WishListGrowingLocation[] | null;
  homeItems: models.WishListHomeItem[] | null;
  search: string;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: WishListHomeState = {
  weeks: null,
  products: null,
  growingLocations: null,
  homeItems: null,
  search: '',
  loading: false,
  error: null
}

interface HomeDataArgs {
  year: number;
  revision: models.WishListRevision | null;
}

export const getHomeData: AsyncThunk<WishListHomeResponse, HomeDataArgs, {state: RootState}> = createAsyncThunk(
  'wish-list/getHomeData',
  async (args, {rejectWithValue}) => {
    try {
      const {year, revision} = args;

      return await wishListApi.home(year, revision?.id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export interface CopyYearArgs {
  fromYear: number;
  toYear: number;
}

export const copyYear: AsyncThunk<CopyYearResponse, CopyYearArgs, {state: RootState}> = createAsyncThunk(
  'wish-list/copyYear',
  async (args, {rejectWithValue}) => {
    try {
      return await wishListApi.copyYear(args.fromYear, args.toYear);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getHomeDataPending = createAction(getHomeData.pending.type),
  getHomeDataFulfilled = createAction<WishListHomeResponse>(getHomeData.fulfilled.type),
  getHomeDataRejected = createAction<ProblemDetails>(getHomeData.rejected.type),
  getRevisionsRejected = createAction<ProblemDetails>(getRevisions.rejected.type),
  createDraftPending = createAction<ProblemDetails>(createDraft.pending.type),
  createDraftFulfilled = createAction(createDraft.fulfilled.type),
  createDraftRejected = createAction<ProblemDetails>(createDraft.rejected.type),
  copyYearPending = createAction(copyYear.pending.type),
  copyYearFulfilled = createAction<CopyYearResponse>(copyYear.fulfilled.type),
  copyYearRejected = createAction<ProblemDetails>(copyYear.rejected.type);

export const homeSlice = createSlice({
  name: 'wish-list',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearState(state) {
      state.error = null;
      state.weeks = null;
      state.products = null;
      state.growingLocations = null;
      state.homeItems = null;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(getHomeDataPending, state => {
        state.loading = true;
      })
      .addCase(getHomeDataFulfilled, (state, action) => {
        state.loading = false;
        state.weeks = action.payload.weeks;
        state.products = action.payload.products;
        state.growingLocations = action.payload.growingLocations;
        state.homeItems = action.payload.items;
      })
      .addCase(getHomeDataRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRevisionsRejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(createDraftPending, state => {
        state.loading = true;
      })
      .addCase(createDraftFulfilled, state => {
        state.loading = false;
      })
      .addCase(createDraftRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(copyYearPending, state => {
        state.loading = true;
      })
      .addCase(copyYearFulfilled, state => {
        state.loading = false;
      })
      .addCase(copyYearRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
});

export const selectState = (state: RootState) => state.wishListHome;
export const selectError = (state: RootState) => state.wishListHome.error;
export const selectLoading = (state: RootState) => state.wishListHome.loading;
export const selectProducts = (state: RootState) => state.wishListHome.products;
export const selectHomeItems = (state: RootState) => state.wishListHome.homeItems;
export const selectSearch = (state: RootState) => state.wishListHome.search;
export const selectFilteredProducts = createSelector(
  selectSearch,
  selectProducts,
  (search, products) => (products || []).filter(p => !search || contains(p.productName, search) || contains(p.wishListDisplayName, search))
);
export const selectGrowingLocations = createSelector(
  (state: RootState) => state.wishListHome.growingLocations,
  growingLocations => growingLocations?.slice(0).sort(sortBy('name')) || []
);
export const selectWeeks = (state: RootState) => state.wishListHome.weeks;

export const selectSpaceByWeek = createSelector(
  selectProducts,
  selectHomeItems,
  selectWeeks,
  selectYear,
  (products, homeItems, weeks, year) => {
    const makeWeekMap = (product: models.WishListHomeProduct) => {
        const productItems = (homeItems || []).filter(i => i.wishListProductId === product.id),
          map = (weeks || []).filter(w => w.year === year).reduce((memo, w) => {
            const weekItems = productItems.filter(i => i.weekNumber === w.weekNumber),
              totalSquareFeet = weekItems.reduce((total, i) => total + i.space, 0),
              availableSpace = weekItems.reduce((total, i) => total + i.available, 0);
              
            return memo.set(w.id, {weekNumber: w.weekNumber, totalSquareFeet, availableSpace});
          }, new Map<number, HomeWeek>());
        return map;
      },
      space = (products || []).reduce((memo, p) => memo.set(p.id, makeWeekMap(p)), new Map<number, Map<number, HomeWeek>>());

    return space;
  }
)

export const { clearError, clearState, setSearch } = homeSlice.actions;

export default homeSlice.reducer;

interface HomeWeek {
  totalSquareFeet: number;
  availableSpace: number;
  weekNumber: number;
}
