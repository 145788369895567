import {Middleware, MiddlewareAPI, Dispatch, Action} from 'redux';
import {RootState} from '..';
import axios from 'boot/axios';
import authService from 'features/auth/authorization-service';
import {setAuthenticated} from 'features/auth/auth-slice';

export const authorizationHeaderMiddleware: Middleware = 
  (_: MiddlewareAPI<Dispatch<Action<string>>, RootState>) =>
  (next: Dispatch<Action<any>>) =>
  async (action: Action<any> & {payload: any}): Promise<Action<any>> => {
    if(action.type === setAuthenticated.type) {
      if(action.payload?.user) {
        const token = await authService.getAccessToken();
        axios.defaults.headers['Authorization'] = `Bearer ${token}`;
      } else {
        axios.defaults.headers['Authorization'] = null;
      }
    }

    const user = await authService.getUser();
    if(user?.expired) {
      await authService.signIn(null);
    }

    return next(action);
  };
