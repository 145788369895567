import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import brokerReducer from 'features/admin/broker-slice';
import colourReducer from 'features/admin/colour-slice';
import customerReducer from 'features/admin/customer-slice';
import priceReducer from 'features/admin/price-slice';
import salesTaxReducer from 'features/admin/sales-tax-slice';
import varietyReducer from 'features/admin/variety-slice';
import authReducer from 'features/auth/auth-slice';
import orderListReducer from 'features/billing/orders/order-list-slice';
import orderDetailReducer from 'features/billing/orders/order-detail-slice';
import emailInvoicesReducer from 'features/billing/orders/email-invoices-slice';
import emailConfirmationsReducer from 'features/billing/orders/email-confirmations-slice';
import {authorizationHeaderMiddleware} from './store/middleware/authorization-headers';
import wishListReducer from 'features/wish-list/wish-list-slice';
import wishListHomeReducer from 'features/wish-list/list/index-slice';
import wishListProductAdminReducer from 'features/wish-list/admin/products-slice';
import wishListProductDetailAdminReducer from 'features/wish-list/admin/product-detail-slice';
import wishListProductDetailReducer from 'features/wish-list/product-detail-slice';
import wishListWeeklyProductReducer from 'features/wish-list/weekly/wish-list-product-weekly-slice';
import wishListSeasonalProductReducer from 'features/wish-list/seasonal/wish-list-product-seasonal-slice';
import growingLocationListReducer from 'features/growing-locations/list-slice';
import growingLocationDetailReducer from 'features/growing-locations/detail-slice';
import growingLocationEditDetailReducer from 'features/growing-locations/edit-detail-slice';
import allocations from 'features/wish-list/allocations/allocations-slice';
import { propagationApi } from 'api/propagation-service';
import propagationReducer from 'features/propagation/propagation-slice';
import propagationProductDetailReducer from 'features/propagation/detail/detail-slice';
import propagationProductDetailWeeklyReducer from 'features/propagation/detail/weekly/weekly-slice';
import propagationProductConfigurationReducer from 'features/propagation/configuration/configuration-slice';
import { propagationSupplierApi } from 'api/propagation-supplier-service';
import { propagationPurchaseOrderApi } from 'api/propagation-purchase-order-service';
import allocationAnalysis from 'features/wish-list/allocations/allocation-analysis-slice';

export const store = configureStore({
  reducer: {
    brokers: brokerReducer,
    colours: colourReducer,
    customers: customerReducer,
    prices: priceReducer,
    salesTaxes: salesTaxReducer,
    varieties: varietyReducer,
    auth: authReducer,
    orderList: orderListReducer,
    orderDetail: orderDetailReducer,
    emailInvoices: emailInvoicesReducer,
    emailConfirmations: emailConfirmationsReducer,
    wishList: wishListReducer,
    wishListHome: wishListHomeReducer,
    wishListProductDetail: wishListProductDetailReducer,
    wishListWeeklyProduct: wishListWeeklyProductReducer,
    wishListSeasonalProduct: wishListSeasonalProductReducer,
    wishListProductAdmin: wishListProductAdminReducer,
    wishListProductDetailAdmin: wishListProductDetailAdminReducer,
    growingLocationList: growingLocationListReducer,
    growingLocationDetail: growingLocationDetailReducer,
    growingLocationEditDetail: growingLocationEditDetailReducer,
    allocations,
    [propagationApi.reducerPath]: propagationApi.reducer,
    [propagationSupplierApi.reducerPath]: propagationSupplierApi.reducer,
    [propagationPurchaseOrderApi.reducerPath]: propagationPurchaseOrderApi.reducer,
    propagation: propagationReducer,
    propagationProductDetail: propagationProductDetailReducer,
    propagationProductDetailWeekly: propagationProductDetailWeeklyReducer,
    propagationProductConfiguration: propagationProductConfigurationReducer,
    allocationAnalysis
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    authorizationHeaderMiddleware,
    propagationApi.middleware,
    propagationSupplierApi.middleware,
    propagationPurchaseOrderApi.middleware
  )
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
