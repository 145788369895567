import Axios from 'axios';
import authService from 'features/auth/authorization-service';

const axios = Axios.create();

const subscription = authService.subscribe(async () => {
  await authService.ensureUserManagerInitialized();
  const token = await authService.getAccessToken();
  if(token) {
    console.log('setting access token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  authService.userManager?.events.addUserLoaded(args => {
    console.log('user loaded');
    if(args.access_token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${args.access_token}`;
    }
  });
  authService.userManager?.events.addUserUnloaded(() => {
    delete axios.defaults.headers.common['Authorization'];
  })

  authService.unsubscribe(subscription);
});

axios.interceptors.response.use(undefined, async err => {
  if(err.response.status === 401) {
    const config = err.config,
      user = await authService.userManager?.signinSilent();
    
    debugger;

    if(user) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
      config.headers['Authorization'] = `Bearer ${user.access_token}`;
      return axios.request(config);
    }
  }

  return Promise.reject(err);
});

export default axios;
