import { lazy } from 'react';
import { permissions } from 'features/auth/permissions';

export const routes = {
  home: {
    path: '/',
    component: lazy(() => import('pages/Home'))
  },
  admin: {
    path: '/admin',
    component: lazy(() => import('pages/Admin')),
    roles: [
      permissions.canAccessAdministrationSection,
      permissions.canEditVarieties,
      permissions.canEditBrokers,
      permissions.canEditCustomers,
      permissions.canEditColourPrices,
      permissions.canEditColours,
    ],
    routes: {
      brokers: {
        list: {
          path: '/admin/brokers',
          component: lazy(() => import('features/admin/Brokers')),
          roles: [permissions.canEditBrokers]
        }
      },
      colours: {
        list: {
          path: '/admin/colours',
          component: lazy(() => import('features/admin/Colours')),
          roles: [permissions.canEditColours]
        }
      },
      customers: {
        list: {
          path: '/admin/customers',
          component: lazy(() => import('features/admin/Customers')),
          roles: [permissions.canEditCustomers]
        }
      },
      prices: {
        list: {
          path: '/admin/prices',
          component: lazy(() => import('features/admin/Prices')),
          roles: [permissions.canEditColourPrices]
        }
      },
      salesTaxes: {
        list: {
          path: '/admin/salestaxes',
          component: lazy(() => import('features/admin/SalesTaxes')),
          roles: [permissions.canAccessAdministrationSection]
        }
      },
      varieties: {
        list: {
          path: '/admin/varieties',
          component: lazy(() => import('features/admin/Varieties')),
          roles: [permissions.canEditVarieties]
        }
      }
    }    
  },
  billing: {
    path: '/billing',
    component: lazy(() => import('pages/Billing')),
    roles: [permissions.canAccessBillingSection],
    routes: {
      list: {
        path: '/billing/orders',
        component: lazy(() => import('features/billing/orders/List'))
      },
      detail: {
        path: '/billing/orders/:id',
        to: (id: number | 'new') => `/billing/orders/${id}`,
        component: lazy(() => import('features/billing/orders/Detail'))
      }
    }
  },
  logs: {
    path: '/logs/:operationid',
    component: lazy(() => import('pages/Logs'))
  },
  apps: {
    path: '/apps',
    component: lazy(() => import('pages/Apps'))
  },
  wishList: {
    path: '/wish-list',
    to: (year: number) => `/wish-list?year=${year}`,
    component: lazy(() => import('pages/WishList')),
    routes: {
      admin: {
        products: {
          path: '/wish-list/admin/products',
          to: (year: number) => `/wish-list/admin/products?year=${year}`,
          component: lazy(() => import('features/wish-list/admin/Products'))
        },
        detail: {
          path: '/wish-list/admin/products/:id',
          to: (id: number, year: number) => `/wish-list/admin/products/${id}?year=${year}`,
          component: lazy(() => import('features/wish-list/admin/ProductDetail'))
        }
      },
      allocations: {
        path: '/wish-list/allocations',
        component: lazy(() => import('features/wish-list/allocations/Allocations')),
        to: (year: number) => `/wish-list/allocations?year=${year}`
      },
      detail: {
        path: '/wish-list/:id',
        to: (id: number, year: number) => `/wish-list/${id}?year=${year}`,
        component: lazy(() => import('features/wish-list/Detail'))
      }
    }
  },
  growingLocations: {
    path: '/growing-locations',
    to: (year: number) => `/growing-locations?year=${year}`,
    component: lazy(() => import('features/growing-locations/List')),
    routes: {      
      detail: {
        path: '/growing-locations/detail/:id',
        component: lazy(() => import('features/growing-locations/Detail')),
        to: (id: number, year: number) => `/growing-locations/detail/${id}?year=${year}`
      }      
    }
  },
  propagation: {
    routes: {
      productList: {
        path: '/propagation/products',
        component: lazy(() => import('features/propagation/detail/List')),
        to: (year: number) => `/propagation/products?year=${year}`
      },
      productDetail: {
        path: '/propagation/products/:id',
        component: lazy(() => import('features/propagation/detail/Detail')),
        to: (id: number, year: number) => `/propagation/products/${id}?year=${year}`
      },
      productConfiguration: {
        path: '/propagation/products/:id/configuration',
        component: lazy(() => import('features/propagation/configuration/Configuration')),
        to: (id: number, year: number) => `/propagation/products/${id}/configuration?year=${year}`
      },
      supplierList: {
        path: '/propagation/suppliers',
        component: lazy(() => import('features/propagation/suppliers/List')),
        to: (year: number) => `/propagation/suppliers?year=${year}`
      },
      supplierDetail: {
        path: '/propagation/suppliers/:id',
        component: lazy(() => import('features/propagation/suppliers/Detail')),
        to: (id: number | 'new', year: number) => `/propagation/suppliers/${id}?year=${year}`
      },
      purchaseOrderList: {
        path: '/propagation/purchase-orders',
        component: lazy(() => import('features/propagation/purchase-orders/List')),
        to: (year: number) => `/propagation/purchase-orders?year=${year}`
      },
      purchaseOrderNew: {
        path: '/propagation/purchase-orders/new',
        component: lazy(() => import('features/propagation/purchase-orders/New')),
        to: (year: number) => `/propagation/purchase-orders/new?year=${year}`
      },
      purchaseOrderDetail: {
        path: '/propagation/purchase-orders/:id',
        component: lazy(() => import('features/propagation/purchase-orders/Detail')),
        to: (id: number, year: number) => `/propagation/purchase-orders/${id}?year=${year}`
      },
      incomingPurchaseOrderList: {
        path: '/propagation/purchase-orders/incoming',
        component: lazy(() => import('features/propagation/purchase-orders/Incoming')),
        to: (year: number) => `/propagation/purchase-orders/incoming?year=${year}`
      },
      gerberaColours: {
        path: '/propagation/gerbera-colours',
        component: lazy(() => import('features/propagation/gerbera-colours/List')),
        to: (year: number) => `/propagation/gerbera-colours?year=${year}`
      }
    }
  }
};
