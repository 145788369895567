import { createSlice, createAction, createSelector, PayloadAction, AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { GrowingLocationResponse, GrowingLocationDetailResponse, growingLocationApi, GrowingLocationPrioritiesResponse, GrowingLocationPriority } from 'api/growing-location-service';
import * as models from 'api/models/growing-locations';
import { saveGrowingLocation } from './edit-detail-slice';
import { sortBy } from 'utils/sort';
import { createProblemDetails, ProblemDetails } from 'utils/problem-details';
import { selectYear } from 'features/wish-list/wish-list-slice';
import { Week } from 'api/models/wish-list';

export interface GrowingLocationZoneWeek extends models.GrowingLocationZoneWeek {
  zoneName: string;
  zoneNotes: string | null;
  revision: number;
  uniqueId: string;
}

export interface GrowingLocationWeek {
  weekId: number;
  weekNumber: number;
  revision: number;
  uniqueId: string;
  zones: GrowingLocationZoneWeek[];
  totalSquareFeet: number;
}

export interface GrowingLocationWeekMap {
  [index: number]: GrowingLocationWeek;
}

export interface GrowingLocationDetailState {
  location: models.GrowingLocation | null;
  growingLocationWeekProductSizes: models.GrowingLocationWeekWishListProductSize[];
  weeks: models.Week[];
  zones: GrowingLocationWeekMap;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: GrowingLocationDetailState = {
  location: null,
  growingLocationWeekProductSizes: [],
  weeks: [],
  zones: {},
  loading: false,
  error: null
};

export interface CopyWeekForwardArgs {
  fromWeekId: number;
  toWeekId: number;
  zoneWeeks: models.GrowingLocationZoneWeek[];
}

export interface CopyGrowingLocationWeekWishListProductSizeArgs {
  id: number;
  toWeekId: number;
}

export interface CopyGrowingLocationWeekWishListAvailableProductsForwardArgs {
  fromWeekId: number;
  toWeekId: number;
}

export interface SetZoneWeekValueArgs<T> {
  weekId: number;
  growingLocationZoneId: number;
  value: T;
}

export const saveGrowingLocationWeeks: AsyncThunk<GrowingLocationResponse, number, {state: RootState}> = createAsyncThunk(
  'growing-locations/saveGrowingLocations',
  async (year, {getState, rejectWithValue}) => {
    const rootState = getState(),
      {location, zones, growingLocationWeekProductSizes: allGrowingLocationWeekProductSizes, weeks: allWeeks} = rootState.growingLocationDetail,
      {wishListProducts} = rootState.growingLocationList,
      [minWeek, maxWeek] = findMinMaxWeeks(year, allGrowingLocationWeekProductSizes, wishListProducts || [], allWeeks);
    if(!location) {
      return rejectWithValue(createProblemDetails('Please choose a location'));
    }

    try {
      const locationId = location.id,
        zoneWeeks = (Object.values(zones) as GrowingLocationWeek[])
          .filter(z => z.weekId >= minWeek && z.weekId <= maxWeek)
          .reduce((memo, z) => memo.concat(z.zones), [] as GrowingLocationZoneWeek[])
          .map(zw => ({id: zw.id, weekId: zw.weekId, growingLocationZoneId: zw.growingLocationZoneId, squareFeet: zw.squareFeet, isAvailable: zw.isAvailable})),
        growingLocationWeekProductSizes = allGrowingLocationWeekProductSizes.filter(ps => ps.weekId >= minWeek && ps.weekId <= maxWeek);

      return await growingLocationApi.updateZoneWeeks(locationId, year, zoneWeeks, growingLocationWeekProductSizes);

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updatePriorities: AsyncThunk<GrowingLocationPrioritiesResponse, GrowingLocationPriority[], {state: RootState}> = createAsyncThunk(
  'growing-locations/updatePriorities',
  async (priorities, {getState, rejectWithValue}) => {
    const rootState = getState(),
      {location} = rootState.growingLocationDetail,
      {year} = rootState.wishList;
    if(!location) {
      return rejectWithValue(createProblemDetails('Please choose a location'));
    }

    try {

      return await growingLocationApi.updatePriorities(location.id, year, priorities);

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const saveGrowingLocationWeeksFulfilled = createAction<GrowingLocationResponse>(saveGrowingLocationWeeks.fulfilled.type),
  saveGrowingLocationFulfilled = createAction<GrowingLocationResponse | undefined>(saveGrowingLocation.fulfilled.type),
  updatePrioritiesFulfilled = createAction<GrowingLocationPrioritiesResponse>(updatePriorities.fulfilled.type),
  updatePrioritiesRejected = createAction<ProblemDetails>(updatePriorities.rejected.type);

interface GetLocationArgs {
  id: number;
  year: number;
}

export const getLocation: AsyncThunk<GrowingLocationDetailResponse, GetLocationArgs, {state: RootState}> = createAsyncThunk(
  'growing-locations/getLocation',
  async (args, {rejectWithValue}) => {
    try {
      const {id, year} = args;
      return await growingLocationApi.getOne(id, year);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export interface AddGrowingLocationWeekWishListProductSizeArgs {
  weekId: number;
  wishListProductId: number;
  sizeId: number;
  sizeName: string;
}

export interface EditGrowingLocationWeekWishListProductSizeArgs {
  id: number;
  allowTight: boolean;
  allowSpaced: boolean;
}

const getLocationPending = createAction(getLocation.pending.type),
  getLocationFulfilled = createAction<GrowingLocationDetailResponse>(getLocation.fulfilled.type),
  getLocationRejected = createAction<ProblemDetails>(getLocation.rejected.type);

export const growingLocationDetailSlice = createSlice({
  name: 'growing-locations/detail',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    },
    clearState(state) {
      state.location = null;      
    },
    setZoneWeekSquareFeet(state, action: PayloadAction<SetZoneWeekValueArgs<number>>) {
      const location = state.location,
        {weekId, growingLocationZoneId, value} = action.payload;
      if(location) {
        const zones = location.zones.slice(),
          zoneIndex = zones.findIndex(z => z.id === growingLocationZoneId);
        
        if(zoneIndex !== -1) {
          const zone = {...zones[zoneIndex]},
            weeks = zone.weeks.slice(),
            weekIndex = weeks.findIndex(w => w.weekId === weekId);

          if(weekIndex !== -1) {
            const week = {...weeks[weekIndex], squareFeet: value};
            weeks.splice(weekIndex, 1, week);
          }

          zone.weeks = weeks;
          zones.splice(zoneIndex, 1, zone);

          const zoneMap = {...state.zones};
          const growingLocationWeek = zoneMap[weekId];
          zoneMap[weekId] = setWeekGrowingLocationSquareFeet(growingLocationWeek, location, zone, value);
          state.zones = zoneMap;
        }

        location.zones = zones.sort(sortBy('name'));
        state.location = location;
      }
    },
    setZoneWeekIsAvailable(state, action: PayloadAction<SetZoneWeekValueArgs<boolean>>) {
      const location = state.location,
        {weekId, growingLocationZoneId, value} = action.payload;
      if(location) {
        const zones = location.zones.slice(),
          zoneIndex = zones.findIndex(z => z.id === growingLocationZoneId);
        
        if(zoneIndex !== -1) {
          const zone = {...zones[zoneIndex]},
            zoneMap = {...state.zones},
            weeks = zone.weeks.slice(),
            weekIndex = weeks.findIndex(w => w.weekId === weekId);

          if(weekIndex !== -1) {
            const squareFeet = value ? zone.squareFeet : 0,
              week = {...weeks[weekIndex], isAvailable: value, squareFeet };
            weeks.splice(weekIndex, 1, week);

            zoneMap[weekId] = setWeekGrowingLocationSquareFeet(zoneMap[weekId], location, zone, squareFeet);
          }

          zone.weeks = weeks;
          zones.splice(zoneIndex, 1, zone);
          
          zoneMap[weekId] = setWeekGrowingLocationIsAvailable(zoneMap[weekId], location, zone, value);
          state.zones = zoneMap;
        }

        location.zones = zones;
        state.location = location;
      }
    },
    resetWeekToDefaults(state, action: PayloadAction<number>) {
      const location = state.location,
        weekId = action.payload;
      if(location) {
        const zones = location.zones.slice();

        zones.forEach(zone => {
          const growingLocationZoneId = zone.id,
            isAvailable = zone.isAvailableByDefault,
            squareFeet = isAvailable ? zone.squareFeet : 0,
            weeks = zone.weeks.slice(),
            weekIndex = weeks.findIndex(w => w.weekId === weekId);

          if(weekIndex === -1) {
            const id = zone.weeks.reduce((min, zone) => Math.min(min, zone.id), 0) - 1,
              week = { id, growingLocationZoneId, weekId, squareFeet, isAvailable };
            weeks.push(week);            
          } else {
            const week = { ...weeks[weekIndex], squareFeet, isAvailable };
            weeks.splice(weekIndex, 1, week);
          }
          zone.weeks = weeks;

          const zoneMap = {...state.zones};
          const growingLocationWeek = zoneMap[weekId];          
          zoneMap[weekId] = setWeekGrowingLocationSquareFeet(growingLocationWeek, location, zone, squareFeet);
          zoneMap[weekId] = setWeekGrowingLocationIsAvailable(zoneMap[weekId], location, zone, isAvailable);
          state.zones = zoneMap;
        });

        location.zones = zones;
        state.location = location;
      }
    },
    copyWeekForward(state, action: PayloadAction<CopyWeekForwardArgs>) {
      const {location, weeks} = state,
        {fromWeekId, toWeekId, zoneWeeks} = action.payload;

      if(location) {
        let nextId = 0;
        const zones = location.zones.slice(),
          zoneMap = {...state.zones};
        zoneWeeks.forEach((zoneWeek) => {
          const {growingLocationZoneId, isAvailable, squareFeet} = zoneWeek,
            zoneIndex = zones.findIndex(z => z.id === growingLocationZoneId);
          if(zoneIndex !== -1) {
            const zone = {...zones[zoneIndex]},
              weeksForZone = weeks.reduce((memo, zw) => {
                const thisWeek = zone.weeks.find(w => w.weekId === zw.id),
                  existing = zone.weeks.find(w => w.weekId === fromWeekId);

                if((zw.id <= fromWeekId || zw.id > toWeekId) && thisWeek) {
                  memo.push(thisWeek);
                } else if(zw.id > fromWeekId && zw.id <= toWeekId) {
                  memo.push({...existing, id: nextId--, growingLocationZoneId, weekId: zw.id, isAvailable, squareFeet});
                }

                return memo;
              }, [] as models.GrowingLocationZoneWeek[]);
            zone.weeks = weeksForZone;
            zones.splice(zoneIndex, 1, zone);
            
            zone.weeks.filter(w => w.weekId > fromWeekId && w.weekId <= toWeekId).forEach(w => {              
              let growingLocationWeek = zoneMap[w.weekId];
              if(!growingLocationWeek) {
                const week = weeks.find(week => week.id === w.weekId);
                if(week) {
                  const model = toGrowingLocationWeek(location, week);
                  growingLocationWeek = model;
                }
              }
              growingLocationWeek = setWeekGrowingLocationSquareFeet(growingLocationWeek, location, zone, squareFeet);
              growingLocationWeek = setWeekGrowingLocationIsAvailable(growingLocationWeek, location, zone, isAvailable);
              zoneMap[w.weekId] = growingLocationWeek;
            });
          }
        });
        location.zones = zones;
        state.location = location;
        state.zones = zoneMap;
      }
    },
    addGrowingLocationWeekWishListProductSize(state, action: PayloadAction<AddGrowingLocationWeekWishListProductSizeArgs>) {
      const {growingLocationWeekProductSizes, location} = state;
      if(location) {
        const id = growingLocationWeekProductSizes.reduce((min, ps) => Math.min(min, ps.id), 0) - 1,
        wishListProductPriority = growingLocationWeekProductSizes.reduce((max, ps) => Math.max(max, ps.wishListProductPriority), 0) + 1,
          value = {...action.payload, id, growingLocationId: location.id, allowTight: true, allowSpaced: true, wishListProductPriority};

        if(!growingLocationWeekProductSizes.some(ps => ps.wishListProductId === value.wishListProductId && ps.sizeId === value.sizeId && ps.weekId === value.weekId)) {
          growingLocationWeekProductSizes.push(value);
          state.growingLocationWeekProductSizes = growingLocationWeekProductSizes;
        }
      }
    },
    deleteGrowingLocationWeekWishListProductSize(state, action: PayloadAction<number>) {
      const {growingLocationWeekProductSizes} = state,
        index = growingLocationWeekProductSizes.findIndex(ps => ps.id === action.payload);

      if(index !== -1) {
        growingLocationWeekProductSizes.splice(index, 1);
        state.growingLocationWeekProductSizes = growingLocationWeekProductSizes;
      }
    },
    copyGrowingLocationWeekWishListProductSizeForward(state, action: PayloadAction<CopyGrowingLocationWeekWishListProductSizeArgs>) {
      const {id, toWeekId} = action.payload,
        {growingLocationWeekProductSizes, location, weeks} = state,
        index = growingLocationWeekProductSizes.findIndex(ps => ps.id === id);

      if(index !== -1) {
        const growingLocationWeekProductSize = growingLocationWeekProductSizes[index],
          {weekId: fromWeekId} = growingLocationWeekProductSize,
          weeklyItem = growingLocationWeekProductSizes
            .find(ps => ps.weekId === fromWeekId && ps.wishListProductId === growingLocationWeekProductSize.wishListProductId && ps.sizeId === growingLocationWeekProductSize.sizeId),
          items = growingLocationWeekProductSizes.filter(ps => ps.weekId <= fromWeekId || ps.weekId > toWeekId);
        let id = growingLocationWeekProductSizes.reduce((min, ps) => Math.min(min, ps.id), 0) - 1;

        if(location && weeklyItem) {
          const futureWeeks = weeks.filter(w => w.id > fromWeekId && w.id <= toWeekId),
            futureItems = futureWeeks.reduce((memo, w) => {
              const existing = growingLocationWeekProductSizes.filter(i => i.weekId === w.id),
                index = existing.findIndex(i => i.wishListProductId === weeklyItem.wishListProductId && i.sizeId === weeklyItem.sizeId);

              if(index === -1) {
                existing.push({...weeklyItem, id: id--, weekId: w.id});
              } else {
                existing[index].allowSpaced = weeklyItem.allowSpaced;
                existing[index].allowTight = weeklyItem.allowTight;
              }

              return memo.concat(existing);

            }, [] as models.GrowingLocationWeekWishListProductSize[]),
          allItems = items.concat(futureItems);

          state.growingLocationWeekProductSizes = allItems;
        }
      }
    },
    editGrowingLocationWeekWishListProductSize(state, action: PayloadAction<EditGrowingLocationWeekWishListProductSizeArgs>) {
      const {growingLocationWeekProductSizes} = state,
        {id, allowTight, allowSpaced} = action.payload,
        index = growingLocationWeekProductSizes.findIndex(ps => ps.id === id);

      if(index !== -1) {
        const edit = {...growingLocationWeekProductSizes[index], allowTight, allowSpaced}
        growingLocationWeekProductSizes.splice(index, 1, edit);
        state.growingLocationWeekProductSizes = growingLocationWeekProductSizes;
      }
    },
    copyGrowingLocationWeekWishListAvailableProductsForward(state, action: PayloadAction<CopyGrowingLocationWeekWishListAvailableProductsForwardArgs>) {
      const {fromWeekId, toWeekId} = action.payload,
        {growingLocationWeekProductSizes, location, weeks} = state,
        weeklyItems = growingLocationWeekProductSizes.filter(ps => ps.weekId === fromWeekId),
        items = growingLocationWeekProductSizes.filter(ps => ps.weekId <= fromWeekId || ps.weekId > toWeekId);
      let id = growingLocationWeekProductSizes.reduce((min, ps) => Math.min(min, ps.id), 0) - 1;

      if(location) {
        const futureWeeks = weeks.filter(w => w.id > fromWeekId && w.id <= toWeekId),
          futureItems = futureWeeks.reduce((memo, w) => {
            const weekItems = weeklyItems.map(i => 
              ({...i, id: id--, weekId: w.id})
            );
            return memo.concat(weekItems);
            }, [] as models.GrowingLocationWeekWishListProductSize[]),
        allItems = items.concat(futureItems);

        state.growingLocationWeekProductSizes = allItems;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(saveGrowingLocationWeeksFulfilled, (state, action) => {
        state.loading = false;
        const {location} = action.payload;
        state.location = location;
        state.zones = toGrowingLocationWeeks(location, state.weeks);
      })
      .addCase(getLocationPending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLocationFulfilled, (state, action) => {
        const {location, growingLocationWeekProductSizes, weeks} = action.payload;
        state.loading = false;
        state.location = location;
        state.growingLocationWeekProductSizes = growingLocationWeekProductSizes;
        state.weeks = weeks;

        if(location) {
          state.zones = toGrowingLocationWeeks(location, state.weeks);
        } else {
          state.zones = {};
        }
      })
      .addCase(getLocationRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(saveGrowingLocationFulfilled, (state, action) => {
        if(action.payload) {
          const {location} = action.payload;
          state.location = location;
          state.zones = toGrowingLocationWeeks(location, state.weeks);
        }
      })
      .addCase(updatePrioritiesRejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updatePrioritiesFulfilled, (state, action) => {
        state.growingLocationWeekProductSizes = action.payload.growingLocationWeekProductSizes;
      })
  }
});

export const { setError, clearState, resetWeekToDefaults, copyWeekForward,
  setZoneWeekSquareFeet, setZoneWeekIsAvailable,
  addGrowingLocationWeekWishListProductSize, deleteGrowingLocationWeekWishListProductSize,
  editGrowingLocationWeekWishListProductSize, copyGrowingLocationWeekWishListAvailableProductsForward,
  copyGrowingLocationWeekWishListProductSizeForward } = growingLocationDetailSlice.actions;

export const selectLocation = (state: RootState) => state.growingLocationDetail.location;
export const selectGrowingLocationWeekProductSizes = (state: RootState) => state.growingLocationDetail.growingLocationWeekProductSizes;
export const selectError = (state: RootState) => state.growingLocationDetail.error;
export const selectLoading = (state: RootState) => state.growingLocationDetail.loading;
export const selectZonesByWeek = (state: RootState) => state.growingLocationDetail.zones;

export const selectWeeks = createSelector(
  selectYear,
  selectGrowingLocationWeekProductSizes,
  (state: RootState) => state.growingLocationDetail.weeks,
  (state: RootState) => state.growingLocationList.wishListProducts || [],
  (year, growingLocationWeekProductSizes, allWeeks, products) => {
      const inUse = growingLocationWeekProductSizes.reduce((memo, ps) => {
          if(!memo.find(a => a.wishListProductId === ps.wishListProductId && a.sizeId === ps.sizeId)) {
            memo.push(ps);
          }
          return memo;
        }, [] as models.GrowingLocationWeekWishListProductSize[]),
        firstWeek = allWeeks.find(w => w.year === year && w.weekNumber === 1)?.id || 0,
        lastWeek = allWeeks.filter(w => w.year === year).reduce((max, w) => Math.max(max, w.id), 0),
        minMax = products
          .reduce((memo, product) => {
            return memo.concat(product.sizes.filter(s => {
              return inUse.some(iu => iu.wishListProductId === product.id && s.sizeId === iu.sizeId);
            }));
          }, [] as models.WishListProductSize[])
          .reduce((memo, productSize) => {
            memo[0] = Math.min(memo[0], productSize.plantWeekId);
            memo[1] = Math.max(memo[1], productSize.salesEndWeekId);
          return memo;
        }, [firstWeek, lastWeek]),
      weeks = allWeeks
        .filter(w => w.id >= minMax[0] && w.id <= minMax[1])
        .sort(sortBy('id'));

    return weeks;
  }
);

export interface GrowingLocationWishListProductSize {
  key: string;
  wishListProductId: number;
  sizeId: number;
  productName: string;
  sizeName: string;
}

function toGrowingLocationWeeks(location: models.GrowingLocation, weeks: models.Week[]): GrowingLocationWeekMap {
  return weeks.sort(sortBy('weekNumber')).reduce((weekMap, week) => {
    const weekId = week.id,
      model = toGrowingLocationWeek(location, week);
    weekMap[weekId] = model;
    return weekMap;
  }, {} as GrowingLocationWeekMap);
}

function toGrowingLocationWeek(location: models.GrowingLocation, week: models.Week): GrowingLocationWeek {
  const weekId = week.id,
      weekNumber = week.weekNumber,
      revision = 0,
      totalSquareFeet = 0,
      zones = location.zones.reduce((zoneMap, zone) => {
        const zoneWeek = zone.weeks.find(z => z.growingLocationZoneId === zone.id && z.weekId === weekId),
          id = zoneWeek?.id || zone.weeks.reduce((min, w) => Math.min(min, w.id), 0) - 1,
          zoneName = zone.name,
          growingLocationZoneId = zone.id,
          revision = 0,
          uniqueId = `${weekId}.${growingLocationZoneId}.${revision}`,
          squareFeet = zoneWeek ? zoneWeek.squareFeet : 0,
          zoneNotes = zone.notes,
          isAvailable = zoneWeek ? zoneWeek.isAvailable : false,
          model = {id, zoneName, growingLocationZoneId, weekId, revision, uniqueId, isAvailable, squareFeet, zoneNotes};

        zoneMap.push(model);
      return zoneMap;
    }, [] as GrowingLocationZoneWeek[]);

    return {weekId, weekNumber, revision, uniqueId: `${location.id}.${weekId}.${revision}`, zones, totalSquareFeet};
}

function setWeekGrowingLocationSquareFeet(growingLocationWeek: GrowingLocationWeek, location: models.GrowingLocation, zone: models.GrowingLocationZone, squareFeet: number): GrowingLocationWeek {
  const revision = growingLocationWeek.revision + 1,
    uniqueId = `${location.id}.${growingLocationWeek.weekId}.${revision}`,
    zones = growingLocationWeek.zones.slice(),
    index = zones.findIndex(z => z.growingLocationZoneId === zone.id);
  if(index !== -1) {
    const zoneWeek = growingLocationWeek.zones[index],
      revision = zoneWeek.revision + 1,
      uniqueId = `${growingLocationWeek.weekId}.${zone.id}.${revision}`,
      model = {...zoneWeek, squareFeet, revision, uniqueId};

    zones.splice(index, 1, model);
  }

  return {...growingLocationWeek, zones, revision, uniqueId};
}

function setWeekGrowingLocationIsAvailable(growingLocationWeek: GrowingLocationWeek, location: models.GrowingLocation, zone: models.GrowingLocationZone, isAvailable: boolean): GrowingLocationWeek {
  const revision = growingLocationWeek.revision + 1,
    uniqueId = `${location.id}.${growingLocationWeek.weekId}.${revision}`,
    zones = growingLocationWeek.zones.slice(),
    index = zones.findIndex(z => z.growingLocationZoneId === zone.id);
  if(index !== -1) {
    const zoneWeek = growingLocationWeek.zones[index],
      revision = zoneWeek.revision + 1,
      uniqueId = `${growingLocationWeek.weekId}.${zone.id}.${revision}`,
      model = {...zoneWeek, isAvailable, revision, uniqueId};

    zones.splice(index, 1, model);
  }

  return {...growingLocationWeek, zones, revision, uniqueId};
}

function findMinMaxWeeks(year: number, growingLocationWeekProductSizes: models.GrowingLocationWeekWishListProductSize[], products: models.WishListProduct[], allWeeks: Week[]) {
  const inUse = growingLocationWeekProductSizes.reduce((memo, ps) => {
    if(!memo.find(a => a.wishListProductId === ps.wishListProductId && a.sizeId === ps.sizeId)) {
      memo.push(ps);
    }
    return memo;
  }, [] as models.GrowingLocationWeekWishListProductSize[]),
  firstWeek = allWeeks.find(w => w.year === year && w.weekNumber === 1)?.id || 0,
  lastWeek = allWeeks.filter(w => w.year === year).reduce((max, w) => Math.max(max, w.id), 0),
  minMax = products
    .reduce((memo, product) => {
      return memo.concat(product.sizes.filter(s => {
        return inUse.some(iu => iu.wishListProductId === product.id && s.sizeId === iu.sizeId);
      }));
    }, [] as models.WishListProductSize[])
    .reduce((memo, productSize) => {
      memo[0] = Math.min(memo[0], productSize.plantWeekId);
      memo[1] = Math.max(memo[1], productSize.salesEndWeekId);
    return memo;
  }, [firstWeek, lastWeek]);

  return minMax;
}

export default growingLocationDetailSlice.reducer;
