import { createSlice, PayloadAction, createAsyncThunk, AsyncThunk, createSelector, createAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from 'app/store';
import { billingApi, OrderDetailResponse, OrderDeleteResponse, OrderUpdateResponse, OrderDuplicateResponse, OrderCreateResponse, NewOrderDetailResponse, EmailInvoicesReponse } from 'api/billing-service';
import { emailInvoices } from './email-invoices-slice';
import * as models from 'api/models/billing';
import { ProblemDetails } from 'utils/problem-details';
import { contains } from 'utils/equals';

export const DefaultFreightCharge = 50;
export const DefaultFreightGlCode = '42800000';
const DefaultSalesTaxId = 'HST';
const DefaultSalesTaxSimplyCode = 'H0';
const DefaultPaymentTerms = 'Net 30 Days';
const DefaultBrokerId = 2;

export interface OrderDetailState {
  order: models.BillingOrderDetail | null;
  customers: models.BillingCustomer[];
  brokers: models.BillingBroker[];
  salesTaxes: models.BillingSalesTax[];
  generalLedgerAccounts: models.BillingGeneralLedgerAccount[];
  deliveryTypes: models.BillingDeliveryType[];
  varieties: models.BillingVariety[];
  colours: models.BillingColour[];
  weeks: models.BillingWeek[];
  paymentTerms: models.PaymentTerm[];
  signoffs: models.SignoffInfo;
  emailAddress: string | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: OrderDetailState = {
  order: null,
  brokers: [],
  customers: [],
  salesTaxes: [],
  generalLedgerAccounts: [],
  deliveryTypes: [],
  varieties: [],
  colours: [],
  weeks: [],
  paymentTerms: [],
  signoffs: {
    hasPropagationSchedule: false,
    propagationManagerSignoffText: null,
    officeAdminSignoffText: null
  },
  emailAddress: null,
  loading: false,
  error: null
};

export const getOrderDetail: AsyncThunk<OrderDetailResponse, number, {state: RootState}> = createAsyncThunk(
  'billing/getOrderDetail',
  async (id: number, {rejectWithValue}) => {
    try {
      return await billingApi.getOrderDetail(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const getNewOrderDetail: AsyncThunk<NewOrderDetailResponse, void, {state: RootState}> = createAsyncThunk(
  'billing/getNewOrderDetail',
  async (_, {rejectWithValue}) => {
    try {
      return await billingApi.getNewOrderDetail();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const createOrder: AsyncThunk<OrderCreateResponse, models.BillingOrderDetail, {state: RootState}> = createAsyncThunk(
  'billing/createOrder',
  async (order: models.BillingOrderDetail, {rejectWithValue}) => {
    try {
      return await billingApi.createOrder(order);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateOrder: AsyncThunk<OrderUpdateResponse, models.BillingOrderDetail, {state: RootState}> = createAsyncThunk(
  'billing/updateOrder',
  async (order: models.BillingOrderDetail, {rejectWithValue}) => {
    try {
      return await billingApi.updateOrder(order);
    } catch(e) {
      debugger;
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteOrder: AsyncThunk<OrderDeleteResponse, number, {state: RootState}> = createAsyncThunk(
  'billing/deleteOrder',
  async (id: number, {rejectWithValue}) => {
    try {
      return await billingApi.deleteOrder(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const duplicateOrder: AsyncThunk<OrderDuplicateResponse, number, {state: RootState}> = createAsyncThunk(
  'billing/duplicateOrder',
  async (id: number, {rejectWithValue}) => {
    try {
      return await billingApi.duplicateOrder(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const commitOrder: AsyncThunk<models.BillingOrderDetail | null, number, {state: RootState}> = createAsyncThunk(
  'billing/commitOrder',
  async (id: number, {getState, rejectWithValue}) => {
    try {
      const order = getState().orderDetail.order,
       committed = !!order?.committedDate;
      if(order) {
        return await billingApi.commitOrder(id, order, !committed);
      }

      return null;
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const postOrder: AsyncThunk<models.BillingOrderDetail | null, number, {state: RootState}> = createAsyncThunk(
  'billing/postOrder',
  async (id: number, {getState, rejectWithValue}) => {
    try {
      const order = getState().orderDetail.order,
        posted = !!order?.postedDate;
      
      if(order) {
        return await billingApi.postOrder(id, order, !posted);
      }

      return null;
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export interface SetItemValueArgs<T> {
  itemId: number;
  value: T;
}

const getOrderDetailPending = createAction(getOrderDetail.pending.type),
  getOrderDetailFulfilled = createAction<OrderDetailResponse>(getOrderDetail.fulfilled.type),
  getOrderDetailRejected = createAction<ProblemDetails>(getOrderDetail.rejected.type),
  getNewOrderDetailPending = createAction(getNewOrderDetail.pending.type),
  getNewOrderDetailFulfilled = createAction<NewOrderDetailResponse>(getNewOrderDetail.fulfilled.type),
  getNewOrderDetailRejected = createAction<ProblemDetails>(getNewOrderDetail.rejected.type),
  createOrderPending = createAction(createOrder.pending.type),
  createOrderFulfilled = createAction(createOrder.fulfilled.type),
  createOrderRejected = createAction<ProblemDetails>(createOrder.rejected.type),
  deleteOrderPending = createAction(deleteOrder.pending.type),
  deleteOrderFulfilled = createAction(deleteOrder.fulfilled.type),
  deleteOrderRejected = createAction<ProblemDetails>(deleteOrder.rejected.type),
  updateOrderPending = createAction(updateOrder.pending.type),
  updateOrderFulfilled = createAction<OrderUpdateResponse>(updateOrder.fulfilled.type),
  updateOrderRejected = createAction<ProblemDetails>(updateOrder.rejected.type),
  duplicateOrderPending = createAction(duplicateOrder.pending.type),
  duplicateOrderFulfilled = createAction(duplicateOrder.fulfilled.type),
  duplicateOrderRejected = createAction<ProblemDetails>(duplicateOrder.rejected.type),
  commitOrderPending = createAction(commitOrder.pending.type),
  commitOrderFulfilled = createAction<models.BillingOrderDetail | null>(commitOrder.fulfilled.type),
  commitOrderRejected = createAction<ProblemDetails>(commitOrder.rejected.type),
  postOrderPending = createAction(postOrder.pending.type),
  postOrderFulfilled = createAction<models.BillingOrderDetail | null>(postOrder.fulfilled.type),
  postOrderRejected = createAction<ProblemDetails>(postOrder.rejected.type),
  emailInvoicesFulfilled = createAction<EmailInvoicesReponse>(emailInvoices.fulfilled.type);

export const orderDetailSlice = createSlice({
  name: 'order-detail',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    setBroker(state, action: PayloadAction<number>) {
      const brokerId = action.payload,
        broker = state.brokers.find(b => b.id === brokerId),
        {order, salesTaxes} = state;

      if(broker && order) {
        order.brokerId = brokerId;
        
        order.billingCompanyName = broker.billingName || broker.name;
        order.billingAddress = broker.address;
        order.billingCity = broker.city;
        order.billingProvince = broker.province;
        order.billingPostalCode = broker.postalCode;
        order.billingCountry = broker.country;
        order.billingEmailAddress = broker.emailAddress;
        order.confirmationEmailAddress = broker.confirmationEmailAddress;

        order.salesTaxId = broker.salesTaxId;
        const salesTax = salesTaxes.find(t => t.taxId === broker.salesTaxId);
        if(salesTax) {
          order.salesTaxId = salesTax.taxId;
          order.salesTaxRate = salesTax.taxRate;
          order.salesTaxSimplyCode = salesTax.simplyCode;
        }
      }
    },
    setBillingCompanyName(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.billingCompanyName = action.payload;
      }
    },
    setBillingAddress(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.billingAddress = action.payload;
      }
    },
    setBillingCity(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.billingCity = action.payload;
      }
    },
    setBillingProvince(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.billingProvince = action.payload;
      }
    },
    setBillingPostalCode(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.billingPostalCode = action.payload;
      }
    },
    setBillingCountry(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.billingCountry = action.payload;
      }
    },
    setBillingEmailAddress(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.billingEmailAddress = action.payload;
      }
    },
    setConfirmationEmailAddress(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.confirmationEmailAddress = action.payload;
      }
    },
    setCustomer(state, action: PayloadAction<number>) {
      const customerId = action.payload,
        customer = state.customers.find(c => c.id === customerId),
        {order} = state;

      if(order && customer) {
        order.customerId = customerId;
        order.shippingCompanyName = customer.billingName || customer.name;
        order.shippingAddress = customer.address;
        order.shippingCity = customer.city;
        order.shippingProvince = customer.province;
        order.shippingPostalCode = customer.postalCode;
        order.shippingCountry = customer.country;
        order.deliveryDay = customer.defaultDeliveryDay;
        order.deliveryTypeId = customer.defaultDeliveryTypeId;
      }
    },
    setShippingCompanyName(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.shippingCompanyName = action.payload;
      }
    },
    setShippingAddress(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.shippingAddress = action.payload;
      }
    },
    setShippingCity(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.shippingCity = action.payload;
      }
    },
    setShippingProvince(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.shippingProvince = action.payload;
      }
    },
    setShippingPostalCode(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.shippingPostalCode = action.payload;
      }
    },
    setShippingCountry(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.shippingCountry = action.payload;
      }
    },
    setPoNumber(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.poNumber = action.payload;
      }
    },
    setPaymentTerms(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.paymentTerms = action.payload;
      }
    },
    setPlantWeek(state, action: PayloadAction<number | null>) {
      if(state.order) {
        state.order.plantWeekId = action.payload;
      }
    },
    setDeliveryDay(state, action: PayloadAction<string | null>) {
      if(state.order) {
        state.order.deliveryDay = action.payload;
      }
    },
    setDeliveryType(state, action: PayloadAction<models.BillingDeliveryType>) {
      if(state.order) {
        state.order.deliveryTypeId = action.payload.id;
        if(contains(action.payload.display, 'delivery')) {
          state.order.freightCharge = DefaultFreightCharge;
          state.order.freightGLCode = DefaultFreightGlCode;
        } else {
          state.order.freightCharge = 0;
        }
      }
    },
    setDeliveryDate(state, action: PayloadAction<string | null>) {
      if(state.order) {
        const date = action.payload,
          {weeks, order} = state,
          m = date && moment(date),
          deliveryDay = (m && m.format('dddd')) || '',
          plantWeek = weeks.find(w => moment(w.startDate).isSame(m, 'isoWeek'))?.id || null;
        
        order.deliveryDay = deliveryDay;
        order.plantWeekId = plantWeek;
      }
    },
    setOrderNumber(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.orderNumber = action.payload;
      }
    },
    setComments(state, action: PayloadAction<string | null>) {
      if(state.order) {
        state.order.comments = action.payload;
      }
    },
    removeItem(state, action: PayloadAction<number>) {
      if(state.order) {
        const index = state.order?.items?.findIndex(i => i.id === action.payload);
        if(index !== -1) {
          state.order?.items.splice(index, 1);
        }
      }
    },
    setItemColourId(state, action: PayloadAction<SetItemValueArgs<number>>) {
      const {itemId, value} = action.payload,
        {order, colours, brokers} = state;

      if(order) {
        const item = order.items?.find(i => i.id === itemId),
          colour = colours.find(c => c.id === value);

        if(item) {
          item.colourId = value;
          if(colour) {
            item.glCode = colour.glCode;
            item.isTaxable = colour.isTaxable;

            const broker = brokers.find(b => b.id === order.brokerId);
            if(broker) {
              const price = broker.brokerColours.find(bc => bc.colourId === value)?.price || 0;
              item.price = price;
            }
          }
        }
      }
    },
    setItemLotNumber(state, action: PayloadAction<SetItemValueArgs<string>>) {
      const {itemId, value} = action.payload,
        {order } = state,
        item = order?.items?.find(i => i.id === itemId);

      if(item) {
        item.lotNumber = value;
      }
    },
    setItemOrderQuantity(state, action: PayloadAction<SetItemValueArgs<number>>) {
      const {itemId, value} = action.payload,
        {order } = state,
        item = order?.items?.find(i => i.id === itemId);

      if(item) {
        item.orderQuantity = value;
        item.shipQuantity = value;
      }
    },
    setItemShipQuantity(state, action: PayloadAction<SetItemValueArgs<number | null>>) {
      const {itemId, value} = action.payload,
        {order } = state,
        item = order?.items?.find(i => i.id === itemId);

      if(item) {
        item.shipQuantity = value;
      }
    },
    setItemPrice(state, action: PayloadAction<SetItemValueArgs<number>>) {
      const {itemId, value} = action.payload,
        {order } = state,
        item = order?.items?.find(i => i.id === itemId);

      if(item) {
        item.price = value;
      }
    },
    setItemIsTaxable(state, action: PayloadAction<SetItemValueArgs<boolean>>) {
      const {itemId, value} = action.payload,
        {order } = state,
        item = order?.items?.find(i => i.id === itemId);

      if(item) {
        item.isTaxable = value;
      }
    },
    setItemGLCode(state, action: PayloadAction<SetItemValueArgs<string>>) {
      const {itemId, value} = action.payload,
        {order } = state,
        item = order?.items?.find(i => i.id === itemId);

      if(item) {
        item.glCode = value;
      }
    },
    setFreightCharge(state, action: PayloadAction<number>) {
      if(state.order) {
        state.order.freightCharge = action.payload;
        if(action.payload && !state.order.freightGLCode) {
          state.order.freightGLCode = DefaultFreightGlCode;
        }
      }
    },
    setIsFreightTaxable(state, action: PayloadAction<boolean>) {
      if(state.order) {
        state.order.isFreightTaxable = action.payload;
      }
    },
    setFreightGLCode(state, action: PayloadAction<string>) {
      if(state.order) {
        state.order.freightGLCode = action.payload;
      }
    },
    addItem(state) {
      if(state.order) {
        const id = state.order.items.reduce((min, item) => Math.min(min, item.id), 0) - 1;
        state.order.items.push({
          id,
          colourId: 0,
          lotNumber: '',
          orderQuantity: 0,
          shipQuantity: null,
          price: 0,
          glCode: '',
          isTaxable: true
        });
      }
    },
    setSalesTaxId(state, action: PayloadAction<string>) {
      const {order, salesTaxes} = state;
      if(order) {
        const tax = salesTaxes.find(t => t.taxId === action.payload);
        if(tax) {
          order.salesTaxId = tax.taxId;
          order.salesTaxRate = tax.taxRate;
          order.salesTaxSimplyCode = tax.simplyCode;
        }
      }
    }
  },
  extraReducers: builder => 
    builder
    .addCase(getOrderDetailPending, state => {
      state.order = null;
      state.error = null;
      state.loading = true;
    })
    .addCase(getOrderDetailFulfilled, (state, action: PayloadAction<OrderDetailResponse>) => {
      state.error = null;
      const {order, brokers, customers, salesTaxes, generalLedgerAccounts, deliveryTypes, varieties, colours, weeks, paymentTerms, signoffs, emailAddress} = action.payload;
      state.order = order;
      state.brokers = brokers;
      state.customers = customers;
      state.salesTaxes = salesTaxes;
      state.generalLedgerAccounts = generalLedgerAccounts;
      state.deliveryTypes = deliveryTypes;
      state.varieties = varieties;
      state.colours = colours;
      state.weeks = weeks;
      state.paymentTerms = paymentTerms;
      state.signoffs = signoffs;
      state.emailAddress = emailAddress;
      state.loading = false;
    })
    .addCase(getOrderDetailRejected, (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.order = null;
      state.loading = false;
    })
    .addCase(getNewOrderDetailPending, state => {
      state.order = null;
      state.error = null;
      state.loading = true;
    })
    .addCase(getNewOrderDetailFulfilled, (state, action: PayloadAction<NewOrderDetailResponse>) => {
      state.error = null;
      const {brokers, customers, salesTaxes, generalLedgerAccounts, deliveryTypes, varieties, colours, weeks, paymentTerms, emailAddress} = action.payload,
        tax = salesTaxes.find(t => t.taxId === DefaultSalesTaxId),
        broker = brokers.find(b => b.id === DefaultBrokerId),
        order = {
          id: 0,
          customerId: 0,
          poNumber: '',
          deliveryTypeId: 0,
          deliveryDay: null,
          deliveryNotes: '',
          plantWeekId: 0,
          brokerId: broker?.id || 0,
          orderNumber: '',
          committedDate: null,
          committedBy: null,
          postedDate: null,
          postedBy: null,
          exportedDate: null,
          exportedBy: null,
          billingCompanyName: broker?.billingName || broker?.name || null,
          billingAddress: broker?.address || null,
          billingCity: broker?.city || null,
          billingProvince: broker?.province || null,
          billingPostalCode: broker?.postalCode || null,
          billingCountry: broker?.country || null,
          billingEmailAddress: broker?.emailAddress || null,
          confirmationEmailAddress: broker?.confirmationEmailAddress || null,
          shippingCompanyName: null,
          shippingAddress: null,
          shippingCity: null,
          shippingProvince: null,
          shippingPostalCode: null,
          shippingCountry: null,
          paymentTerms: DefaultPaymentTerms,
          salesTaxId: DefaultSalesTaxId,
          salesTaxRate: tax?.taxRate || 0,
          salesTaxSimplyCode: tax?.simplyCode || DefaultSalesTaxSimplyCode,
          freightCharge: 0,
          isFreightTaxable: true,
          freightGLCode: DefaultFreightGlCode,
          comments: null,
          items: [],
          invoiceEmails: [],
          confirmationEmails: []
      };
      state.order = order;
      state.brokers = brokers;
      state.customers = customers;
      state.salesTaxes = salesTaxes;
      state.generalLedgerAccounts = generalLedgerAccounts;
      state.deliveryTypes = deliveryTypes;
      state.varieties = varieties;
      state.colours = colours;
      state.weeks = weeks;
      state.paymentTerms = paymentTerms;
      state.emailAddress = emailAddress;
      state.loading = false;
    })
    .addCase(getNewOrderDetailRejected, (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.order = null;
      state.loading = false;
    })
    .addCase(createOrderPending, state => {
      state.error = null;
      state.loading = true;
    })
    .addCase(createOrderFulfilled, state => {
      state.error = null;
      state.loading = false;
    })
    .addCase(createOrderRejected, (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(deleteOrderPending, state => {
      state.error = null;
      state.loading = true;
    })
    .addCase(deleteOrderFulfilled, state => {
      state.error = null;
      state.order = null;
      state.loading = false;
    })
    .addCase(deleteOrderRejected, (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(updateOrderPending, state => {
      state.error = null;
      state.loading = true;
    })
    .addCase(updateOrderFulfilled, (state, action: PayloadAction<OrderUpdateResponse>) => {
      state.error = null;
      state.order = action.payload.order;
      state.loading = false;
    })
    .addCase(updateOrderRejected, (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(duplicateOrderPending, state => {
      state.error = null;
      state.loading = true;
    })
    .addCase(duplicateOrderFulfilled, state => {
      state.error = null;
      state.order = null;
      state.loading = false;
    })
    .addCase(duplicateOrderRejected, (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(commitOrderPending, state => {
      state.error = null;
      state.loading = true;
    })
    .addCase(commitOrderFulfilled, (state, action: PayloadAction<models.BillingOrderDetail | null>) => {
      state.error = null;
      if(state.order && action.payload) {
        state.order.committedBy = action.payload.committedBy;
        state.order.committedDate = action.payload.committedDate;
      }
      state.loading = false;
    })
    .addCase(commitOrderRejected, (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(postOrderPending, state => {
      state.error = null;
      state.loading = true;
    })
    .addCase(postOrderFulfilled, (state, action: PayloadAction<models.BillingOrderDetail | null>) => {
      state.error = null;
      if(state.order && action.payload) {
        state.order.postedBy = action.payload.postedBy;
        state.order.postedDate = action.payload.postedDate;
      }
      state.loading = false;
    })
    .addCase(postOrderRejected, (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(emailInvoicesFulfilled, (state, action: PayloadAction<EmailInvoicesReponse>) => {
      if(state.order) {
        const order = state.order,
          email = action.payload.email,
          invoiceEmails = order.invoiceEmails.slice();
        
        invoiceEmails.push(email);

        order.invoiceEmails = invoiceEmails;
        state.order = order;
      }
    })
});

export const { setBroker, setBillingCompanyName, setBillingAddress, setBillingCity, setBillingCountry, setBillingEmailAddress, setConfirmationEmailAddress, setBillingPostalCode, setBillingProvince,
  setCustomer, setShippingCompanyName, setShippingAddress, setShippingCity, setShippingCountry, setShippingPostalCode, setShippingProvince,
  setPlantWeek, setDeliveryDay, setDeliveryType, setDeliveryDate, setSalesTaxId, setPoNumber, setPaymentTerms,
  setItemColourId, setItemLotNumber, setItemOrderQuantity, setItemShipQuantity, setItemPrice, setItemIsTaxable, setItemGLCode, removeItem, addItem,
  setFreightCharge, setIsFreightTaxable, setFreightGLCode, setOrderNumber, setComments, setError, clearError } = orderDetailSlice.actions;

export const selectError = (state: RootState) => state.orderDetail.error;
export const selectLoading = (state: RootState) => state.orderDetail.loading;
export const selectSignoffs = (state: RootState) => state.orderDetail.signoffs;
export const selectEmailAddress = (state: RootState) => state.orderDetail.emailAddress;

export const selectOrderDetail = createSelector(
  (state: RootState) => state.orderDetail,
  (state) => state
);

export const selectDeliveryDate = createSelector(
  (state: RootState) => {
    const plantWeekId = state.orderDetail.order?.plantWeekId,
      weeks = state.orderDetail.weeks,
      week = (plantWeekId && weeks.find(w => w.id === plantWeekId)) || null,
      date = week?.startDate || null;
    
    return date;
  },
  (state: RootState) => state.orderDetail.order?.deliveryDay || null,
  (date, day) => {
    const m = (date && moment(date)) || null;

    if(day) {
      m?.day(day);
    }

    return m?.format('YYYY-MM-DD') || '';
  }
);

export const selectSalesTax = createSelector(
  (state: RootState) => state.orderDetail.order?.salesTaxId || null,
  (state: RootState) => state.orderDetail.salesTaxes,
  (id, taxes) => taxes.find(t => id && t.taxId === id) || null
);

export const selectFreightCharge = createSelector(
  (state: RootState) => state.orderDetail.order,
  order => order?.freightCharge || 0
);

export const selectOrderItems = createSelector(
  (state: RootState) => state.orderDetail.order,
  order => order?.items || []
);

export const selectOrderItemTotal = createSelector(
  selectOrderItems,
  items => items.reduce((total, item) => total + item.price * (item.shipQuantity == null ? item.orderQuantity : item.shipQuantity), 0)
);

export const selectTaxableItems = createSelector(
  selectOrderItems,
  items => items.filter(i => i.isTaxable)
)

export const selectOrderSalesTaxAmount = createSelector(
  (state: RootState) => state.orderDetail.order?.salesTaxRate || 0,
  selectTaxableItems,
  (state: RootState) => state.orderDetail.order?.isFreightTaxable ? (state.orderDetail.order?.freightCharge || 0) : 0,
  (taxRate, items, freight) => (taxRate / 100) * (items.reduce((total, item) => total + item.price * (item.shipQuantity == null ? item.orderQuantity : item.shipQuantity), 0) + freight)
);

export const selectGrandTotal = createSelector(
  selectOrderItemTotal,
  selectOrderSalesTaxAmount,
  selectFreightCharge,
  (total, tax, freight) => total + tax + freight
);

export const selectPlugCount = createSelector(
  selectOrderItems,
  items => items.reduce((total, item) => total + (item.colourId > 0 ? (item.shipQuantity == null ? item.orderQuantity : item.shipQuantity) : 0), 0)
)

export default orderDetailSlice.reducer;
