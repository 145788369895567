import { createSlice, createAsyncThunk, createSelector, PayloadAction, AsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { adminApi } from 'api/admin-service';
import { AdminBroker } from 'api/models/admin';
import { ProblemDetails } from 'utils/problem-details';

export interface BrokerState {
  brokers: AdminBroker[] | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: BrokerState = {
  brokers: null,
  loading: false,
  error: null
};

export const getBrokers: AsyncThunk<AdminBroker[], void, {state: RootState}> = createAsyncThunk(
  'admin/getBrokers',
  async (_, {rejectWithValue}) => {
    try {
      return await adminApi.brokerList();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const createBroker: AsyncThunk<AdminBroker, AdminBroker, {state: RootState}> = createAsyncThunk(
  'admin/createBroker',
  async (broker, {rejectWithValue}) => {
    try {
      return await adminApi.brokerCreate(broker);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateBroker: AsyncThunk<AdminBroker, AdminBroker, {state: RootState}> = createAsyncThunk(
  'admin/updateBroker',
  async (broker, {rejectWithValue}) => {
    try {
      return await adminApi.brokerUpdate(broker);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteBroker: AsyncThunk<AdminBroker, number, {state: RootState}> = createAsyncThunk(
  'admin/deleteBroker',
  async (id, {rejectWithValue}) => {
    try {
      return await adminApi.brokerDelete(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const brokerSlice = createSlice({
  name: 'brokers',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    }
  },
  extraReducers: {
    [getBrokers.pending.type]: (state) => {
      state.brokers = [];
      state.error = null;
      state.loading = true;
    },
    [getBrokers.fulfilled.type]: (state, action: PayloadAction<AdminBroker[]>) => {
      state.brokers = action.payload;
      state.error = null;
      state.loading = false;
    },
    [getBrokers.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createBroker.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [createBroker.fulfilled.type]: (state, action: PayloadAction<AdminBroker>) => {
      state.error = null;
      state.loading = false;
      if(state.brokers) {
        state.brokers.push(action.payload);
      }
    },
    [createBroker.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateBroker.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [updateBroker.fulfilled.type]: (state, action: PayloadAction<AdminBroker>) => {
      state.error = null;
      state.loading = false;

      if(state.brokers) {
        const broker = action.payload,
          brokers = state.brokers.slice(),
          index = brokers.findIndex(c => c.id === broker.id);

        if(index !== -1) {
          brokers.splice(index, 1, broker);
        }

        state.brokers = brokers;
      }
    },
    [updateBroker.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteBroker.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [deleteBroker.fulfilled.type]: (state, action: PayloadAction<AdminBroker>) => {
      state.error = null;
      state.loading = false;

      if(state.brokers) {
        const broker = action.payload,
          brokers = state.brokers.slice(),
          index = brokers.findIndex(c => c.id === broker.id);

        if(index !== -1) {
          brokers.splice(index, 1);
        }

        state.brokers = brokers;
      }
    },
    [deleteBroker.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setError } = brokerSlice.actions;

export const selectBrokers = (state: RootState) => state.brokers.brokers;
export const selectLoading = (state: RootState) => state.brokers.loading;
export const selectError = (state: RootState) => state.brokers.error;

export const selectSortedBrokers = createSelector(
  selectBrokers,
  brokers => brokers?.slice().sort((a, b) => a.name.localeCompare(b.name)) || null
);

export default brokerSlice.reducer;
