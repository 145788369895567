import * as models from 'api/models/propagation';
import { sortBy } from 'utils/sort';

const sortByStartWeekId = sortBy('startWeekId');

export function updateAllWeeklyVarietySowPeriods(weeklyProduct: models.ProductDetailWeekly, sowPeriods: models.SowPeriod[], purchaseOrderGroups: models.PurchaseOrderGroup[]) {
  const product = {...weeklyProduct},
    varieties = product.varieties.map(v => ({...v}));

  varieties.forEach(variety => {
    variety.sowPeriods = updateWeeklyVarietySowPeriods(variety, sowPeriods, purchaseOrderGroups);
  });

  product.varieties = varieties;

  return product;
}

export function updateWeeklyVarietySowPeriods(variety: models.ProductDetailWeeklyVariety, sowPeriods: models.SowPeriod[], purchaseOrderGroups: models.PurchaseOrderGroup[]) {
  const varietySowPeriods = variety.sowPeriods.map(sp => ({...sp}));

  let endingInventory: number | null = null;

  sowPeriods
    .map(sp => ({...sp}))
    .sort(sortByStartWeekId)
    .forEach(sp => {
      const vsp = varietySowPeriods.find(s => s.wishListProductSowPeriodId === sp.id);
      if(vsp) {
        if(endingInventory != null) {
          vsp.beginningInventory = endingInventory;
        }
        const overage = vsp.overage,
          total = variety.quantities
            .filter(q => q.sowWeekId >= sp.startWeekId && q.sowWeekId <= sp.endWeekId)
            .reduce((total, q) => total + q.opgMix + q.specific + q.florist, 0) * overage,
          ordered = purchaseOrderGroups
            .filter(g => g.wishListProductSowPeriodId === sp.id)
            .reduce((groupTotal, g) =>
              groupTotal + g.purchaseOrders
                .reduce((poTotal, po) =>
                  poTotal + po.items
                    .filter(i => i.varietyId === variety.id)
                    .reduce((itemTotal, i) => itemTotal + i.quantityOrdered, 0)
                , 0)
            , 0);
        endingInventory = vsp.beginningInventory - total + ordered;
      }
    });

  return varietySowPeriods;
}

interface QuantityRequired {
  cuttings: number;
  withOverage: number;
}

class QuantityRequiredResult {
  opgMix: QuantityRequired = {cuttings: 0, withOverage: 0};
  florist: QuantityRequired = {cuttings: 0, withOverage: 0};
  specific: QuantityRequired = {cuttings: 0, withOverage: 0};
  overage: number;
  beginningInventory: number;
  wishListSizes: models.ProductDetailWeeklyWishListSize[];

  constructor(product: models.ProductDetailWeekly | null, varietySowPeriod?: models.ProductDetailWeeklyVarietySowPeriod | undefined) {
    this.wishListSizes = product?.wishListSizes || [];
    this.overage = varietySowPeriod?.overage || 1;
    this.beginningInventory = varietySowPeriod?.beginningInventory || 0;
  }
  
  get opg(): QuantityRequired {
    const cuttings = this.opgMix.cuttings + this.specific.cuttings,
      withOverage = this.opgMix.withOverage + this.specific.withOverage;

    return {cuttings, withOverage};
  }

  get totalCuttings() {
    return this.opgMix.cuttings + this.florist.cuttings + this.specific.cuttings;
  }

  get totalWithOverage() {
    return this.opgMix.withOverage + this.florist.withOverage + this.specific.withOverage;
  }

  addQuantity(quantity: models.ProductDetailWeeklyVarietyQuantity) {
    const {opgMix, specific, florist} = quantity,
      wishListProductSize = this.wishListSizes.find(s => s.sizeId === quantity.sizeId),
      plugsPerPot = wishListProductSize?.plugsPerPot || 1;
    this.opgMix.cuttings += (opgMix * plugsPerPot);
    this.opgMix.withOverage += (opgMix * this.overage * plugsPerPot);
    this.florist.cuttings += (florist * plugsPerPot);
    this.florist.withOverage += (florist * this.overage * plugsPerPot);
    this.specific.cuttings += (specific * plugsPerPot);
    this.specific.withOverage += (specific * this.overage * plugsPerPot);
  }

  addResult(result: QuantityRequiredResult) {
    this.opgMix.cuttings += result.opgMix.cuttings;
    this.opgMix.withOverage += result.opgMix.withOverage;
    this.florist.cuttings += result.florist.cuttings;
    this.florist.withOverage += result.florist.withOverage;
    this.specific.cuttings += result.specific.cuttings;
    this.specific.withOverage += result.specific.withOverage;
    return this;
  }
}

export function totalQuantityRequired(varieties: models.ProductDetailWeeklyVariety[], product: models.ProductDetailWeekly | null, size?: models.ProductDetailWeeklyWishListSize, sowPeriod?: models.SowPeriod | null) {
  const result = varieties.reduce((memo, variety) => {
    const required = quantityRequired(variety, product, size, sowPeriod);
    return memo.addResult(required);
  }, new QuantityRequiredResult(product));

  return result;
}

export function quantityRequired(variety: models.ProductDetailWeeklyVariety, product: models.ProductDetailWeekly | null, size?: models.ProductDetailWeeklyWishListSize, sowPeriod?: models.SowPeriod | null): QuantityRequiredResult {
  const varietySowPeriod = variety.sowPeriods.find(sp => sp.wishListProductSowPeriodId === sowPeriod?.id),
    result = new QuantityRequiredResult(product, varietySowPeriod);

  variety.quantities
    .filter(q => !size || q.sizeId === size.sizeId)
    .filter(q => !sowPeriod || (q.sowWeekId >= sowPeriod.startWeekId && q.sowWeekId <= sowPeriod.endWeekId))
    .forEach(result.addQuantity.bind(result));

  return result;
}
