import { createSlice, createSelector, AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import * as models from 'api/models/wish-list';
import { formatNumber } from 'utils/format';
import { sortBy } from 'utils/sort';
import { selectWeeks } from '../list/index-slice';
import { selectProduct, setProduct, sortSize, setDirty, setSpaceAllocated } from '../product-detail-slice';
import { selectYear } from '../wish-list-slice';
import { refreshWeeklyAllocations } from './weekly-wish-list';
import * as capacity from './weekly-capacity';

export interface WishListProductWeeklyState { }

const initialState: WishListProductWeeklyState = { };

interface SetSizeValueArgs<T> {
  sizeId: number;
  value: T;
}

interface SetProductSizeValueArgs<T> {
  productSizeId: number;
  value: T;
}

export type WishListProductSizeProperty = keyof models.WishListProductSize;
type PropType<TProp extends WishListProductSizeProperty> = models.WishListProductSize[TProp] | null;

interface SetProductSizeConfigurationValueArgs {
  productSizeId: number;
  property: WishListProductSizeProperty;
  value: PropType<WishListProductSizeProperty>
}

export const initializeAllocations: AsyncThunk<void, void, {state: RootState}> = createAsyncThunk(
  'wish-list-weekly/initializeAllocations',
  async (_, {getState, dispatch}) => {
    const rootState = getState(),
      {spaceAvailable, spaceAllocated} = rootState.wishListProductDetail,
      weeks = rootState.wishListHome.weeks || [],
      product = rootState.wishListProductDetail.product;
      
    if(product) {
      const allocated = refreshWeeklyAllocations(product, spaceAvailable, spaceAllocated, weeks);
      dispatch(setSpaceAllocated(allocated));
    }
  }
);

export const refreshAllocations: AsyncThunk<void, void, {state: RootState}> = createAsyncThunk(
  'wish-list-weekly/refreshAllocations',
  async (_, {getState, dispatch}) => {
    const rootState = getState(),
      {spaceAvailable, spaceAllocated} = rootState.wishListProductDetail,
      weeks = rootState.wishListHome.weeks || [],
      product = rootState.wishListProductDetail.product;
      
    if(product) {
      const
        copy = {...product},
        sizes = product.sizes
          .map(s => ({...s}));
        
        sizes.forEach(s => {
          if(s.finishWeekId) {
            s.plantWeekId = s.finishWeekId - s.productionWeeks;
            s.sales = [
                {id: -1, weekId: s.finishWeekId, percentSold: 50},
                {id: -2, weekId: s.finishWeekId + 1, percentSold: 25},
                {id: -3, weekId: s.finishWeekId + 2, percentSold: 25}
              ];
          }
        });

      setSkipGainWeeks(sizes);

      copy.sizes = sizes;

      await dispatch(setProduct(copy));

      const allocated = refreshWeeklyAllocations(product, spaceAvailable, spaceAllocated, weeks);
      dispatch(setSpaceAllocated(allocated));

      dispatch(setDirty(true));
    }
  }
);

export const setFirstFinishWeekId: AsyncThunk<void, SetSizeValueArgs<number>, {state: RootState}> = createAsyncThunk(
  'wish-list-weekly/setFirstFinishWeekId',
  async (args, {getState, dispatch}) => {
      const {sizeId, value} = args,
        rootState = getState(),
        state = rootState.wishListProductDetail,
        allWeeks = rootState.wishListHome.weeks || [],
        year = rootState.wishList.year;

      if(state.product) {

        const product = {...state.product},
          existingSizes = product.sizes.filter(s => s.sizeId === sizeId),
          firstSize = existingSizes[0],
          lastFinishWeekId = firstSize?.salesEndWeekId || allWeeks.filter(w => w.year === year).reduce((max, week) => Math.max(max, week.id), 0),
          weeks = allWeeks.filter(w => w.id >= value && ((lastFinishWeekId && w.id <= lastFinishWeekId) || (!lastFinishWeekId && w.year === year)));

        let id = product.sizes.reduce((min, s) => Math.min(min, s.id), 0) - 1,
          roundNumber = existingSizes.reduce((max, s) => s.sizeId === sizeId ? Math.max(max, s.roundNumber) : max, 1) + 1;

        if(weeks.length) {
          //make a copy of the sizes, but get rid of the ones outside the first/last range
          const sizes = product.sizes.filter(s => s.sizeId !== sizeId || !s.finishWeekId || weeks.some(w => w.id === s.finishWeekId)).map(s => ({...s}));
          
          weeks.forEach(week => {
              const size = existingSizes.find(s => s.finishWeekId === week.id),
                index = sizes.findIndex(s => s.id === size?.id);

              if(size && index !== -1) {

                sizes.splice(index, 1, {...size, ...{salesStartWeekId: value}});

              } else {

                const colours = product.colourRatios.map(c => {
                    return {id: id--, colourId: c.colourId, colourName: c.colourName, wishListQuantity: 0, colourRatioPercentage: c.percentage};
                  }),
                  newSize: models.WishListProductSize = {
                    id: id--,
                    sizeId,
                    sizeName: firstSize?.sizeName || '',
                    potsPerSquareFootTight: 0,
                    potsPerSquareFootSpaced: 0,
                    weeksTightlySpaced: 0,
                    plantWeekId: week.id,
                    salesStartWeekId: value,
                    salesEndWeekId: lastFinishWeekId,
                    defaultTightLocationId: null,
                    defaultSpacedLocationId: null,
                    productionWeeks: 0,
                    roundNumber: roundNumber++,
                    roundName: `Week ${week.weekNumber}`,
                    finishWeekId: week.id,
                    estimatedShrinkPercentage: 0,
                    potsPerCase: firstSize?.potsPerCase || 0,
                    isSkipWeek: false,
                    precedesSkipWeek: false,
                    succeedsSkipWeek: false,
                    isGainWeek: false,
                    precedesGainWeek: false,
                    succeedsGainWeek: false,
                    colours,
                    sales: [
                      // they get sold 25% 2 weeks after, and the remainder in the 3rd week
                      {id: id--, weekId: week.id, percentSold: 50},
                      {id: id--, weekId: week.id + 1, percentSold: 25},
                      {id: id--, weekId: week.id + 2, percentSold: 25}
                    ]
                  };

                //newSize.allocations = createAllocations(newSize, homeState);

                sizes.push(newSize);
              }
            });

          setSkipGainWeeks(sizes);
          product.sizes = sizes;

          dispatch(setProduct(product));
          dispatch(setDirty(true));
        }
      }
  }
);

export const setLastFinishWeekId: AsyncThunk<void, SetSizeValueArgs<number>, {state: RootState}> = createAsyncThunk(
  'wish-list-weekly/setLastFinishWeekId',
  async (args, {getState, dispatch}) => {
      const {sizeId, value} = args,
        rootState = getState(),
        state = rootState.wishListProductDetail,
        allWeeks = rootState.wishListHome.weeks || [],
        year = rootState.wishList.year;

      if(state.product) {

        const product = {...state.product},
          existingSizes = product.sizes.filter(s => s.sizeId === sizeId),
            firstSize = existingSizes[0],
            firstFinishWeekId = firstSize?.salesStartWeekId || allWeeks.filter(w => w.year === year).reduce((min, week) => Math.min(min, week.id), 0),
          weeks = allWeeks.filter(w => ((firstFinishWeekId && w.id >= firstFinishWeekId) || (!firstFinishWeekId && w.year === year)) && w.id <= value);

        let id = product.sizes.reduce((min, s) => Math.min(min, s.id), 0) - 1,
          roundNumber = existingSizes.reduce((max, s) => s.sizeId === sizeId ? Math.max(max, s.roundNumber) : max, 1) + 1;

        if(weeks.length) {
          //make a copy of the sizes, but get rid of the ones outside the first/last range
          const sizes = product.sizes.filter(s => s.sizeId !== sizeId || !s.finishWeekId || weeks.some(w => w.id === s.finishWeekId)).map(s => ({...s}));
          
          weeks.forEach(week => {
              const size = existingSizes.find(s => s.finishWeekId === week.id),
                index = sizes.findIndex(s => s.id === size?.id);

              if(size && index !== -1) {
                sizes.splice(index, 1, {...size, ...{salesEndWeekId: value}});
              } else {

                const colours = product.colourRatios.map(c => {
                    return {id: id--, colourId: c.colourId, colourName: c.colourName, wishListQuantity: 0, colourRatioPercentage: c.percentage};
                  }),
                newSize: models.WishListProductSize = {
                  id: id--,
                  sizeId,
                  sizeName: firstSize?.sizeName || '',
                  potsPerSquareFootTight: 0,
                  potsPerSquareFootSpaced: 0,
                  weeksTightlySpaced: 0,
                  plantWeekId: week.id,
                  salesStartWeekId: firstFinishWeekId,
                  salesEndWeekId: value,
                  defaultTightLocationId: null,
                  defaultSpacedLocationId: null,
                  productionWeeks: 0,
                  roundNumber: roundNumber++,
                  roundName: `Week ${week.weekNumber}`,
                  finishWeekId: week.id,
                  estimatedShrinkPercentage: 0,
                  potsPerCase: firstSize?.potsPerCase || 0,
                  isSkipWeek: false,
                  precedesSkipWeek: false,
                  succeedsSkipWeek: false,
                  isGainWeek: false,
                  precedesGainWeek: false,
                  succeedsGainWeek: false,
                  colours,
                  sales: [
                    // they get sold 25% 2 weeks after, and the remainder in the 3rd week
                    {id: id--, weekId: week.id, percentSold: 50},
                    {id: id--, weekId: week.id + 1, percentSold: 25},
                    {id: id--, weekId: week.id + 2, percentSold: 25}
                  ]
                };

                //newSize.allocations = createAllocations(newSize, homeState);

                sizes.push(newSize);
              }
            });

          setSkipGainWeeks(sizes);
          product.sizes = sizes;

          dispatch(setProduct(product));
          dispatch(setDirty(true));
        }
      }
  }
);

export const setColourPercentages: AsyncThunk<void, SetProductSizeValueArgs<ColourPercentage[]>, {state: RootState}> = createAsyncThunk(
  'wish-list-weekly/setColourPercentages',
  async (args, {getState, dispatch}) => {
      const {productSizeId, value} = args,
        rootState = getState(),
        state = rootState.wishListProductDetail;

      if(state.product) {

        const product = {...state.product},
          sizes = product.sizes.map(s => ({...s})),
          index = sizes.findIndex(s => s.id === productSizeId);

        if(index !== -1) {
          const size = {...sizes[index]},
            totalQuantity = size.colours.reduce((total, colour) => total + colour.wishListQuantity, 0),
            colours = size.colours.map(c => ({...c}));

          let quantityRemaining = totalQuantity;

          value.forEach((cp, index) => {
            const {colourId, percentage} = cp,
              colour = colours.find(c => c.colourId === colourId),
              quantity = Math.round(totalQuantity * (percentage / 100));

            if(colour) {
              colour.colourRatioPercentage = percentage;
              colour.wishListQuantity = index === value.length - 1 ? quantityRemaining : quantity;
              quantityRemaining -= quantity;
            }

            size.colours = colours;
          });

          sizes.splice(index, 1, size);
          product.sizes = sizes;

          dispatch(setProduct(product));
          dispatch(setDirty(true));
        }        
      }
  }
);

export const setEstimatedShrinkPercentage: AsyncThunk<void, SetProductSizeValueArgs<number>, {state: RootState}> = createAsyncThunk(
  'wish-list-weekly/setEstimatedShrinkPercentage',
  async (args, {getState, dispatch}) => {
      const {productSizeId, value} = args,
        rootState = getState(),
        state = rootState.wishListProductDetail;

      if(state.product) {

        const product = {...state.product},
          sizes = product.sizes.map(s => ({...s})),
          size = sizes.find(s => s.id === productSizeId);

        if(size) {
          size.estimatedShrinkPercentage = value;

          product.sizes = sizes;

          dispatch(setProduct(product));
          dispatch(setDirty(true));
        }        
      }
  }
);

export const copyShrinkForward: AsyncThunk<void, SetProductSizeValueArgs<number>, {state: RootState}> = createAsyncThunk(
  'wish-list-weekly/copyShrinkForward',
  async (args, {getState, dispatch}) => {
    const {productSizeId, value} = args,
      rootState = getState(),
      state = rootState.wishListProductDetail;

    if(state.product) {

      const product = {...state.product},
        size = product.sizes.find(s => s.id === productSizeId);

      if(size) {
        const finishWeekId = size.finishWeekId,
          sizeId = size.sizeId,
          sizes = product.sizes.map(s => ({...s}));

          if(finishWeekId) {
            sizes
              .filter(sz => {
                return sz.sizeId === sizeId && sz.finishWeekId && sz.finishWeekId > finishWeekId;
              })
              .forEach(sz => {
                  sz.estimatedShrinkPercentage = value;
              });
            product.sizes = sizes;

            dispatch(setProduct(product));
            dispatch(setDirty(true));
          }
        }
    }
  }
);

export const copyColourPercentageForward: AsyncThunk<void, SetProductSizeValueArgs<ColourPercentage>, {state: RootState}> = createAsyncThunk(
  'wish-list-weekly/copyColourPercentageForward',
  async (args, {getState, dispatch}) => {
    const {productSizeId, value} = args,
      rootState = getState(),
      state = rootState.wishListProductDetail;

    if(state.product) {

      const product = {...state.product},
        size = product.sizes.find(s => s.id === productSizeId);

        if(size) {
          const finishWeekId = size?.finishWeekId,
          sizeId = size?.sizeId,
          sizes = product.sizes.map(s => ({...s}));
          if(finishWeekId) {
            sizes
              .filter(sz => {
                return sz.sizeId === sizeId && sz.finishWeekId && sz.finishWeekId > finishWeekId;
              })
              .forEach(sz => {
                  const colours = sz.colours.map(c => ({...c})),
                    colour = colours.find(c => c.colourId === value.colourId),
                    totalQuantity = colours.reduce((total, colour) => total + colour.wishListQuantity, 0);

                  let quantityRemaining = totalQuantity;

                  if(colour) {
                    colour.colourRatioPercentage = value.percentage;
                  }

                  colours.forEach((c, index) => {
                    const quantity = Math.round(totalQuantity * ((c.colourRatioPercentage || 0) / 100));
                    c.wishListQuantity = index === colours.length - 1 ? quantityRemaining : quantity;
                    quantityRemaining -= quantity;
                  });
                  sz.colours = colours;
              });
          product.sizes = sizes;

          dispatch(setProduct(product));
          dispatch(setDirty(true));
        }
      }
    }
  }
);

export const setConfigurationValue: AsyncThunk<void, SetProductSizeConfigurationValueArgs, {state: RootState}> = createAsyncThunk(
  'wish-list-weekly/setConfigurationValue',
  async (args, {getState, dispatch}) => {
    const {productSizeId, property, value} = args,
      rootState = getState(),
      state = rootState.wishListProductDetail;

    if(state.product) {

      const product = {...state.product},
          sizes = product.sizes.map(s => ({...s})),
          size = sizes.find(s => s.id === productSizeId);

        if(size && property in size) {
          // @ts-ignore
          size[property] = value;
          //size.allocations = createAllocations(size, homeState);          

          if(property === 'productionWeeks') {
            if(size.finishWeekId) {
              size.plantWeekId = size.finishWeekId - size.productionWeeks;
              size.sales = [
                {id: -1, weekId: size.finishWeekId, percentSold: 50},
                {id: -2, weekId: size.finishWeekId + 1, percentSold: 25},
                {id: -3, weekId: size.finishWeekId + 2, percentSold: 25}
              ]
            }
          }

          setSkipGainWeeks(sizes);
          product.sizes = sizes;

          dispatch(setProduct(product));
          dispatch(setDirty(true));
        }
    }
  }
);

export const copyConfigurationValueForward: AsyncThunk<void, SetProductSizeConfigurationValueArgs, {state: RootState}> = createAsyncThunk(
  'wish-list-weekly/copyConfigurationValueForward',
  async (args, {getState, dispatch}) => {
    const {productSizeId, property, value} = args,
      rootState = getState(),
      state = rootState.wishListProductDetail;

    if(state.product) {

      const product = {...state.product},
          size = product.sizes.find(s => s.id === productSizeId),
          finishWeekId = size?.finishWeekId;

        if(size && finishWeekId) {
          const sizes = product.sizes.map(s => ({...s}));

          sizes.filter(s => s.sizeId === size.sizeId && s.finishWeekId && s.finishWeekId > finishWeekId)
            .forEach(s => {
              const newSize: models.WishListProductSize = {...s};
              // @ts-ignore
              newSize[property] = value;

              if(property === 'productionWeeks' && s.finishWeekId) {
                newSize.plantWeekId = s.finishWeekId - size.productionWeeks;
                newSize.sales = [
                  {id: -1, weekId: s.finishWeekId, percentSold: 50},
                  {id: -2, weekId: s.finishWeekId + 1, percentSold: 25},
                  {id: -3, weekId: s.finishWeekId + 2, percentSold: 25}
                ]
              }

              //newSize.allocations = createAllocations(newSize, homeState);

              const index = sizes.findIndex(sz => sz.id === s.id);
              if(index !== -1) {
                sizes.splice(index, 1, newSize);
              }
            });

            setSkipGainWeeks(sizes);
            product.sizes = sizes;

            dispatch(setProduct(product));
            dispatch(setDirty(true));
        }        
    }
  }
);

export const setPots: AsyncThunk<void, SetProductSizeValueArgs<number>, {state: RootState}> = createAsyncThunk(
  'wish-list-weekly/setPots',
  async (args, {getState, dispatch}) => {
      const {productSizeId, value} = args,
        rootState = getState(),
        state = rootState.wishListProductDetail,
        weeks = rootState.wishListHome.weeks || [],
        {spaceAvailable, spaceAllocated} = rootState.wishListProductDetail;

      if(state.product) {

        const product = {...state.product},
          sizes = product.sizes.map(s => ({...s})),
          size = sizes.find(s => s.id === productSizeId);

        if(size) {
          const colours = size.colours.map(c => ({...c}));
          let quantityRemaining = value;
          colours.forEach((c, index) => {
            const quantity = Math.round(value * (c.colourRatioPercentage || 0) / 100);
            c.wishListQuantity = (index === colours.length - 1) ? quantityRemaining : quantity;
            quantityRemaining -= quantity;
          });
          size.colours = colours;
          
          const allocations = refreshWeeklyAllocations(product, spaceAvailable, spaceAllocated, weeks);
          dispatch(setSpaceAllocated(allocations));

          setSkipGainWeeks(sizes);
          product.sizes = sizes;

          dispatch(setProduct(product));
          dispatch(setDirty(true));
        }
      }
  }
);

export const weeklySlice = createSlice({
  name: 'wish-list-detail-weekly',
  initialState,
  reducers: { }
});

export const selectSpaceAvailable = (state: RootState) => state.wishListProductDetail.spaceAvailable;
export const selectSpaceAllocated = (state: RootState) => state.wishListProductDetail.spaceAllocated;
const selectSizeId = (state: RootState) => state.wishListProductDetail.sizeId;

export const selectWeeklySizes = createSelector(
  selectProduct,
  (product) => {
    const allSizes = product?.sizes.map(sz => ({...sz})).sort(sortSize) || [],
      sizes = allSizes.reduce((memo, size) => {
        if(!memo.some(s => s.id === size.sizeId)) {
          memo.push({id: size.sizeId, name: size.sizeName});
        }
        return memo;
      }, [] as WeeklyProductSize[]);
    return sizes;
  }
);

export const selectWeeklySize = createSelector(
  selectWeeklySizes,
  selectSizeId,
  (weeklySizes, sizeId) => {
    return weeklySizes.find(s => s.id === sizeId) || null;
  }
);

export const selectSummary = createSelector(
  selectYear,
  selectProduct,
  selectWeeklySizes,
  (state: RootState) => (state.wishListHome.weeks || []),
  selectSpaceAvailable,
  selectSpaceAllocated,
  (state: RootState) => state.wishListHome.growingLocations || [],
  (year, product, weeklySizes, allWeeks, spaceAvailable, spaceAllocated, growingLocations) => {
    const productSizes = product?.sizes || [],
      yearWeeks = allWeeks.filter(w => w.year === year),
      firstFinishWeek = productSizes.reduce((min, s) => Math.min(min, (s.finishWeekId || 0)), yearWeeks[yearWeeks.length - 1]?.id || Number.MAX_SAFE_INTEGER),
      lastFinishWeekId = productSizes.reduce((max, s) => Math.max(max, (s.finishWeekId || 0)), yearWeeks[0]?.id || 0),
      wishListProductPriorities = spaceAvailable.reduce((memo, a) => {
        if(!memo.has(a.wishListProductId)) {
          memo.set(a.wishListProductId, a.wishListProductPriority);
        }
        return memo;
      }, new Map<number, number>()),
      productPrioritiesByGrowingLocation = spaceAvailable
        .filter(a => a.wishListProductId === product?.id)
        .reduce((memo, a) => memo.set(a.growingLocationId, a.wishListProductPriority), new Map<number, number>());

    return allWeeks
      .filter(w => w.id >= firstFinishWeek && w.id <= lastFinishWeekId)
      .map((week): WeekSummaryItem => {
        const sizeSizes = productSizes.filter(s => s.finishWeekId === week.id),
          sizeArray = sizeSizes.map(size => {
            const {sizeId, sizeName} = size,
              pots = size.colours.reduce((total, colour) => total + colour.wishListQuantity, 0);

            return {id: size.id, sizeId, sizeName, pots, potsPerCase: size.potsPerCase};
          }),
          allocationForWeek = spaceAllocated.filter(a => a.weekId === week.id),
          availableForWeek = spaceAvailable.filter(a => a.weekId === week.id),
          sizes = weeklySizes.reduce((memo, weeklySize) => {
            const
              firstSize = sizeArray.find(sz => sz.sizeId === weeklySize.id),
              id = firstSize?.id || 0,
              pots = firstSize?.pots || 0,
              potsPerCase = firstSize?.potsPerCase || 1,
              cases = Math.ceil(pots / potsPerCase),              
              sizeAllocations = allocationForWeek.filter(f => f.productId === product?.productId && f.sizeId === weeklySize.id),
              sizeAvailable = availableForWeek.filter(f => f.productId === product?.productId && f.sizeId === weeklySize.id),
              weeklyProductSizeIds = sizeAllocations.reduce((memo, a) => {
                if(memo.indexOf(a.wishListProductSizeId) === -1) {
                  memo.push(a.wishListProductSizeId);
                }
                return memo;
              }, [] as number[]),
              space = productSizes
                .filter(ps => weeklyProductSizeIds.indexOf(ps.id) !== -1)
                .reduce((total, ps) => total + capacity.squareFeet(ps, week.id), 0),
              locations = sizeAvailable.map(sa => sa.growingLocationId),
              otherAllocations = allocationForWeek
                .filter(f => f.growingLocationId > 0)
                .filter(f => {
                  if(locations.indexOf(f.growingLocationId) === -1) {
                    return false;
                  }

                  // return all other sizes
                  if(f.productId === product?.productId) {
                    return f.sizeId !== weeklySize.id;
                  }
                  
                  return (wishListProductPriorities.get(f.productId) || 1) < (productPrioritiesByGrowingLocation.get(f.growingLocationId) || 1);
                }),
              allocated = sizeAllocations
                .filter(sa => sa.growingLocationId > 0)
                .reduce((total, sa) => total + sa.space, 0),
              spaceForOtherSizes = otherAllocations.reduce((total, sa) => total + sa.space, 0),
              otherSizes = otherAllocations.reduce((memo, a) => {
                const name = `${a.sizeName} ${a.productName}`,
                  space = otherAllocations.filter(o => o.wishListProductId === a.wishListProductId && o.sizeId === a.sizeId).reduce((total, o) => total + o.space, 0),
                  otherSize = `${name}: ${formatNumber(space)}`;
                if(!memo.find(o => o.indexOf(name) !== -1)) {
                  memo.push(otherSize);
                }
                return memo;
              }, [] as string[]),
              available = availableForWeek
                .filter(a => a.productId === product?.productId && a.sizeId === weeklySize.id)
                .reduce((memo, a) => {
                  if(!memo.find(sa => sa.growingLocationId === a.growingLocationId)) {
                    memo.push(a);
                  }
                  return memo;
                }, [] as models.WishListSpaceAvailable[])
                .reduce((total, a) => total + a.space, 0),
              availableForSize = available - spaceForOtherSizes,
              remaining = availableForSize - space;

            memo[weeklySize.id] = {id, sizeId: weeklySize.id, sizeName: weeklySize.name, pots, cases, potsPerCase, space, allocated, available, remaining, availableForSize, otherSizes, spaceForOtherSizes}
            
            return memo;
          }, {} as {[index: number]: WeekSummaryItemSize}),
          sizeValues = Object.values(sizes),
          quantity = sizeValues.reduce((total, size) => total + size.pots, 0),
          required = sizeValues.reduce((total, sa) => total + sa.space, 0),
          allocated = sizeValues.reduce((total, sa) => total + sa.allocated, 0),
          availableForProduct = availableForWeek.filter(a => a.wishListProductId === product?.id),
          otherAvailable = availableForWeek.filter(a => a.wishListProductId !== product?.id),
          otherPriorities = otherAvailable.reduce((memo, a) => memo.set(a.wishListProductId, a.wishListProductPriority), new Map<number, number>()),
          locations = availableForProduct.map(sa => sa.growingLocationId),
          otherProductAllocations = allocationForWeek
            .filter(f => f.wishListProductId !== product?.id && (otherPriorities.get(f.wishListProductId) || 1) < (productPrioritiesByGrowingLocation.get(f.growingLocationId) || 1) && locations.indexOf(f.growingLocationId) !== -1),
          spaceForOtherProducts = otherProductAllocations.reduce((total, sa) => total + sa.space, 0),
          availableLocations = availableForWeek
            .filter(a => a.wishListProductId === product?.id)
            .reduce((memo, a) => {
              if(!memo.find(sa => sa.id === a.growingLocationId)) {
                const location = growingLocations.find(l => l.id === a.growingLocationId);
                if(location) {
                  const {id, name, isHangingLocation} = location,
                    space = location.weeks
                      .filter(w => w.weekId === week.id)
                      .reduce((total, w) => total + w.squareFeet, 0),
                    otherAllocations = Array.from(otherProductAllocations
                      .filter(o => o.growingLocationId === a.growingLocationId)
                      .reduce((memo, o) => {
                        const key = o.productName,
                          allocated = (memo.get(key)) || 0 + o.space;
                        return memo.set(key, allocated);
                      }, new Map<string, number>())
                      .entries())
                      .map(kvp => `${kvp[0]}: ${formatNumber(kvp[1])}`);
                  memo.push({id, name, isHangingLocation, space, priorityTight: a.growingLocationPriorityTight, prioritySpaced: a.growingLocationPrioritySpaced, otherAllocations});
                }
              }
              return memo;
            }, [] as WeekSummaryLocation[])
            .sort(sortBy('priority')),
          totalAvailableSpace = availableLocations.reduce((total, l) => total + l.space, 0),
          availableSpace = totalAvailableSpace - spaceForOtherProducts;

        return {
          week,
          quantity,
          required,
          allocated,
          availableLocations,
          totalAvailableSpace,
          availableSpace,
          sizes
        };
      });
  }
);

export const selectSize = createSelector(
  selectProduct,
  selectWeeklySize,
  (product, weeklySize) => {
    if(!product || !weeklySize) {
      return null;
    }

    const size = product.sizes.find(s => s.sizeId === weeklySize.id);
    if(!size) {
      return null;
    }

    return {...size, ...{name: weeklySize?.name}};
  }
);

export const selectColourWeeks = createSelector(  
  selectProduct,
  selectSizeId,
  selectWeeks,
  (product, sizeId, weeks) => (product?.sizes || [])
      .filter(size => size.sizeId === sizeId)
      .slice()
      .map(size => {
        const week = weeks?.find(w => w.id === size.finishWeekId),
          name = (week ? `Week ${week.weekNumber}, ${week.year}` : size.roundName) || `Round ${size.roundNumber}`,
          colourPercentages = size.colours.reduce((memo, colour) => {
            const {colourId, colourRatioPercentage} = colour;
            memo.push({colourId, percentage: (colourRatioPercentage || 0)});
            return memo;
          }, [] as ColourPercentage[]),
          {id, estimatedShrinkPercentage, finishWeekId, roundNumber} = size;
        return {id, name, shrink: estimatedShrinkPercentage, colourPercentages, finishWeekId, roundNumber} as SeasonWeek;
      })
      .sort(sortColourWeek)
);

export const selectConfigurationWeeks = createSelector(  
  selectProduct,
  selectSizeId,
  (product, sizeId) => (product?.sizes || [])
      .filter(size => size.sizeId === sizeId)
      .sort(sortConfigurationWeek)
);

const sortByFinishWeekId = sortBy('finishWeekId'),
  sortByRoundNumber = sortBy('roundNumber'),
  sortByName = sortBy('name');

function sortColourWeek(a: SeasonWeek, b: SeasonWeek) {
  // put all the weeks at the beginning
  if(a.finishWeekId && !b.finishWeekId) return -1;
  if(!a.finishWeekId && b.finishWeekId) return 1;

  const sort1 = sortByFinishWeekId(a, b);
  if(sort1 !== 0) {
    return sort1;
  }
  const sort2 = sortByRoundNumber(a, b);
  if(sort2 !== 0) {
    return sort2;
  }
  return sortByName(a, b);
}

function sortConfigurationWeek(a: models.WishListProductSize, b: models.WishListProductSize) {
  // put all the weeks at the beginning
  if(a.finishWeekId && !b.finishWeekId) return -1;
  if(!a.finishWeekId && b.finishWeekId) return 1;

  const sort1 = sortByFinishWeekId(a, b);
  if(sort1 !== 0) {
    return sort1;
  }
  const sort2 = sortByRoundNumber(a, b);
  if(sort2 !== 0) {
    return sort2;
  }
  return sortByName(a, b);
}

export default weeklySlice.reducer;

export interface WeeklyProductSize {
  id: number;
  name: string;
}

export interface WeekSummaryItem {
  week: models.Week;
  quantity: number;
  required: number;
  allocated: number;
  availableLocations: WeekSummaryLocation[];
  totalAvailableSpace: number;
  availableSpace: number;
  sizes: {[index: number]: WeekSummaryItemSize};
}

export interface WeekSummaryItemSize {
  id: number;
  sizeId: number;
  sizeName: string;
  cases: number;
  pots: number;
  potsPerCase: number;
  space: number;
  allocated: number;
  available: number;
  remaining: number;
  availableForSize: number;
  spaceForOtherSizes: number;
  otherSizes: string[]
}

export interface WeekSummaryLocation {
  id: number;
  name: string;
  isHangingLocation: boolean;
  space: number;
  priorityTight: number;
  prioritySpaced: number;
  otherAllocations: string[];
}

export interface SeasonWeek {
  id: number;
  name: string;
  shrink: number;
  colourPercentages: ColourPercentage[];
  finishWeekId: number | null;
  roundNumber: number;
}

export interface ColourPercentage {
  colourId: number;
  percentage: number;
}

export interface QuantityWeek {
  id: number;
  roundNumber: number;
  plantWeek: models.Week | null;
  finishWeek: models.Week | null;
  finishWeekId: number | null;
  pots: number;
  cases: number;
  spaceRequired: number;
  spaceAllocated: number;
  hasColourRatios: boolean;
  potsPerCase: number;
  isSkipWeek: boolean;
  isGainWeek: boolean;
}

function setSkipGainWeeks(sizes: models.WishListProductSize[]) {
  sizes.forEach(size => {
    const previous = sizes.find(s => s.finishWeekId === ((size.finishWeekId || 0) - 1) && s.sizeId === size.sizeId),
      next = sizes.find(s => s.finishWeekId === ((size.finishWeekId || 0) + 1) && s.sizeId === size.sizeId);
  
    size.isSkipWeek = size.productionWeeks < (previous || size).productionWeeks;
    size.precedesSkipWeek = !!previous && previous.isSkipWeek;
    size.succeedsSkipWeek = !!next && next.isSkipWeek;
    size.isGainWeek = size.productionWeeks > (previous || size).productionWeeks;
    size.precedesGainWeek = !!previous && previous.isGainWeek;
    size.succeedsGainWeek = !!next && next.isGainWeek;
  
    if(previous) {
      previous.precedesSkipWeek = size.isSkipWeek;
      previous.precedesGainWeek = size.isGainWeek;
    }
    if(next) {
      next.succeedsSkipWeek = size.isSkipWeek;
      next.succeedsGainWeek = size.isGainWeek
    }
  });
}
