import { createSlice, createAsyncThunk, createSelector, PayloadAction, AsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { adminApi } from 'api/admin-service';
import { AdminColour } from 'api/models/admin';
import { ProblemDetails } from 'utils/problem-details';

export const DefaultColourGlCode = '40200000';

export interface ColourState {
  colours: AdminColour[] | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: ColourState = {
  colours: null,
  loading: false,
  error: null
};

export const getColours: AsyncThunk<AdminColour[], void, {state: RootState}> = createAsyncThunk(
  'admin/getColours',
  async (_, {rejectWithValue}) => {
    try {
      return await adminApi.colourList();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const createColour: AsyncThunk<AdminColour, AdminColour, {state: RootState}> = createAsyncThunk(
  'admin/createColour',
  async (colour, {rejectWithValue}) => {
    try {
      return await adminApi.colourCreate(colour);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateColour: AsyncThunk<AdminColour, AdminColour, {state: RootState}> = createAsyncThunk(
  'admin/updateColour',
  async (colour, {rejectWithValue}) => {
    try {
      return await adminApi.colourUpdate(colour);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteColour: AsyncThunk<AdminColour, number, {state: RootState}> = createAsyncThunk(
  'admin/deleteColour',
  async (id, {rejectWithValue}) => {
    try {
      return await adminApi.colourDelete(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const colourSlice = createSlice({
  name: 'colours',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    }
  },
  extraReducers: {
    [getColours.pending.type]: (state) => {
      state.colours = [];
      state.error = null;
      state.loading = true;
    },
    [getColours.fulfilled.type]: (state, action: PayloadAction<AdminColour[]>) => {
      state.colours = action.payload;
      state.error = null;
      state.loading = false;
    },
    [getColours.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createColour.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [createColour.fulfilled.type]: (state, action: PayloadAction<AdminColour>) => {
      state.error = null;
      state.loading = false;
      if(state.colours) {
        state.colours.push(action.payload);
      }
    },
    [createColour.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateColour.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [updateColour.fulfilled.type]: (state, action: PayloadAction<AdminColour>) => {
      state.error = null;
      state.loading = false;

      if(state.colours) {
        const colour = action.payload,
          colours = state.colours.slice(),
          index = colours.findIndex(c => c.id === colour.id);

        if(index !== -1) {
          colours.splice(index, 1, colour);
        }

        state.colours = colours;
      }
    },
    [updateColour.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteColour.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [deleteColour.fulfilled.type]: (state, action: PayloadAction<AdminColour>) => {
      state.error = null;
      state.loading = false;

      if(state.colours) {
        const colour = action.payload,
          colours = state.colours.slice(),
          index = colours.findIndex(c => c.id === colour.id);

        if(index !== -1) {
          colours.splice(index, 1);
        }

        state.colours = colours;
      }
    },
    [deleteColour.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setError } = colourSlice.actions;

export const selectColours = (state: RootState) => state.colours.colours;
export const selectLoading = (state: RootState) => state.colours.loading;
export const selectError = (state: RootState) => state.colours.error;

export const selectSortedColours = createSelector(
  selectColours,
  colours => colours?.slice().sort((a, b) => a.name.localeCompare(b.name)) || null
);

export default colourSlice.reducer;
