import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction, createAction, createSelector } from '@reduxjs/toolkit';
import * as models from 'api/models/wish-list';
import { CreateRevisionResponse, DeleteRevisionResponse, RevisionsResponse, wishListApi } from 'api/wish-list-service';
import { RootState } from 'app/store';
import { ProblemDetails } from 'utils/problem-details';

const year = new Date().getFullYear();

export interface WishListState {
  year: number;
  revisions: models.WishListRevision[];
  revision: models.WishListRevision | null;
}

const initialState: WishListState = {
  year,
  revisions: [],
  revision: null
};

export const getRevisions: AsyncThunk<RevisionsResponse, number, {state: RootState}> = createAsyncThunk(
  'wish-list/getRevisions',
  async (year, {rejectWithValue}) => {
    try {
      return await wishListApi.revisions(year);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

interface CreateDraftArgs {
  name: string;
  comments: string | null;
}

export const createDraft: AsyncThunk<CreateRevisionResponse, CreateDraftArgs, {state: RootState}> = createAsyncThunk(
  'wish-list/createDraft',
  async (args, {rejectWithValue, getState}) => {
    try {
      const {year} = getState().wishList,
        {name, comments} = args;

      return await wishListApi.createRevision(name, comments, year);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteDraft: AsyncThunk<DeleteRevisionResponse, number, {state: RootState}> = createAsyncThunk(
  'wish-list/deleteDraft',
  async (id, {rejectWithValue}) => {
    try {
      return await wishListApi.deleteRevision(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getRevisionsFulfilled = createAction<RevisionsResponse>(getRevisions.fulfilled.type),
  createDraftFulfilled = createAction<CreateRevisionResponse>(createDraft.fulfilled.type),
  deleteDraftFulfilled = createAction<DeleteRevisionResponse>(deleteDraft.fulfilled.type);

export const wishListSlice = createSlice({
  name: 'wish-list',
  initialState,
  reducers: {
    setYear(state, action: PayloadAction<number>) {
      state.year = action.payload;
    },
    setRevision(state, action: PayloadAction<models.WishListRevision | null>) {
      state.revision = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(getRevisionsFulfilled, (state, action) => {
        state.revisions = action.payload.revisions;
      })
      .addCase(createDraftFulfilled, (state, action) => {
        const revision = action.payload.revision,
          revisions = state.revisions.slice();


        revisions.push(revision);
        state.revisions = revisions;
      })
      .addCase(deleteDraftFulfilled, (state, action) => {
        const revisions = state.revisions.slice(),
          index = revisions.findIndex(r => r.id === action.payload.revisionId);

        if(index !== -1) {
          revisions.splice(index, 1);
          state.revisions = revisions;
          state.revision = null;
        }
      })
});

export const { setYear, setRevision } = wishListSlice.actions;

export const selectYear = (state: RootState) => state.wishList.year;
export const selectRevisions = (state: RootState) => state.wishList.revisions;
export const selectRevision = (state: RootState) => state.wishList.revision;
export const selectYears = createSelector(
  selectYear,
  year => [year - 2, year - 1, year, year + 1, year + 2]
);

export default wishListSlice.reducer;
