import { ApiBase } from './api-base';
import * as models from './models/growing-locations';
import { WishListSpaceAvailable } from './models/wish-list';

class GrowingLocationService extends ApiBase {
  getAll(year: number): Promise<GrowingLocationIndexResponse> {
    return this.get(`/api/growing-locations?year=${year}`);
  }

  getOne(id: number, year: number): Promise<GrowingLocationDetailResponse> {
    return this.get(`/api/growing-locations/${id}?year=${year}`);
  }

  create(year: number, name: string,isHangingLocation: boolean, isActive: boolean, zones: GrowingLocationZoneUpdate[]): Promise<GrowingLocationResponse> {
    const model = {year, name, isHangingLocation, isActive, zones};
    return this.post('/api/growing-locations', model);
  }

  update(id: number, year: number, name: string, isHangingLocation: boolean, isActive: boolean, zones: GrowingLocationZoneUpdate[]): Promise<GrowingLocationResponse> {
    const model = {id, year, name, isHangingLocation, isActive, zones};
    return this.put(`/api/growing-locations/${id}`, model);
  }

  deleteGrowingLocation(id: number): Promise<void> {
    return this.delete(`/api/growing-locations/${id}`);
  }

  updateZoneWeeks(locationId: number, year: number, zoneWeeks: GrowingLocationZoneWeekUpdate[],
    growingLocationWeekProductSizes: models.GrowingLocationWeekWishListProductSize[]): Promise<GrowingLocationResponse> {
      const model = {locationId, year, zoneWeeks, growingLocationWeekProductSizes};
      return this.put(`/api/growing-locations/${locationId}/weeks`, model);
  }

  wishListProducts(year: number): Promise<WishListProductsResponse> {
    return this.get(`/api/growing-locations/wish-list-products?year=${year}`);
  }

  allocations(year: number) : Promise<WishListAllocationsResponse> {
    return this.get(`/api/growing-locations/allocations?year=${year}`);
  }

  allocationsByLocation(locationId: number, year: number) : Promise<WishListAllocationsResponse> {
    return this.get(`/api/growing-locations/allocations/locations/${locationId}?year=${year}`);
  }

  allocationsByWeek(weekId: number, year: number) : Promise<WishListAllocationsResponse> {
    return this.get(`/api/growing-locations/allocations/weeks/${weekId}?year=${year}`);
  }

  addAvailabilities(model: GrowingLocationAvailabilitiesModel): Promise<GrowingLocationAvailabilitiesResponse> {
    return this.post('/api/growing-locations/availabilities', model);
  }

  updatePriorities(locationId: number, year: number, priorities: GrowingLocationPriority[]): Promise<GrowingLocationPrioritiesResponse> {
    return this.post(`/api/growing-locations/${locationId}/priorities`, {year, priorities});
  }
}

export interface GrowingLocationIndexResponse {
  locations: models.GrowingLocationListItem[];
}

export interface GrowingLocationDetailResponse {
  location: models.GrowingLocation;
  growingLocationWeekProductSizes: models.GrowingLocationWeekWishListProductSize[];
  weeks: models.Week[];
}

export interface GrowingLocationResponse {
  location: models.GrowingLocation;
}

export interface GrowingLocationZoneUpdate {
  id: number;
  name: string;
  isAvailableByDefault: boolean;
  squareFeet: number;
  notes: string | null;
}

export interface GrowingLocationZoneWeekUpdate {
  id: number;
  weekId: number;
  growingLocationZoneId: number;
  squareFeet: number;
  isAvailable: boolean;
}

export interface WishListProductsResponse {
  products: models.WishListProduct[];
}

export interface WishListAllocationsResponse {
  allocations: models.AllocationListModel;
  weeks: models.Week[];
  growingLocations: models.GrowingLocationListItem[];
}

export interface GrowingLocationAvailabilitiesModel {
  wishListProductId: number;
  sizeId: number;
  growingLocationId: number;
  availabilities: GrowingLocationAvailability[];
}

export interface GrowingLocationAvailability {
  weekId: number;
  allowTight: boolean;
  allowSpaced: boolean;
}

export interface GrowingLocationAvailabilitiesResponse {
  available: WishListSpaceAvailable[];
}

export interface GrowingLocationPriority {
  wishListProductId: number;
  priority: number;
}

export interface GrowingLocationPrioritiesResponse {
  growingLocationWeekProductSizes: models.GrowingLocationWeekWishListProductSize[];
}

export const growingLocationApi = new GrowingLocationService();
