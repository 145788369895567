import { ApiBase } from './api-base';
import * as models from './models/wish-list';
import { AllocationAnalysis } from './models/allocation-analysis';

class WishListService extends ApiBase {
  home(year: number, revisionId: number | undefined): Promise<WishListHomeResponse> {
    const query = revisionId ? `&revision=${revisionId}` : '';
    return this.get(`/api/wish-list?year=${year}${query}`);
  }

  getProductDetail(id: number, revisionId: number | undefined): Promise<WishListProductDetailResponse> {
    const query = revisionId ? `?revision=${revisionId}` : '';
    return this.get(`/api/wish-list/${id}${query}`);
  }

  productUpdate(wishListProductId: number, useRatiosBySize: boolean,
    sizes: WishListProductSizeUpdate[], allocations: WishListProductSizeAllocationUpdate[],
      sales: WishListProductSizeSalesWeekUpdate[], sizeColourRatios: WishListProductSizeColourRatioUpdate[]): Promise<WishListProductUpdateReponse> {
    return this.put(`/api/wish-list/${wishListProductId}`, {wishListProductId, useRatiosBySize, sizes, allocations, sales, sizeColourRatios});
  }

  adminProducts(year: number): Promise<WishListProductsResponse> {
    return this.get(`/api/wish-list/products/admin?year=${year}`);
  }

  productAdminDetail(id: number, revision: number | null): Promise<WishListProductAdminResponse> {
    return this.get(`/api/wish-list/products/admin/${id}` + (revision ? `?revision=${revision}` : ''));
  }

  wishListProductAdminUpdate(id: number, wishListDisplayName: string | null, useRatios: boolean, useRatiosBySize: boolean, isWeekly: boolean, sizes: UpdateWishListProductAdminSize[], colours: UpdateWishListProductAdminColour[]): Promise<WishListProductAdminUpdateResponse> {
    return this.post(`/api/wish-list/products/admin/${id}`, {wishListDisplayName, useRatios, useRatiosBySize, isWeekly, sizes, colours});
  }

  wishListProductAdminCreate(year: number, productId: number, wishListDisplayName: string | null): Promise<WishListProductAdminCreateResponse> {
    return this.post('/api/wish-list/products/admin', {year, productId, wishListDisplayName});
  }

  deleteWishListProduct(id: number): Promise<WishListProductUpdateReponse> {
    return this.delete(`/api/wish-list/products/admin/${id}`);
  }

  weeks(year: number): Promise<GetWeeksResponse> {
    return this.get(`/api/wish-list/weeks?year=${year}`);
  }

  createSize(name: string): Promise<CreateSizeResponse> {
    return this.post('/api/wish-list/sizes', {name});
  }

  deleteSize(id: number): Promise<void> {
    return this.delete(`/api/wish-list/sizes/${id}`);
  }

  createColour(name: string): Promise<CreateColourResponse> {
    return this.post('/api/wish-list/colours', {name});
  }

  deleteColour(id: number): Promise<void> {
    return this.delete(`/api/wish-list/colours/${id}`);
  }

  revisions(year: number): Promise<RevisionsResponse> {
    return this.get(`/api/wish-list/revisions?year=${year}`);
  }

  createRevision(name: string, comments: string | null, year: number): Promise<CreateRevisionResponse> {
    return this.post('/api/wish-list/revisions', {name, comments, year});
  }

  deleteRevision(id: number): Promise<DeleteRevisionResponse> {
    return this.delete(`/api/wish-list/revisions/${id}`);
  }

  copyYear(fromYear: number, toYear: number): Promise<CopyYearResponse> {
    return this.post('/api/wish-list/copy', {fromYear, toYear});
  }

  updatePriorities(wishListProductId: number, priorities: WishListPriority[]): Promise<WishListProductPrioritiesResponse> {
    return this.post(`/api/wish-list/${wishListProductId}/priorities`, {priorities});
  }

  allocationAnalysis(year: number): Promise<AllocationAnalysisResponse> {
    return this.get(`/api/wish-list/allocation-analysis?year=${year}`);
  }
}

export interface WishListHomeResponse {
  products: models.WishListHomeProduct[];
  weeks: models.Week[];
  growingLocations: models.WishListGrowingLocation[];
  items: models.WishListHomeItem[];
}

export interface WishListProductDetailResponse {
  product: models.WishListProduct;
  sizes: models.Size[];
  colours: models.WishListColour[];
  spaceAllocated: models.WishListSpaceAllocated[];
  spaceAvailable: models.WishListSpaceAvailable[];
}

export interface WishListProductSizeColourUpdate {
  id: number;
  wishListQuantity: number;
}

export interface WishListProductSizeAllocationUpdate {
  weekId: number;
  wishListProductSizeId: number;
  growingLocationId: number;
  squareFeet: number;
}

export interface UpdateWishListProductAdminSize {
  id: number;
  sizeId: number;
  potsPerCase: number;
}

export interface UpdateWishListProductAdminColour {
  colourId: number;
  percentage: number | null;
}

export interface WishListProductUpdateReponse {
  product: models.WishListProduct;
}

export interface WishListProductAdminCreateResponse {
  product: models.WishListProductListItem;
}

export interface WishListProductAdminUpdateResponse {
  products: models.WishListProductListItem[];
}

export interface WishListProductsResponse {
  wishListProducts: models.WishListProductListItem[];
  products: models.Product[];
}

export interface WishListProductAdminResponse {
  product: models.WishListProduct;
  sizes: models.Size[];
  colours: models.WishListColour[];
}

export interface UpdateWishListProductAdminModel {
  productId: number;
  year: number;
  sizeIds: number[];
  colourIds: number[];
}

export interface GetWeeksResponse {
  weeks: models.Week[];
}

export interface WishListProductSizeUpdate {
  id: number;
  sizeId: number;
  potsPerSquareFootTight: number;
  potsPerSquareFootSpaced: number;
  weeksTightlySpaced: number;
  plantWeekId: number | null;
  salesStartWeekId: number;
  salesEndWeekId: number;
  defaultTightLocationId: number | null;
  defaultSpacedLocationId: number | null;
  productionWeeks: number;
  roundNumber: number;
  roundName: string | null;
  finishWeekId: number | null;
  estimatedShrinkPercentage: number;
  isSkipWeek: boolean;
  precedesSkipWeek: boolean;
  succeedsSkipWeek: boolean;
  isGainWeek: boolean;
  precedesGainWeek: boolean;
  succeedsGainWeek: boolean;

  colours: WishListProductSizeColourUpdate[];
}

export interface WishListProductSizeColourUpdate {
  id: number;
  colourId: number;
  wishListQuantity: number;
  colourRatioPercentage: number | null;
}

export interface WishListProductSizeSalesWeekUpdate {
  wishListProductSizeId: number;
  weekId: number;
  percentSold: number | null;
}

export interface WishListProductSizeColourRatioUpdate {
  sizeId: number;
  colourId: number;
  percentage: number;
}

export interface CreateSizeResponse {
  size: models.Size;
}

export interface CreateColourResponse {
  colour: models.WishListColour;
}

export interface RevisionsResponse {
  revisions: models.WishListRevision[];
}

export interface CreateRevisionResponse {
  revision: models.WishListRevision;
}

export interface DeleteRevisionResponse {
  revisionId: number;
}

export interface CopyYearResponse {
  year: number;
}

export interface WishListPriority {
  growingLocationId: number;
  sizeId: number;
  priorityTight: number;
  prioritySpaced: number;
}

export interface WishListProductPrioritiesResponse {
  available: models.WishListSpaceAvailable[];
}

export interface AllocationAnalysisResponse {
  analysis: AllocationAnalysis;
}

export const wishListApi = new WishListService();
