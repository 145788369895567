import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api-base';
import * as models from './models/propagation';

export const propagationPurchaseOrderApi = createApi({
  reducerPath: 'propagation-purchase-order-api',
  baseQuery: axiosBaseQuery('/api/propagation/purchase-orders/'),
  tagTypes: ['PurchaseOrders', 'PurchaseOrder'],
  endpoints: builder => ({
    purchaseOrderList: builder.query<PurchaseOrderListResponse, PurchaseOrderListArgs>({
      query: model => {
        const yearParam = model.year ? `year=${model.year}&` : '',
          supplierParam = model.supplierId ? `supplierId=${model.supplierId}&` : '',
          productParam = model.productId ? `productId=${model.productId}` : '',
          url = '?' + yearParam + supplierParam + productParam,
          method = 'GET';

        return {url, method};
      },
      providesTags: ['PurchaseOrders']
    }),
    purchaseOrderDetail: builder.query<PurchaseOrderDetailResponse, PurchaseOrderDetailArgs>({
      query: model => {
        const yearParam = model.year ? `?year=${model.year}` : '',
          url = model.id + yearParam,
          method = 'GET';

        return {url, method};
      },
      providesTags: ['PurchaseOrder']
    }),
    purchaseOrderUpdate: builder.mutation<PurchaseOrderDetailUpdateResponse, PurchaseOrderDetailUpdateArgs>({
      query: data => ({url: `${data.purchaseOrder.id}`, method: 'PUT', data}),
      invalidatesTags: ['PurchaseOrders', 'PurchaseOrder']
    }),
    incomingPurchaseOrderList: builder.query<IncomingPurchaseOrderListResponse, IncomingPurchaseOrderListArgs>({
      query: ({year, productId, startWeekId, endWeekId}) => {
        const productParam = productId ? `&productId=${productId}` : '',
          startWeekParam  = startWeekId ? `&startWeekId=${startWeekId}` : '',
          endWeekParam = endWeekId ? `&endWeekId=${endWeekId}` : '',
          url = `incoming?year=${year}${productParam}${startWeekParam}${endWeekParam}`,
          method = 'GET';

        return {url, method};
      }
    })
  })
});

export interface PurchaseOrderListArgs {
  year: number | null;
  supplierId: number | null;
  productId: number | null;
}

export interface PurchaseOrderDetailArgs {
  id: number;
  year: number | null;
}

export interface PurchaseOrderListResponse {
  purchaseOrders: models.PurchaseOrderListItem[];
  suppliers: models.Supplier[];
  products: models.PurchaseOrderListProduct[];
}

export interface PurchaseOrderDetailResponse {
  purchaseOrder: models.PurchaseOrder;
  suppliers: models.Supplier[];
  weeks: models.PurchaseOrderWeek[];
  userName: string;
}

export interface PurchaseOrderDetailUpdateArgs {
  purchaseOrder: models.PurchaseOrder;
}

export interface PurchaseOrderDetailUpdateResponse {
  purchaseOrder: models.PurchaseOrder;
}

interface IncomingPurchaseOrderListArgs {
  year: number;
  productId: number | null;
  startWeekId: number | null;
  endWeekId: number | null;
}

export interface IncomingPurchaseOrderListResponse {
  purchaseOrders: models.IncomingPurchaseOrderListItem[];
  products: models.PurchaseOrderListProduct[];
  weeks: models.PurchaseOrderWeek[];
}

export const { usePurchaseOrderListQuery, usePurchaseOrderDetailQuery, usePurchaseOrderUpdateMutation, useIncomingPurchaseOrderListQuery } = propagationPurchaseOrderApi;
