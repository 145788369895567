import { ApiBase } from './api-base';
import { AdminColour, AdminCustomer, AdminBroker, AdminSalesTax, AdminVariety, AdminPrice } from './models/admin';

class AdminService extends ApiBase {
  colourList(): Promise<AdminColour[]> {
    return this.get('/api/admin/colours');
  }

  colourCreate(colour: AdminColour): Promise<AdminColour> {
    return this.post('/api/admin/colours', colour);
  }

  colourUpdate(colour: AdminColour): Promise<AdminColour> {
    return this.put(`/api/admin/colours/${colour.id}`, colour);
  }

  colourDelete(id: number): Promise<AdminColour> {
    return this.delete(`/api/admin/colours/${id}`);
  }

  customerList(): Promise<AdminCustomer[]> {
    return this.get('/api/admin/customers');
  }

  customerCreate(customer: AdminCustomer): Promise<AdminCustomer> {
    return this.post('/api/admin/customers', customer);
  }

  customerUpdate(customer: AdminCustomer): Promise<AdminCustomer> {
    return this.put(`/api/admin/customers/${customer.id}`, customer);
  }

  customerDelete(id: number): Promise<AdminCustomer> {
    return this.delete(`/api/admin/customers/${id}`);
  }

  brokerList(): Promise<AdminBroker[]> {
    return this.get('/api/admin/brokers');
  }

  brokerCreate(broker: AdminBroker): Promise<AdminBroker> {
    return this.post('/api/admin/brokers', broker);
  }

  brokerUpdate(broker: AdminBroker): Promise<AdminBroker> {
    return this.put(`/api/admin/brokers/${broker.id}`, broker);
  }

  brokerDelete(id: number): Promise<AdminBroker> {
    return this.delete(`/api/admin/brokers/${id}`);
  }

  salesTaxList(): Promise<AdminSalesTax[]> {
    return this.get('/api/admin/salesTaxes');
  }

  salesTaxCreate(tax: AdminSalesTax): Promise<AdminSalesTax> {
    return this.post('/api/admin/salesTaxes', tax);
  }

  salesTaxUpdate(tax: AdminSalesTax): Promise<AdminSalesTax> {
    return this.put(`/api/admin/salesTaxes/${tax.taxId}`, tax);
  }

  salesTaxDelete(id: string): Promise<AdminSalesTax> {
    return this.delete(`/api/admin/salesTaxes/${id}`);
  }

  varietyList(): Promise<AdminVariety[]> {
    return this.get('/api/admin/varieties');
  }

  varietyCreate(variety: AdminVariety): Promise<AdminVariety> {
    return this.post('/api/admin/varieties', variety);
  }

  varietyUpdate(variety: AdminVariety): Promise<AdminVariety> {
    return this.put(`/api/admin/varieties/${variety.id}`, variety);
  }

  varietyDelete(id: number): Promise<AdminVariety> {
    return this.delete(`/api/admin/varieties/${id}`);
  }

  priceInfo(): Promise<PriceListResponse> {
    return this.get('/api/admin/prices');
  }

  prices(brokerId: number): Promise<AdminPrice[]> {
    return this.get(`/api/admin/prices/${brokerId}`);
  }

  updatePrice(brokerId: number, colourId: number, price: number): Promise<AdminPrice> {
    const model = {colourId, price};
    return this.post(`/api/admin/prices/${brokerId}`, model);
  }
}

export interface PriceListResponse {
  brokers: AdminBroker[];
  colours: AdminColour[];
}

export const adminApi = new AdminService();
