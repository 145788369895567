import { createSlice, createAction, AsyncThunk, createAsyncThunk, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import * as models from 'api/models/growing-locations';
import * as wishListModels from 'api/models/wish-list';
import { growingLocationApi, WishListAllocationsResponse } from 'api/growing-location-service';
import { ProblemDetails } from 'utils/problem-details';
import { AllocationsMap } from './growing-location-allocations';
import { sortBy, sortSizeName } from 'utils/sort';
import { selectYear } from 'features/wish-list/wish-list-slice';

export interface AllocationsState {
  loading: boolean;
  error: ProblemDetails | null;
  locationId: number | null;
  weekId: number | null;
  startWeekId: number | null;
  endWeekId: number | null;
  weeks: models.Week[];
  locations: models.GrowingLocationListItem[];
  space: models.GrowingLocationWeekSpace[];
  available: wishListModels.WishListSpaceAvailable[];
  allocated: wishListModels.WishListSpaceAllocated[];
  unallocated: wishListModels.WishListSpaceAllocated[];
  noAvailability: models.WishListNoAvailabilityProductSize[];
}

const initialState: AllocationsState = {
  loading: false,
  error: null,
  locationId: null,
  weekId: null,
  startWeekId: null,
  endWeekId: null,
  weeks: [],
  locations: [],
  space: [],
  available: [],
  allocated: [],
  unallocated: [],
  noAvailability: []
};

export const getAllocations: AsyncThunk<WishListAllocationsResponse, number, {state: RootState}> = createAsyncThunk(
  'growing-locations/getAllocations',
  async (year, {rejectWithValue}) => {

    try {

      return await growingLocationApi.allocations(year);

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export interface GetAllocationsForLocationArgs {
  year: number;
  locationId: number;
}

export const getAllocationsForLocation: AsyncThunk<WishListAllocationsResponse, GetAllocationsForLocationArgs, {state: RootState}> = createAsyncThunk(
  'growing-locations/getAllocationsForLocation',
  async (args, {rejectWithValue}) => {

    try {

      const {locationId, year} = args;
      return await growingLocationApi.allocationsByLocation(locationId, year);

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getAllocationsPending = createAction(getAllocations.pending.type),
  getAllocationsFulfilled = createAction<WishListAllocationsResponse>(getAllocations.fulfilled.type),
  getAllocationsRejected = createAction<ProblemDetails>(getAllocations.rejected.type),
  getAllocationsForLocationPending = createAction(getAllocationsForLocation.pending.type),
  getAllocationsForLocationFulfilled = createAction<WishListAllocationsResponse>(getAllocationsForLocation.fulfilled.type),
  getAllocationsForLocationRejected = createAction<ProblemDetails>(getAllocationsForLocation.rejected.type);

export const growingLocationAllocationsSlice = createSlice({
  name: 'growing-locations/allocations',
  initialState,
  reducers: {
    clearState(state) {
      state.error = null;
      state.locationId = null;
      state.weekId = null;
      state.weeks = [];
      state.locations = [];
      state.space = [];
      state.available = [];
      state.allocated = [];
      state.unallocated = [];
      state.noAvailability = [];
    },
    setError(state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    },
    setLocationId(state, action: PayloadAction<number | null>) {
      state.locationId = action.payload;
    },
    setWeekId(state, action: PayloadAction<number | null>) {
      state.weekId = action.payload;
    },
    setStartWeekId(state, action: PayloadAction<number | null>) {
      state.startWeekId = action.payload;
    },
    setEndWeekId(state, action: PayloadAction<number | null>) {
      state.endWeekId = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(getAllocationsPending, state => {
        state.loading = true;
      })
      .addCase(getAllocationsFulfilled, (state, action) => {
        const {allocations, weeks, growingLocations} = action.payload;
        state.loading = false;
        state.weeks = weeks;
        state.locations = growingLocations;
        state.space = allocations.space;
        state.available = allocations.available;
        state.allocated = allocations.allocated;
        state.unallocated = allocations.unallocated;
        state.noAvailability = allocations.noAvailability;
      })
      .addCase(getAllocationsRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllocationsForLocationPending, state => {
        state.loading = true;
      })
      .addCase(getAllocationsForLocationFulfilled, (state, action) => {
        const {allocations, weeks, growingLocations} = action.payload;
        state.loading = false;
        state.weeks = weeks;
        state.locations = growingLocations;
        state.space = allocations.space;
        state.available = allocations.available;
        state.allocated = allocations.allocated;
        state.unallocated = allocations.unallocated;
        state.noAvailability = allocations.noAvailability;
      })
      .addCase(getAllocationsForLocationRejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
});

export const {clearState, setLocationId, setStartWeekId, setEndWeekId, setWeekId, setError} = growingLocationAllocationsSlice.actions;

export const selectError = (state: RootState) => state.allocations.error;
export const selectLoading = (state: RootState) => state.allocations.loading;
export const selectWeeks = (state: RootState) => state.allocations.weeks;
export const selectLocationId = (state: RootState) => state.allocations.locationId;
export const selectWeekId = (state: RootState) => state.allocations.weekId;
export const selectStartWeekId = (state: RootState) => state.allocations.startWeekId;
export const selectEndWeekId = (state: RootState) => state.allocations.endWeekId;
export const selectLocations = (state: RootState) => state.allocations.locations;
export const selectSpace = (state: RootState) => state.allocations.space;
export const selectAvailable = (state: RootState) => state.allocations.available;
export const selectAllocated = (state: RootState) => state.allocations.allocated;
export const selectUnallocated = (state: RootState) => state.allocations.unallocated;

export interface UnallocatedProductSize {
  wishListProductId: number;
  productId: number;
  productName: string;
  sizeId: number;
  sizeName: string;
  weeks: UnallocatedProductSizeWeek[];  
}

export interface UnallocatedProductSizeWeek {
  weekId: number;
  weekNumber: number;
  year: number;
  space: number;
  availableLocations: string[];
}

export const selectUnallocatedProductSizes = createSelector(
  selectUnallocated,
  selectStartWeekId,
  selectEndWeekId,
  selectLocationId,
  selectWeekId,
  selectAvailable,
  (allUnallocated, startWeekId, endWeekId, locationId, weekId, allAvailable) => {
    const filteredUnallocated = allUnallocated.filter(a => (!startWeekId || a.weekId >= startWeekId) && (!endWeekId || a.weekId <= endWeekId) && (!weekId || a.weekId === weekId)),
      available = allAvailable.filter(a => (!locationId || a.growingLocationId === locationId) && (!startWeekId || a.weekId >= startWeekId) && (!endWeekId || a.weekId <= endWeekId) && (!weekId || a.weekId === weekId)),
      unallocated = filteredUnallocated.filter(al => available.some(av => av.wishListProductId === al.wishListProductId && av.sizeId === al.sizeId))
        .reduce((memo, u) => {
          const existing = memo.find(a => a.productName === u.productName && a.sizeName === u.sizeName);
          if(existing) {
            const week = existing.weeks.find(w => w.weekId === u.weekId);
            if(week) {
              week.space += u.space;
            } else {
              const availableLocations = available.filter(a => a.weekId === u.weekId && a.wishListProductId === u.wishListProductId && a.sizeId === u.sizeId)
                .reduce((lMemo, l) => {
                  if(lMemo.indexOf(l.growingLocationName) === -1) {
                    lMemo.push(l.growingLocationName);
                  }
                  return lMemo;
                }, [] as string[]);
              existing.weeks.push({
                weekId: u.weekId,
                weekNumber: u.weekNumber,
                year: u.year,
                space: u.space,
                availableLocations
              });
            }          
          } else {
            const availableLocations = available.filter(a => a.weekId === u.weekId && a.wishListProductId === u.wishListProductId && a.sizeId === u.sizeId)
              .reduce((lMemo, l) => {
                if(lMemo.indexOf(l.growingLocationName) === -1) {
                  lMemo.push(l.growingLocationName);
                }
                return lMemo;
              }, [] as string[]);
            memo.push({
              wishListProductId: u.wishListProductId,
              productId: u.productId,
              productName: u.productName,
              sizeId: u.sizeId,
              sizeName: u.sizeName,
              weeks: [
                {
                  weekId: u.weekId,
                  weekNumber: u.weekNumber,
                  year: u.year,
                  space: u.space,
                  availableLocations
                }
              ]            
            });
          }

          return memo;
        }, [] as UnallocatedProductSize[])
        .sort((a, b) => {
          const productSort = sortByProductName(a, b);
          if(productSort) {
            return productSort;
          }

          return sortSizeName(a.sizeName, b.sizeName);
        });

      return unallocated;
  }
);

export interface NoAvailabilityProduct {
  wishListProductId: number;
  productId: number;
  productName: string;
  sizes: NoAvailabilityProductSize[];
}

export interface NoAvailabilityProductSize {
  sizeId: number;
  sizeName: string;
}

const sortByProductName = sortBy('productName'),
  sortBySizeName = sortBy('sizeName'),
  sortBySize = (a: NoAvailabilityProductSize, b: NoAvailabilityProductSize) => {
    const result = sortSizeName(a.sizeName, b.sizeName);
    if(result) {
      return result;
    }

    return sortBySizeName(a, b);
  };

export const selectNoAvailability = createSelector(
  (state: RootState) => state.allocations.noAvailability,
  noAvailability => {
    const products = noAvailability.reduce((memo, na) => {
      if(!memo.some(a => a.productId === na.productId)) {
        memo.push({wishListProductId: na.wishListProductId, productId: na.productId, productName: na.productName, sizes: []})
      }
      
      const product = memo.find(a => a.productId === na.productId);
      if(product && !product.sizes.some(s => s.sizeId === na.sizeId)) {
        product.sizes.push({sizeId: na.sizeId, sizeName: na.sizeName});
      }

      return memo;
    }, [] as NoAvailabilityProduct[])
    .sort(sortByProductName);

    products.forEach(p => p.sizes.sort(sortBySize));

    return products;
  }
);
export const selectLocation = createSelector(
  selectLocationId,
  selectLocations,
  (locationId, locations) => (locations || []).find(l => l.id === locationId) || null
);
export const selectStartWeek = createSelector(
  selectStartWeekId,
  selectYear,
  selectWeeks,
  (startWeekId, year, allWeeks) => {
    const weeks = allWeeks.filter(w => w.year === year),
      startWeek = weeks.find(w => w.id === startWeekId) || weeks[0];
    return startWeek;
  }
);
export const selectEndWeek = createSelector(
  selectEndWeekId,
  selectYear,
  selectWeeks,
  (endWeekId, year, allWeeks) => {
    const weeks = allWeeks.filter(w => w.year === year),
      endWeek = weeks.find(w => w.id === endWeekId) || weeks[weeks.length - 1];
    return endWeek;
  }
);

export interface AllocationItem {
  available: number;
  used: number;
  remaining: number;
}

export const selectAllocations = createSelector(
  selectLocations,
  selectWeeks,
  selectSpace,
  selectAllocated,
  selectUnallocated,
  selectAvailable,
  (locations, weeks, space, allocated, unallocated, available) => {
    const allocations = new AllocationsMap();
    locations?.forEach(location => {
      const spaceForLocation = space.filter(a => a.growingLocationId === location.id),
        allocatedForLocation = allocated.filter(a => a.growingLocationId === location.id),
        availableForLocation = available.filter(a => a.growingLocationId === location.id);
      weeks.forEach(week => {
        const spaceForWeek = spaceForLocation.filter(a => a.weekId === week.id),
          allocatedForWeek = allocatedForLocation.filter(a => a.weekId === week.id),
          unallocatedForWeek = unallocated.filter(a => a.weekId === week.id),
          availableForWeek = availableForLocation.filter(a => a.weekId === week.id);

        allocations.addSpace(spaceForWeek, week.id);
        allocations.addAllocations(allocatedForWeek, week.id);
        allocations.addUnallocated(availableForWeek, unallocatedForWeek, week.id)
      });
    })
    return allocations;
  }
);

export default growingLocationAllocationsSlice.reducer;
