import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { Collapse, Nav, Navbar, NavbarBrand, NavItem, NavLink, NavbarToggler } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { routes } from 'app/routes';
import { LoginMenu } from 'features/auth/LoginMenu';
import { selectAuthenticated, selectPermissions } from 'features/auth/auth-slice';
import { can } from 'utils/can';
import { selectYear as selectWishListYear } from 'features/wish-list/wish-list-slice';
import { selectYear as selectPropagationYear } from 'features/propagation/propagation-slice';

export function NavMenu() {
    const isInWishList = useRouteMatch(routes.wishList.path),
      isInGrowingLocations = useRouteMatch(routes.growingLocations.path),
      isInPropagationProducts = useRouteMatch(routes.propagation.routes.productList.path),
      isInPropagationSuppliers = useRouteMatch(routes.propagation.routes.supplierList.path),
      isInPropagationPurchaseOrders = useRouteMatch(routes.propagation.routes.purchaseOrderList.path),
      isInPropagationColours = useRouteMatch(routes.propagation.routes.gerberaColours.path),
      isInPropagation = isInPropagationProducts || isInPropagationSuppliers
        || isInPropagationPurchaseOrders || isInPropagationColours,
      [isOpen, setIsOpen] = useState(false),
      toggle = () => setIsOpen(!isOpen),
      authenticated = useSelector(selectAuthenticated),
      userPermissions = useSelector(selectPermissions),
      wishListYear = useSelector(selectWishListYear),
      propagationYear = useSelector(selectPropagationYear);
    
    return (
        <header>
            <Navbar color="dark" dark expand="md">
                <NavbarBrand to={routes.home.path} tag={Link}>
                    <img src='/img/logo.png' alt="Orchard Park Growers" />
                </NavbarBrand>      
                <NavbarToggler onClick={toggle}>
                    <FontAwesomeIcon icon={['fal', 'bars']} />
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar>
                    {authenticated &&
                    <Nav className="mr-auto" navbar>
                        <NavItem>
                            <NavLink to={routes.home.path} tag={Link}>
                                <FontAwesomeIcon icon={['fad', 'home']} />
                                &nbsp;
                                Home
                            </NavLink>
                        </NavItem>
                        {can(routes.billing.roles, userPermissions) &&
                        <NavItem>
                            <NavLink to={routes.billing.path} tag={Link}>
                                <FontAwesomeIcon icon={['fad', 'usd-square']} />
                                &nbsp;
                                Billing
                            </NavLink>
                        </NavItem>
                        }
                        <NavItem>
                            <NavLink to={routes.wishList.to(wishListYear)} tag={Link}>
                                <FontAwesomeIcon icon={['fad', 'wand-magic']} />
                                &nbsp;
                                Wish List
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to={routes.propagation.routes.productList.to(propagationYear)} tag={Link}>
                                <FontAwesomeIcon icon={['fal', 'seedling']} />
                                &nbsp;
                                Propagation
                            </NavLink>
                        </NavItem>
                        {can(routes.admin.roles, userPermissions) &&
                        <NavItem>
                            <NavLink to={routes.admin.path} tag={Link}>
                                <FontAwesomeIcon icon={['fad', 'cogs']} />
                                &nbsp;
                                Administration
                            </NavLink>
                        </NavItem>
                        }
                        <NavItem>
                            <NavLink to={routes.apps.path} tag={Link}>
                                <FontAwesomeIcon icon={['fal', 'desktop-alt']} />
                                &nbsp;
                                Apps
                            </NavLink>
                        </NavItem>
                    </Nav>
                    }
                    <LoginMenu />
                </Collapse>
            </Navbar>
            {(isInWishList || isInGrowingLocations) &&
            <Navbar color="default" dark expand="md" className="py-2 small">
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mx-auto" navbar>
                  <NavItem>
                    <NavLink to={routes.wishList.to(wishListYear)} tag={Link}>
                        <FontAwesomeIcon icon={['fad', 'wand-magic']} />
                        &nbsp;
                        Wish List Home
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to={routes.wishList.routes.admin.products.to(wishListYear)} tag={Link}>
                      <FontAwesomeIcon icon={['fad', 'flower-daffodil']} fixedWidth />
                      &nbsp;
                      Edit Products
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to={routes.growingLocations.to(wishListYear)} tag={Link}>
                      <FontAwesomeIcon icon={['fad', 'map-marked']} fixedWidth />
                      &nbsp;
                      Growing Locations
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to={routes.wishList.routes.allocations.to(wishListYear)} tag={Link}>
                      <FontAwesomeIcon icon={['fad', 'star-half']} fixedWidth />
                      &nbsp;
                      Allocations
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
            }
            {(isInPropagation) &&
            <Navbar color="default" dark expand="md" className="py-2 small">
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mx-auto" navbar>
                  <NavItem>
                    <NavLink to={routes.propagation.routes.productList.to(propagationYear)} tag={Link}>
                        <FontAwesomeIcon icon={['fad', 'seedling']} />
                        &nbsp;
                        Products
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to={routes.propagation.routes.purchaseOrderList.to(propagationYear)} tag={Link}>
                      <FontAwesomeIcon icon={['fal', 'file-powerpoint']} fixedWidth />
                      &nbsp;
                      Purchase Orders
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to={routes.propagation.routes.incomingPurchaseOrderList.to(propagationYear)} tag={Link}>
                      <FontAwesomeIcon icon={['fal', 'arrow-from-left']} fixedWidth />
                      &nbsp;
                      Incoming Orders
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to={routes.propagation.routes.gerberaColours.to(propagationYear)} tag={Link}>
                      <FontAwesomeIcon icon={['fad', 'paint-brush']} fixedWidth />
                      &nbsp;
                      Gerbera Colours
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to={routes.propagation.routes.supplierList.to(propagationYear)} tag={Link}>
                      <FontAwesomeIcon icon={['fad', 'warehouse-alt']} fixedWidth />
                      &nbsp;
                      Suppliers
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
            }
        </header>
    );
}
