import React, {useState, useEffect} from 'react';
import authService, {UserProfile} from 'features/auth/authorization-service';

export function Profile() {
  const [ready, setReady] = useState(false),
    [token, setToken] = useState(''),
    [user, setUser] = useState(null as UserProfile | null);

  useEffect(() => {
    const init = async () => {
      setReady(true);
      setToken(await authService.getAccessToken() || '');
      setUser(await authService.getUser() || null);
    };

    if(!ready) {
      init();
    }
  });

  return (
    <div>
      <h1>Profile</h1>

      {token &&
        <h2>Token: {token}</h2>
      }

      {user &&
        <>
        <h2>User: {user.name}</h2>
        <h3>Roles:</h3>
        <ul>
          {typeof user.role === 'string' &&
            <li>{user.role}</li>
          }
          {Array.isArray(user.role) &&
            user.role.map(r =>
              <li>{r}</li>  
            )
          }
        </ul>
        <h3>Permissions:</h3>
        <ul>
          {typeof user.permission === 'string' &&
            <li>{user.permission}</li>
          }
          {Array.isArray(user.permission) &&
            user.permission.map(p =>
              <li>{p}</li>  
            )
          }
        </ul>
        </>
      }
    </div>
  );
}
