import { createSlice, createAsyncThunk, PayloadAction, AsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { adminApi, PriceListResponse } from 'api/admin-service';
import { AdminPrice, AdminBroker, AdminColour } from 'api/models/admin';
import { ProblemDetails } from 'utils/problem-details';

export type PriceMap = {
  [index: number]: AdminPrice;
}

export type VarietyMap = {
  [index: string] : AdminColour[];
}

export interface PricingState {
  brokers: AdminBroker[];
  broker: AdminBroker | null;
  colours: VarietyMap;
  prices: PriceMap | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: PricingState = {
  brokers: [],
  broker: null,
  colours: { },
  prices: null,
  loading: false,
  error: null
};

export interface PriceUpdateArgs {
  brokerId: number;
  colourId: number;
  price: number;
}

export const getPriceInfo: AsyncThunk<PriceListResponse, void, {state: RootState}> = createAsyncThunk(
  'admin/getPriceInfo',
  async (_, {rejectWithValue}) => {
    try {
      return await adminApi.priceInfo();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const getPrices: AsyncThunk<AdminPrice[], number, {state: RootState}> = createAsyncThunk(
  'admin/getPrices',
  async (brokerId, {rejectWithValue}) => {
    try {
      return await adminApi.prices(brokerId);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updatePrice: AsyncThunk<AdminPrice, PriceUpdateArgs, {state: RootState}> = createAsyncThunk(
  'admin/updatePrice',
  async (args, {rejectWithValue}) => {
    try {
      const {brokerId, colourId, price} = args;
      return await adminApi.updatePrice(brokerId, colourId, price);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const priceSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ProblemDetails | null>) => {
      state.error = action.payload
    },
    clearError: (state) => {
      state.error = null;
    },
    setBroker: (state, action: PayloadAction<AdminBroker | null>) => {
      state.broker = action.payload;
    }
  },
  extraReducers: {
    [getPriceInfo.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getPriceInfo.fulfilled.type]: (state, action: PayloadAction<PriceListResponse>) => {
      const {brokers, colours} = action.payload;

      state.error = null;
      state.loading = false;
      
      state.brokers = brokers;
      state.colours = colours.reduce((memo, colour) => {
        if(colour.varietyType) {
          if(!memo[colour.varietyType]) {
            memo[colour.varietyType] = [];
          }
          memo[colour.varietyType].push(colour);
        }
        return memo;
      }, {} as VarietyMap);
    },
    [getPriceInfo.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getPrices.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [getPrices.fulfilled.type]: (state, action: PayloadAction<AdminPrice[]>) => {
      state.error = null;
      state.loading = false;
      state.prices = action.payload.reduce((memo, p) => {
        memo[p.colourId] = p;
        return memo;
      }, {} as PriceMap);
    },
    [getPrices.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updatePrice.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [updatePrice.fulfilled.type]: (state, action: PayloadAction<AdminPrice>) => {
      state.error = null;
      state.loading = false;
      if(state.prices) {
        const price = action.payload,
          prices = {...state.prices};

        prices[price.colourId] = price;

        state.prices = prices;
      }
    },
    [updatePrice.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setError, clearError, setBroker } = priceSlice.actions;

export const selectPriceState = (state: RootState) => state.prices;
export const selectBroker = (state: RootState) => state.prices.broker;

export default priceSlice.reducer;
