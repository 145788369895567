import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {NavLink } from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ApplicationPaths} from './auth-constants';
import {selectAuthenticated, selectUser} from './auth-slice';

export function LoginMenu() {
  const authenticated = useSelector(selectAuthenticated),
    user = useSelector(selectUser),
    profilePath = ApplicationPaths.Profile,
    logoutPath = {
      pathname: ApplicationPaths.LogOut,
      state: { local: true },
    };

  return (
    <Fragment>
      {authenticated &&
      <>
      <NavLink tag={Link} to={profilePath}>{user?.name || ''}</NavLink>
      <NavLink tag={Link} to={logoutPath}>
        <FontAwesomeIcon icon={['fad', 'sign-out']} />
        Logout
      </NavLink>
      </>
      }
    </Fragment>
  );
}
