import { createSlice, PayloadAction, createSelector, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from 'app/store';
import { billingApi, OrderListResponse } from 'api/billing-service';
import * as models from 'api/models/billing';
import { ProblemDetails } from 'utils/problem-details';
import { contains } from 'utils/equals';

export interface OrderListState {
  orders: models.BillingOrderListItem[] | null;
  year: number;
  page: number;
  search: string;
  customer: string;
  loading: boolean;
  error: ProblemDetails | null;
  emailAddress: string | null;
}

const initialState: OrderListState = {
  orders: null,
  year: new Date().getFullYear(),
  page: 1,
  search: '',
  customer: '',
  emailAddress: null,
  loading: false,
  error: null
};

const PageSize = 25;

export const getOrders: AsyncThunk<OrderListResponse, void, {state: RootState}> = createAsyncThunk(
  'billing/getOrders',
  async (_, {getState, rejectWithValue}) => {
    try {
      const year = getState().orderList.year;
      return await billingApi.getOrderList(year);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const orderListSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setYear(state, action: PayloadAction<number>) {
      state.year = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setCustomer(state, action: PayloadAction<string>) {
      state.customer = action.payload;
    },
    setError(state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    }
  },
  extraReducers: {
    [getOrders.pending.type]: (state) => {
      state.page = 1;
      state.error = null;
      state.loading = true;
    },
    [getOrders.fulfilled.type]: (state, action: PayloadAction<OrderListResponse>) => {
      state.error = null;      
      state.orders = action.payload.orders.sort((a, b) => {
        const a1 = a.shipDate ? moment(a.shipDate).toDate().valueOf() : Number.MAX_VALUE,
          a2 = b.shipDate ? moment(b.shipDate).toDate().valueOf() : Number.MAX_VALUE;

        return a2 - a1;
      });
      state.emailAddress = action.payload.emailAddress;
      state.loading = false;
    },
    [getOrders.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.orders = [];
      state.loading = false;
    }
  }
});

export const {setYear, setPage, setSearch, setCustomer, setError} = orderListSlice.actions;

export const selectPage = (state: RootState) => state.orderList.page;
export const selectOrders = (state: RootState) => state.orderList.orders;
export const selectSearch = (state: RootState) => state.orderList.search;
export const selectCustomer = (state: RootState) => state.orderList.customer;
export const selectEmailAddress = (state: RootState) => state.orderList.emailAddress;

export const selectFilteredOrders = createSelector(
  selectOrders,
  selectSearch,
  selectCustomer,
  (orders, search, customer) => orders?.filter(o => {
    if(customer && customer !== 'All' && customer !== o.customerName) {
      return false;
    }
    if(!search) {
      return true;
    }
    return contains(o.orderNumber, search) || contains(o.customerName, search) || contains(o.poNumber, search);
  }) || []
);

export const selectPagedOrders = createSelector(
  selectFilteredOrders,
  selectPage,
  (orders, page) => orders.slice(PageSize * (page-1), PageSize * page)
);

export const selectPages = createSelector(
  selectFilteredOrders,
  orders => {
    return Math.ceil((orders?.length || 0) / PageSize);
  }
);

export const selectCustomers = createSelector(
  selectOrders,
  orders => [{id: 'All', text: 'All Customers'}].concat(orders?.map(o => o.customerName).reduce((customers, c) => {
    if(customers.findIndex(o => o.id === c) === -1) {
      customers.push({id: c, text: c});
    }
    return customers;
  }, [] as {id: string, text: string}[]).sort() || [])
);

export default orderListSlice.reducer;
