import { createSlice, createAsyncThunk, createSelector, PayloadAction, AsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { adminApi } from 'api/admin-service';
import { AdminVariety } from 'api/models/admin';
import { ProblemDetails } from 'utils/problem-details';

export interface VarietyState {
  varieties: AdminVariety[] | null;
  loading: boolean;
  error: ProblemDetails | null;
}

const initialState: VarietyState = {
  varieties: null,
  loading: false,
  error: null
};

export const getVarieties: AsyncThunk<AdminVariety[], void, {state: RootState}> = createAsyncThunk(
  'admin/getVarieties',
  async (_, {rejectWithValue}) => {
    try {
      return await adminApi.varietyList();
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const createVariety: AsyncThunk<AdminVariety, AdminVariety, {state: RootState}> = createAsyncThunk(
  'admin/createVariety',
  async (variety, {rejectWithValue}) => {
    try {
      return await adminApi.varietyCreate(variety);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateVariety: AsyncThunk<AdminVariety, AdminVariety, {state: RootState}> = createAsyncThunk(
  'admin/updateVariety',
  async (variety, {rejectWithValue}) => {
    try {
      return await adminApi.varietyUpdate(variety);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteVariety: AsyncThunk<AdminVariety, number, {state: RootState}> = createAsyncThunk(
  'admin/deleteVariety',
  async (id, {rejectWithValue}) => {
    try {
      return await adminApi.varietyDelete(id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const varietySlice = createSlice({
  name: 'varieties',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<ProblemDetails | null>) {
      state.error = action.payload;
    }
  },
  extraReducers: {
    [getVarieties.pending.type]: (state) => {
      state.varieties = [];
      state.error = null;
      state.loading = true;
    },
    [getVarieties.fulfilled.type]: (state, action: PayloadAction<AdminVariety[]>) => {
      state.varieties = action.payload;
      state.error = null;
      state.loading = false;
    },
    [getVarieties.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [createVariety.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [createVariety.fulfilled.type]: (state, action: PayloadAction<AdminVariety>) => {
      state.error = null;
      state.loading = false;
      if(state.varieties) {
        state.varieties.push(action.payload);
      }
    },
    [createVariety.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateVariety.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [updateVariety.fulfilled.type]: (state, action: PayloadAction<AdminVariety>) => {
      state.error = null;
      state.loading = false;

      if(state.varieties) {
        const variety = action.payload,
          varieties = state.varieties.slice(),
          index = varieties.findIndex(c => c.id === variety.id);

        if(index !== -1) {
          varieties.splice(index, 1, variety);
        }

        state.varieties = varieties;
      }
    },
    [updateVariety.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteVariety.pending.type]: (state) => {
      state.error = null;
      state.loading = true;
    },
    [deleteVariety.fulfilled.type]: (state, action: PayloadAction<AdminVariety>) => {
      state.error = null;
      state.loading = false;

      if(state.varieties) {
        const variety = action.payload,
          varieties = state.varieties.slice(),
          index = varieties.findIndex(c => c.id === variety.id);

        if(index !== -1) {
          varieties.splice(index, 1);
        }

        state.varieties = varieties;
      }
    },
    [deleteVariety.rejected.type]: (state, action: PayloadAction<ProblemDetails>) => {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const { setError } = varietySlice.actions;

export const selectVarieties = (state: RootState) => state.varieties.varieties;
export const selectLoading = (state: RootState) => state.varieties.loading;
export const selectError = (state: RootState) => state.varieties.error;

export const selectSortedVarieties = createSelector(
  selectVarieties,
  varieties => varieties?.slice().sort((a, b) => a.name.localeCompare(b.name)) || null
);

export default varietySlice.reducer;
