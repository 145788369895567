import moment from 'moment';
import numeral from 'numeral';

export function formatDate(date:any, format = 'MMM D, YYYY'): string {
  if(date == null) {
    return '';
  }

  const m = moment(date);

  if(!m.isValid()) {
    return '';
  }

  return m.format(format);
}

export function formatNumber(value: any, format = '0,0'): string {
  if(value == null) {
    return '0';
  }

  return numeral(value).format(format);
}

export function formatCurrency(value: any, format = '$0,0.00'): string {
  if(value == null) {
    return '$0.00';
  }

  return numeral(value).format(format);
}
