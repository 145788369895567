import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Redirect, RouteProps} from 'react-router-dom'
import {setAuthenticated, selectPermissions} from './auth-slice';
import {ApplicationPaths, QueryParameterNames} from './auth-constants';
import authService from 'features/auth/authorization-service';

export interface AuthorizeRouteProps extends RouteProps {
  roles?: string[];
}

let _subscription = -1;

export function AuthorizeRoute(props: AuthorizeRouteProps) {
  const [ready, setReady] = useState(false),
    [isAuthenticated, setIsAuthenticated] = useState(false),
    dispatch = useDispatch(),
    permissions = useSelector(selectPermissions),
    populateAuthenticationState = useCallback(async () => {
      const user = await authService.getUser(true) || null;
      await dispatch(setAuthenticated({user}));
      setIsAuthenticated(!!user);
      setReady(true);
    }, [dispatch]);

  _subscription = authService.subscribe(populateAuthenticationState);

  useEffect(() => {
    const intialize = async () => await populateAuthenticationState();

    intialize();

    return () => {
      if(_subscription > -1) {
        authService.unsubscribe(_subscription);
      }
    }
  }, [populateAuthenticationState]);

  if(Array.isArray(props.roles)) {
    if(!permissions.some(p => props.roles?.indexOf(p) !== -1)) {
      return <></>;
    }
  }

  const link = document.createElement('a'),
    {path} = props;

  if(path && typeof path === 'string') {
    link.href = path;
  }

  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`,
      redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`,
      { component: Component, ...rest } = props;

  if(!ready) {
    return <div />
  }

  return <Route {...rest}
      render={(props) => {
          if (isAuthenticated) {
              // @ts-ignore
              return <Component {...props} />
          } else {
              return <Redirect to={redirectUrl} />
          }
      }} />
}
