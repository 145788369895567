import * as models from 'api/models/wish-list';

export function isPlantWeek(size: models.WishListProductSize, weekId: number) {
  return size.plantWeekId === weekId;
}

export function isSpaceWeek(size: models.WishListProductSize, weekId: number) {
  const plantWeekId = size.plantWeekId,
    spaceWeekId = plantWeekId + size.weeksTightlySpaced;

  return weekId === spaceWeekId;
}

export function isTightlySpaced(size: models.WishListProductSize, weekId: number) {
  const plantWeekId = size.plantWeekId,
    spaceWeekId = plantWeekId + size.weeksTightlySpaced;

  return weekId >= plantWeekId && weekId < spaceWeekId;
}

export function isProductionSpaced(size: models.WishListProductSize, weekId: number) {
  const spaceWeekId = size.plantWeekId + size.weeksTightlySpaced,
    lastWeekId = size.sales.reduce((max, s) => Math.max(max, s.weekId), size.finishWeekId || 0);

  return weekId >= spaceWeekId && weekId < lastWeekId;
}

export function squareFeet(size: models.WishListProductSize, weekId: number) {
  const wasHalfPlanted = size.isSkipWeek && isPlantWeek(size, weekId),
    wasHalfSpaced = size.isSkipWeek && isSpaceWeek(size, weekId),
    // NOTE: we don't count the space until the next week
    isTight = isTightlySpaced(size, weekId - 1),
    isSpaced = isProductionSpaced(size, weekId - 1),
    quantity = size.colours.reduce((total, c) => total + c.wishListQuantity, 0),
    sold = quantitySoldBy(size, weekId),
    quantityRemaining = Math.max(quantity - sold, 0),
    tightDivisor = wasHalfPlanted || wasHalfSpaced ? 2 : 1,
    tightQuantity = (isTight || wasHalfPlanted) ? Math.round(quantityRemaining / tightDivisor) : 0,
    spacedQuantity = (isSpaced || wasHalfSpaced) ? Math.round(quantityRemaining - tightQuantity) : 0,
    tightSpace = size.potsPerSquareFootTight === 0 ? 0 : Math.ceil(tightQuantity / size.potsPerSquareFootTight),
    spacedSpace = size.potsPerSquareFootSpaced === 0 ? 0 : Math.ceil(spacedQuantity / size.potsPerSquareFootSpaced),
    squareFeet = tightSpace + spacedSpace;

  return squareFeet;
}

export function quantity(size: models.WishListProductSize, weekId: number) {
  const quantity = size.colours.reduce((total, c) => total + c.wishListQuantity, 0),
    sold = quantitySoldBy(size, weekId),
    quantityRemaining = Math.max(quantity - sold, 0);

  return quantityRemaining;
}

function quantitySoldBy(size: models.WishListProductSize, weekId: number) {
  const percentSoldBy = size.sales.filter(s => s.weekId <= weekId).reduce((percent, s) => percent + (s.percentSold || 0), 0),
    quantity = size.colours.reduce((total, c) => total + c.wishListQuantity, 0),
    quantitySold = Math.round(quantity * percentSoldBy / 100);

  return quantitySold;
}
