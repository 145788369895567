import { createSlice, createAction,createAsyncThunk, AsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { GrowingLocation } from 'api/models/growing-locations';
import { ProblemDetails } from 'utils/problem-details';
import { RootState } from 'app/store';
import { growingLocationApi, GrowingLocationResponse } from 'api/growing-location-service';
import { sortBy } from 'utils/sort';

interface GrowingLocationState {
  location: GrowingLocation | null;
  saving: boolean;
  error: ProblemDetails | null;
}

const initialState: GrowingLocationState = {
  location: null,
  saving: false,
  error: null
};

export const saveGrowingLocation: AsyncThunk<GrowingLocationResponse | undefined, void, {state: RootState}> = createAsyncThunk(
  'growing-locations/saveGrowingLocation',
  async (_, {getState, rejectWithValue}) => {
    try {
      const state = getState(),
        year = state.wishList.year,
        location = state.growingLocationEditDetail.location;
        
      if(location?.id) {
        return await growingLocationApi.update(location.id, year, location.name, location.isHangingLocation, location.isActive, location.zones);
      } else if(location) {
        return await growingLocationApi.create(year, location.name, location.isHangingLocation, location.isActive, location.zones);
      }
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteGrowingLocation: AsyncThunk<number, number, {state: RootState}> = createAsyncThunk(
  'growing-locations/deleteGrowingLocation',
  async (id, {rejectWithValue}) => {
    try {
      await growingLocationApi.deleteGrowingLocation(id);
      return id;
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

interface SetZoneArgs<T> {
  zoneId: number;
  value: T;
}

const updateGrowingLocationPending = createAction(saveGrowingLocation.pending.type),
  updateGrowingLocationFulfilled = createAction<GrowingLocationResponse | undefined>(saveGrowingLocation.fulfilled.type),
  updateGrowingLocationRejected = createAction<ProblemDetails>(saveGrowingLocation.rejected.type),
  deleteGrowingLocationPending = createAction(deleteGrowingLocation.pending.type),
  deleteGrowingLocationFulfilled = createAction<number>(deleteGrowingLocation.fulfilled.type),
  deleteGrowingLocationRejected = createAction<ProblemDetails>(deleteGrowingLocation.rejected.type);

  export const growingLocationEditDetailSlice = createSlice({
    name: 'growing-location-edit-detail',
    initialState,
    reducers: {
      setError(state, action: PayloadAction<ProblemDetails | null>) {
        state.error = action.payload;
      },
      setLocation(state, action: PayloadAction<GrowingLocation | null>) {
        if(action.payload) {
          const location = {...action.payload};
          location.zones = [...location.zones].sort(sortBy('name'));
          
          state.location = location;
        } else {
          state.location = null;
        }
        
      },
      setName(state, action: PayloadAction<string>) {
        const location = state.location;
        if(location) {
          location.name = action.payload;
          state.location = location;
        }
      },
      setIsActive(state, action: PayloadAction<boolean>) {
        const location = state.location;
        if(location) {
          location.isActive = action.payload;
          state.location = location;
        }
      },
      setIsHangingLocation(state, action: PayloadAction<boolean>) {
        const location = state.location;
        if(location) {
          location.isHangingLocation = action.payload;
          state.location = location;
        }
      },
      setZoneName(state, action: PayloadAction<SetZoneArgs<string>>) {
        const location = state.location;
        if(location) {
          const {zoneId, value} = action.payload,
            index = location.zones.findIndex(z => z.id === zoneId);

          if(index !== -1) {
            const zone = {...location.zones[index], name: value};
            location.zones.splice(index, 1, zone);            
          }

          state.location = location;
        }
      },
      setZoneIsAvailableByDefault(state, action: PayloadAction<SetZoneArgs<boolean>>) {
        const location = state.location;
        if(location) {
          const {zoneId, value} = action.payload,
            index = location.zones.findIndex(z => z.id === zoneId);

          if(index !== -1) {
            const zone = {...location.zones[index], isAvailableByDefault: value};
            location.zones.splice(index, 1, zone);            
          }

          state.location = location;
        }
      },
      setZoneSquareFeet(state, action: PayloadAction<SetZoneArgs<number>>) {
        const location = state.location;
        if(location) {
          const {zoneId, value} = action.payload,
            index = location.zones.findIndex(z => z.id === zoneId);

          if(index !== -1) {
            const zone = {...location.zones[index], squareFeet: value};
            location.zones.splice(index, 1, zone);            
          }

          state.location = location;
        }
      },
      setZoneNotes(state, action: PayloadAction<SetZoneArgs<string | null>>) {
        const location = state.location;
        if(location) {
          const {zoneId, value} = action.payload,
            index = location.zones.findIndex(z => z.id === zoneId);

          if(index !== -1) {
            const zone = {...location.zones[index], notes: value};
            location.zones.splice(index, 1, zone);            
          }

          state.location = location;
        }
      },
      addZone(state) {
        const location = state.location;
        if(location) {
          const zones = location.zones.slice(),
            id = zones.reduce((min, zone) => Math.min(min, zone.id), 0) - 1;
          zones.push({id, growingLocationId: location.id, name: '', isAvailableByDefault: true, squareFeet: 0, notes: null, weeks: []});
          location.zones = zones;
          state.location = location;
        }
      },
      removeZone(state, action: PayloadAction<number>) {
        const location = state.location;
        if(location) {
          const id = action.payload,
            zones = location.zones.slice(),
            index = zones.findIndex(z => z.id === id);
    
          if(index !== -1) {
            zones.splice(index, 1);
          }

          location.zones = zones;
          state.location = location;
        }
      }
    },
    extraReducers: builder => {
      builder
        .addCase(updateGrowingLocationPending, state => {
          state.saving = true;
          state.error = null
        })
        .addCase(updateGrowingLocationFulfilled, (state, action) => {
          if(action.payload) {
            state.saving = false;
            state.location = null;
          }
        })
        .addCase(updateGrowingLocationRejected, (state, action) => {
          state.saving = false;
          state.error = action.payload;
        })
        .addCase(deleteGrowingLocationPending, state => {
          state.saving = true;
          state.error = null
        })
        .addCase(deleteGrowingLocationFulfilled, state => {
          state.saving = false;
          state.location = null;
        })
        .addCase(deleteGrowingLocationRejected, (state, action) => {
          state.saving = false;
          state.error = action.payload;
        })
    }
  });
  
  export const { setError, setLocation, setName, setIsActive, setIsHangingLocation, addZone, removeZone,
    setZoneName, setZoneIsAvailableByDefault, setZoneSquareFeet, setZoneNotes } = growingLocationEditDetailSlice.actions;
  
  export const selectLocation = (state: RootState) => state.growingLocationEditDetail.location;
  export const selectSaving = (state: RootState) => state.growingLocationEditDetail.saving;
  export const selectError = (state: RootState) => state.growingLocationEditDetail.error;
  
  export default growingLocationEditDetailSlice.reducer;
