import { createSlice, PayloadAction, createAsyncThunk, AsyncThunk, createSelector, createAction } from '@reduxjs/toolkit';
import { ProblemDetails } from 'utils/problem-details';
import { RootState } from 'app/store';
import * as models from 'api/models/wish-list';
import { wishListApi, WishListProductUpdateReponse, WishListProductDetailResponse,
  WishListProductSizeSalesWeekUpdate, WishListPriority, WishListProductPrioritiesResponse } from 'api/wish-list-service';
import { sortBy, sortSizeName } from 'utils/sort';
import { refreshSeasonalAllocations } from './seasonal/seasonal-wish-list';
import { createProblemDetails } from 'utils/problem-details';

export interface WishListProductDetailState {
  product: models.WishListProduct | null;
  sizeId: number | null;
  spaceAvailable: models.WishListSpaceAvailable[];
  spaceAllocated: models.WishListSpaceAllocated[];
  saving: boolean;
  saved: boolean;
  dirty: boolean;
  error: ProblemDetails | null;
}

const initialState: WishListProductDetailState = {
  product: null,
  sizeId: null,
  spaceAvailable: [],
  spaceAllocated: [],
  saving: false,
  saved: false,
  dirty: false,
  error: null
};

export interface UpdateProductArgs {
  product: models.WishListProduct;
}

export const updateProduct: AsyncThunk<WishListProductUpdateReponse, UpdateProductArgs, {state: RootState}> = createAsyncThunk(
  'wish-list/updateProduct',
  async (args, {rejectWithValue, getState}) => {
    try {
      const rootState = getState(),
        {product} = args,
        wishListProductId = product.id,
        useRatiosBySize = product.useRatiosBySize,
        sizes = product.sizes.map(size => ({id: size.id, sizeId: size.sizeId, potsPerSquareFootTight: size.potsPerSquareFootTight,
          potsPerSquareFootSpaced: size.potsPerSquareFootSpaced, plantWeekId: size.plantWeekId, weeksTightlySpaced: size.weeksTightlySpaced,
          salesStartWeekId: size.salesStartWeekId, salesEndWeekId: size.salesEndWeekId,
          defaultTightLocationId: size.defaultTightLocationId, defaultSpacedLocationId: size.defaultSpacedLocationId, productionWeeks: size.productionWeeks,
          roundNumber: size.roundNumber, roundName: size.roundName, finishWeekId: size.finishWeekId, estimatedShrinkPercentage: size.estimatedShrinkPercentage,
          isSkipWeek: size.isSkipWeek, precedesSkipWeek: size.precedesSkipWeek, succeedsSkipWeek: size.succeedsSkipWeek, isGainWeek: size.isGainWeek,
          precedesGainWeek: size.precedesGainWeek, succeedsGainWeek: size.succeedsGainWeek, 
          colours: size.colours.map(c => ({id: c.id, colourId: c.colourId, wishListQuantity: c.wishListQuantity, colourRatioPercentage: c.colourRatioPercentage}))})),        
        allocations = rootState.wishListProductDetail.spaceAllocated
          .filter(a => a.wishListProductId === wishListProductId && !!a.space)
            .map(a => ({weekId: a.weekId, wishListProductSizeId: a.wishListProductSizeId, growingLocationId: a.growingLocationId, squareFeet: a.space})),
        sales = product.sizes.reduce((memo, size) => 
              memo.concat(size.sales.map(allocation => ({wishListProductSizeId: size.id,  weekId: allocation.weekId, percentSold: allocation.percentSold})))
            ,[] as WishListProductSizeSalesWeekUpdate[]),
        sizeColourRatios = product.sizeColourRatios.map(r => ({sizeId: r.sizeId, colourId: r.colourId, percentage: r.percentage}));

      return await wishListApi.productUpdate(wishListProductId, useRatiosBySize, sizes, allocations, sales, sizeColourRatios);
      
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export interface GetProductDetailArgs {
  id: number;
  revision: models.WishListRevision | null;
}

export const getProductDetail: AsyncThunk<WishListProductDetailResponse, GetProductDetailArgs, {state: RootState}> = createAsyncThunk(
  'wish-list-detail/getProductDetail',
  async (args, {rejectWithValue}) => {
    try {
        const {id, revision} = args
        return await wishListApi.getProductDetail(id, revision?.id);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

interface UpdatePrioritiesPayload {
  response: WishListProductPrioritiesResponse;
  weeks: models.Week[];
}

export const updatePriorities: AsyncThunk<UpdatePrioritiesPayload, WishListPriority[], {state: RootState}> = createAsyncThunk(
  'wish-list-seasonal/updatePriorities',
  async (priorities, {getState, rejectWithValue}) => {
    try {

      const rootState = getState(),
        {product} = rootState.wishListProductDetail,
        weeks = rootState.wishListHome.weeks || [];

      if(!product) {
        return rejectWithValue(createProblemDetails('Please choose a product'));
      }

      const response = await wishListApi.updatePriorities(product.id, priorities);

      return {response, weeks};

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const updateProductPending = createAction(updateProduct.pending.type),
  updateProductFulfilled = createAction<WishListProductUpdateReponse>(updateProduct.fulfilled.type),
  updateProductRejected = createAction<ProblemDetails>(updateProduct.rejected.type),
  getProductDetailPending = createAction(getProductDetail.pending.type),
  getProductDetailFulfilled = createAction<WishListProductDetailResponse>(getProductDetail.fulfilled.type),
  getProductDetailRejected = createAction<ProblemDetails>(getProductDetail.rejected.type),
  updatePrioritiesFulfilled = createAction<UpdatePrioritiesPayload>(updatePriorities.fulfilled.type),
  updatePrioritiesRejected = createAction<ProblemDetails>(updatePriorities.rejected.type);

export const detailSlice = createSlice({
  name: 'wish-list-detail',
  initialState,
  reducers: {    
    setSizeId(state, action: PayloadAction<number>) {
      state.sizeId = action.payload;
    },
    clearError(state) {
      state.error = null;
    },
    clearState(state) {
      state.product = null;
      state.sizeId = null;
      state.dirty = false;
    },
    setSaving(state, action: PayloadAction<boolean>) {
      state.saving = action.payload;
    },
    setSaved(state, action: PayloadAction<boolean>) {
      state.saved = action.payload;
    },
    setDirty(state, action: PayloadAction<boolean>) {
      state.dirty = action.payload;
    },
    setSpaceAllocated(state, action: PayloadAction<models.WishListSpaceAllocated[]>) {
      state.spaceAllocated = action.payload;
    },
    setSpaceAvailable(state, action: PayloadAction<models.WishListSpaceAvailable[]>) {
      state.spaceAvailable = action.payload;
    },
    addRound(state, action: PayloadAction<models.WishListProductSize>) {
      const product = state.product,
        size = action.payload;

      state.dirty = true;

      if(product) {
        const sizes = product.sizes.slice(),
          id = sizes.reduce((min, s) => Math.min(min, s.id), 0) - 1,
          roundNumber = sizes.reduce((max, s) => s.sizeId === size.sizeId ? Math.max(max, s.roundNumber) : max, 1) + 1,
          roundName = size.roundName || `R${roundNumber}`,
          newSize = {...size, id, roundNumber, roundName },
          index = sizes.findIndex(s => s.id === size.id) + 1;

        newSize.colours = newSize.colours.map((c, idx) => {
          const id = -idx,
            wishListQuantity = 0;

          return {...c, id, wishListQuantity};
        });

        sizes.splice(index, 0, newSize);
        product.sizes = sizes;
        
        state.product = product;
      }
    },
    renameRound(state, action: PayloadAction<string>) {
      const {product, sizeId} = state,
        value = action.payload,
        sizes = product?.sizes.slice() || [],
        index = sizes.findIndex(s => s.id === sizeId);

      state.dirty = true;

      if(product && index !== -1) {
        const size = {...sizes[index], roundName: value};
        sizes.splice(index, 1, size);

        product.sizes = sizes;
      
        state.product = product;
      }
    },
    decrementRoundNumber(state, action: PayloadAction<models.Week[]>) {
      const {product, sizeId} = state,
        weeks = action.payload,
        sizes = product?.sizes.map(s => ({...s})) || [],
        index = sizes.findIndex(s => s.id === sizeId),
        prevIndex = index - 1;

      state.dirty = true;

      if(product && index !== -1) {
        const size = {...sizes[index]};
        size.roundNumber--;
        sizes.splice(index, 1, size);

        const prevSize = sizes[prevIndex];
        if(prevSize) {
          const size = {...prevSize};
          size.roundNumber++;
          sizes.splice(prevIndex, 1, size);
        }

        sizes.sort(sortSize);

        sizes.forEach((s, index) => {
          s.roundNumber = index + 1;
        });

        product.sizes = sizes;
      
        state.product = product;

        state.spaceAllocated = refreshSeasonalAllocations(product, state.spaceAvailable, state.spaceAllocated, weeks);
      }
    },
    incrementRoundNumber(state, action: PayloadAction<models.Week[]>) {
      const {product, sizeId} = state,
        weeks = action.payload,
        sizes = product?.sizes.map(s => ({...s})) || [],
        index = sizes.findIndex(s => s.id === sizeId),
        nextIndex = index + 1;

      state.dirty = true;

      if(product && index !== -1) {
        const size = {...sizes[index]};
        size.roundNumber++;
        sizes.splice(index, 1, size);

        const nextSize = sizes[nextIndex];
        if(nextSize) {
          const size = {...nextSize};
          size.roundNumber--;
          sizes.splice(nextIndex, 1, size);
        }

        sizes.sort(sortSize);

        sizes.forEach((s, index) => {
          s.roundNumber = index + 1;
        });

        product.sizes = sizes;
      
        state.product = product;
        state.spaceAllocated = refreshSeasonalAllocations(product, state.spaceAvailable, state.spaceAllocated, weeks);
      }
    },
    removeRound(state, action: PayloadAction<{weeks: models.Week[], size: models.WishListProductSize}>) {
      const product = state.product,
        {size, weeks} = action.payload;

      state.dirty = true;

      if(product) {
        const sizes = product.sizes.slice(),
          index = sizes.findIndex(s => s.id === size.id);

        if(index !== -1) {
          sizes.splice(index, 1);
        }

        product.sizes = sizes;
        
        state.product = product;
        state.spaceAllocated = refreshSeasonalAllocations(product, state.spaceAvailable, state.spaceAllocated, weeks);
      }
    },
    setProduct(state, action: PayloadAction<models.WishListProduct>) {
      state.product = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(updateProductPending, state => {
        state.saving = true;
      })
      .addCase(updateProductFulfilled, (state, action) => {
        const {product} = action.payload,
          previousYear = state.product?.previousYear || [];
        product.previousYear = previousYear;
        state.product = product;
        state.saving = false;
        state.saved = true;
        state.dirty = false;
      })
      .addCase(updateProductRejected, (state, action) => {
        state.error = action.payload;
        state.saving = false;
      })
      .addCase(getProductDetailPending, state => {
        state.saving = true;
      })
      .addCase(getProductDetailFulfilled, (state, action) => {        
        const {product, spaceAllocated, spaceAvailable} = action.payload,
          {sizeId} = state;

        state.saving = false;

        product.sizes.sort(sortSize);
        product.sizes.forEach((s, index) => s.roundNumber = index + 1);
  
        state.product = product;
        state.spaceAllocated = spaceAllocated;
        state.spaceAvailable = spaceAvailable;

        if(!sizeId || (product.sizes.find(s => s.id === sizeId))) {
          state.sizeId = product.sizes[0]?.id || null;
        }
      })
      .addCase(getProductDetailRejected, (state, action) => {
        state.saving = false;
        state.error = action.payload;
      })
      .addCase(updatePrioritiesFulfilled, (state, action) => {
        const {response, weeks} = action.payload;
        state.spaceAvailable = response.available;
        const {product, spaceAvailable, spaceAllocated} = state;

        if(product) {
          state.spaceAllocated = refreshSeasonalAllocations(product, spaceAvailable, spaceAllocated, weeks);
        }
      })
      .addCase(updatePrioritiesRejected, (state, action) => {
        state.error = action.payload;
      })
});

export const { setSizeId, clearError, clearState, setSaved, setDirty, setSpaceAllocated, setSpaceAvailable,
  addRound, renameRound, incrementRoundNumber, decrementRoundNumber, removeRound, setProduct } = detailSlice.actions;

export const selectError = (state: RootState) => state.wishListProductDetail.error;
export const selectProduct = (state: RootState) => state.wishListProductDetail.product;
export const selectSizes = (state: RootState) => state.wishListProductDetail.product?.sizes.slice().sort(sortSize) || [];
export const selectSaving = (state: RootState) => state.wishListProductDetail.saving;
export const selectSaved = (state: RootState) => state.wishListProductDetail.saved;
export const selectDirty = (state: RootState) => state.wishListProductDetail.dirty;

export const selectSize = createSelector(
  selectProduct,
  (state: RootState) => state.wishListProductDetail.sizeId,
  (product, sizeId) => {
    return product?.sizes.find(s => s.id === sizeId) || null;
  }
);

export default detailSlice.reducer;

export function sortSize(a: models.WishListProductSize, b: models.WishListProductSize) {
  const result = sortBy('roundNumber')(a, b);
  if(result !== 0) return result;

  return sortSizeName(a.sizeName, b.sizeName);
}
