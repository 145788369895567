import { createSlice, createAsyncThunk, AsyncThunk, createAction, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { createProblemDetails, ProblemDetails } from 'utils/problem-details';
import { RootState } from 'app/store';
import * as models from 'api/models/wish-list';
import { CreateColourResponse, CreateSizeResponse, wishListApi, WishListProductAdminCreateResponse, WishListProductAdminResponse, WishListProductAdminUpdateResponse } from 'api/wish-list-service';
import { sortBy, sortSizeName } from 'utils/sort';

export interface WishListProductDetailState {
  product: ProductDetailProduct | null;
  error: ProblemDetails | null;
  saving: boolean;
  sizes: models.Size[];
  colours: models.WishListColour[];
}

const initialState: WishListProductDetailState = {
  product: null,
  error: null,
  saving: false,
  sizes: [],
  colours: []
};

export interface ProductDetailProduct {
  id: number;
  productId: number
  name: string;
  wishListDisplayName: string | null;
  useRatios: boolean;
  useRatiosBySize: boolean;
  isWeekly: boolean;
  sizes: ProductDetailSize[];
  colours: models.WishListProductColourRatio[];
}

export interface ProductDetailSize {
  id: number;
  sizeId: number;
  sizeName: string;
  potsPerCase: number;
}

export interface GetProductDetailArgs {
  id: number;
  revision: models.WishListRevision | null;
}

export const getProductDetail: AsyncThunk<WishListProductAdminResponse, GetProductDetailArgs, {state: RootState}> = createAsyncThunk(
  'wish-list-product-admin/getProductDetail',
  async (args, {rejectWithValue}) => {
    try {
        const {id, revision} = args
        return await wishListApi.productAdminDetail(id, revision?.id || null);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const updateWishListProductAdmin: AsyncThunk<WishListProductAdminUpdateResponse, void, {state: RootState}> = createAsyncThunk(
  'wish-list-product-admin/updateWishListProductAdmin',
  async (_, {rejectWithValue, getState}) => {
    try {
      const {product} = getState().wishListProductDetailAdmin;

      if(!product) {
        return rejectWithValue(createProblemDetails('Product is missing'));
      }
      
      const {id, wishListDisplayName, useRatios, useRatiosBySize, isWeekly} = product,
        sizes = product.sizes.map(s => ({id: s.id, sizeId: s.sizeId, potsPerCase: s.potsPerCase})),
        colours = product.colours.map(c => ({colourId: c.colourId, percentage: c.percentage}));

      return await wishListApi.wishListProductAdminUpdate(id, wishListDisplayName, useRatios, useRatiosBySize, isWeekly, sizes, colours);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const createSize: AsyncThunk<CreateSizeResponse, string, {state: RootState}> = createAsyncThunk(
  'wish-list-product-admin/createSize',
  async (name, {rejectWithValue}) => {
    try {
      return await wishListApi.createSize(name);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteSize: AsyncThunk<number, number, {state: RootState}> = createAsyncThunk(
  'wish-list-product-admin/deleteSize',
  async (id, {rejectWithValue}) => {
    try {
      await wishListApi.deleteSize(id);
      return id;
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const createColour: AsyncThunk<CreateColourResponse, string, {state: RootState}> = createAsyncThunk(
  'wish-list-product-admin/createColour',
  async (name, {rejectWithValue}) => {
    try {
      return await wishListApi.createColour(name);
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteColour: AsyncThunk<number, number, {state: RootState}> = createAsyncThunk(
  'wish-list-product-admin/deleteColour',
  async (id, {rejectWithValue}) => {
    try {
      await wishListApi.deleteColour(id);
      return id;
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export const deleteWishListProduct: AsyncThunk<number, number, {state: RootState}> = createAsyncThunk(
  'wish-list/deleteWishListProduct',
  async (id, {rejectWithValue}) => {
    try {

      await wishListApi.deleteWishListProduct(id);

      return id;
      
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

export interface CreateWishListProductArgs {
  productId: number;
  wishListDisplayName: string | null;
}

export const createWishListProduct: AsyncThunk<WishListProductAdminCreateResponse, CreateWishListProductArgs, {state: RootState}> = createAsyncThunk(
  'wish-list/createWishListProduct',
  async (args, {rejectWithValue, getState}) => {
    try {

      const year = getState().wishList.year,
        {productId, wishListDisplayName} = args;

      return await wishListApi.wishListProductAdminCreate(year, productId, wishListDisplayName);
      
    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getProductDetailFulfilled = createAction<WishListProductAdminResponse>(getProductDetail.fulfilled.type),
  getProductDetailRejected = createAction<ProblemDetails>(getProductDetail.rejected.type),
  updateWishListProductAdminPending = createAction(updateWishListProductAdmin.pending.type),
  updateWishListProductAdminFulfilled = createAction<WishListProductAdminUpdateResponse>(updateWishListProductAdmin.fulfilled.type),
  updateWishListProductAdminRejected = createAction<ProblemDetails>(updateWishListProductAdmin.rejected.type),
  createSizeFulfilled = createAction<CreateSizeResponse>(createSize.fulfilled.type),
  createSizeRejected = createAction<ProblemDetails>(createSize.rejected.type),
  deleteSizeFulfilled = createAction<number>(deleteSize.fulfilled.type),
  deleteSizeRejected = createAction<ProblemDetails>(deleteSize.rejected.type),
  createColourFulfilled = createAction<CreateColourResponse>(createColour.fulfilled.type),
  createColourRejected = createAction<ProblemDetails>(createColour.rejected.type),
  deleteColourFulfilled = createAction<number>(deleteColour.fulfilled.type),
  deleteColourRejected = createAction<ProblemDetails>(deleteColour.rejected.type),
  deleteWishListProductPending = createAction(deleteWishListProduct.pending.type),
  deleteWishListProductFulfilled = createAction<number>(deleteWishListProduct.fulfilled.type),
  deleteWishListProductRejected = createAction<ProblemDetails>(deleteWishListProduct.rejected.type),
  createWishListProductPending = createAction(createWishListProduct.pending.type),
  createWishListProductRejected = createAction<ProblemDetails>(createWishListProduct.rejected.type);

export const wishListProductDetailAdminSlice = createSlice({
  name: 'wish-list-product-detail-admin-slice',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    clearState(state) {
      state.product = null;
      state.error = null;
      state.saving = false;
      state.sizes = [];
      state.colours = [];
    },
    setProduct(state, action: PayloadAction<ProductDetailProduct>) {
      state.product = action.payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(getProductDetailFulfilled, (state, action) => {
        state.sizes = action.payload.sizes;
        state.colours = action.payload.colours;

        const product = action.payload.product,
        sizes = product.sizes.map(size => {
          return {id: size.id, sizeId: size.sizeId, sizeName: size.sizeName, potsPerCase: size.potsPerCase} as ProductDetailSize;
        }).sort(sortBySizeName),
        colours = product.sizes.reduce((memo, size) => {
          size.colours.forEach(c => {
            if(memo.findIndex(m => m.colourId === c.colourId) === -1) {
              const percentage = product.colourRatios.find(r => r.colourId === c.colourId)?.percentage || 0;
              memo.push({colourId: c.colourId, colourName: c.colourName, percentage});
            }
          });
          return memo;
        }, [] as models.WishListProductColourRatio[])
        .sort(sortByColourName),
        productDetail: ProductDetailProduct = {
          id: product.id,
          name: product.productName,
          wishListDisplayName: product.wishListDisplayName,
          productId: product.productId,
          useRatios: product.useRatios,
          useRatiosBySize: product.useRatiosBySize,
          isWeekly: product.isWeekly,
          sizes,
          colours
        };

        state.product = productDetail;
      })
      .addCase(getProductDetailRejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateWishListProductAdminPending, state => {
        state.error = null;
        state.saving = true;
      })
      .addCase(updateWishListProductAdminFulfilled, (state, action) => {
        state.saving = false;
      })
      .addCase(updateWishListProductAdminRejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(createSizeFulfilled, (state, action) => {
        state.sizes.push(action.payload.size);
      })
      .addCase(createSizeRejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteSizeFulfilled, (state, action) => {
        const index = state.sizes.findIndex(s => s.id === action.payload);
        if(index !== -1) {
          state.sizes.splice(index, 1);
        }
      })
      .addCase(deleteSizeRejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(createColourFulfilled, (state, action) => {
        state.colours.push(action.payload.colour);
      })
      .addCase(createColourRejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteColourFulfilled, (state, action) => {
        const index = state.colours.findIndex(s => s.id === action.payload);
        if(index !== -1) {
          state.colours.splice(index, 1);
        }
      })
      .addCase(deleteColourRejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteWishListProductPending, state => {
        state.saving = true;
      })
      .addCase(deleteWishListProductRejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(deleteWishListProductFulfilled, (state, action) => {
        state.saving = false;
      })
      .addCase(createWishListProductPending, state => {
        state.saving = true;
      })
      .addCase(createWishListProductRejected, (state, action) => {
        state.error = action.payload;
      })
});

export const {clearError, clearState, setProduct} = wishListProductDetailAdminSlice.actions;

const sortBySizeName = sortBy('sizeName'),
  sortByColourName = sortBy('colourName'),
  sortByName = sortBy('name');

export const selectError = (state: RootState) => state.wishListProductDetailAdmin.error;
export const selectSaving = (state: RootState) => state.wishListProductDetailAdmin.saving;
export const selectProduct = (state: RootState) => state.wishListProductDetailAdmin.product;

export const selectProductSizes = createSelector(
  selectProduct,
  (product) => {
    return (product?.sizes || [])
      .reduce((memo, size) => {
        if(memo.findIndex(s => s.sizeId === size.sizeId) === -1) {
          memo.push(size);
        }

        return memo;
      }, [] as ProductDetailSize[])
      .map(s => ({...s}))
      .sort(sortProductSize)
  }
);

export const selectSizes = createSelector(
  (state: RootState) => state.wishListProductDetailAdmin.sizes,
  selectProduct,
  (allSizes, product) => {
    const productSizes = (product?.sizes || []).map(s => s.sizeId);
    return allSizes
      .filter(s => productSizes.indexOf(s.id) === -1)
      .map(s => ({...s}))
      .sort(sortSize)
  }
);

export const selectColours = createSelector(
  (state: RootState) => state.wishListProductDetailAdmin.colours,
  selectProduct,
  (allColours, product) => {
    const productColours = (product?.colours || []).map(c => c.colourId);
    return allColours
      .filter(c => productColours.indexOf(c.id) === -1)
      .map(c => ({...c}))
      .sort(sortByName)
  }
);

export default wishListProductDetailAdminSlice.reducer;

function sortSize (a: models.Size, b: models.Size) {
  return sortSizeName(a.name, b.name);
}

function sortProductSize (a: ProductDetailSize, b: ProductDetailSize) {
  return sortSizeName(a.sizeName, b.sizeName);
}