import { createSlice, PayloadAction, createSelector, AsyncThunk, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { wishListApi, AllocationAnalysisResponse } from 'api/wish-list-service';
import * as models from 'api/models/allocation-analysis';
import { sortBy, sortSizeName } from 'utils/sort';
import { ProblemDetails } from 'utils/problem-details';

const sortByName = sortBy('name');

interface AllocationAnalysisState {
  tabId: number;
  productFilter: number[];
  sizeFilter: number[];
  wishListProductSizes: models.AllocationAnalysisWishListProductSize[];
  growingLocations: models.AllocationAnalysisGrowingLocation[];
  allocations: models.AllocationAnalysisAllocation[];
  available: models.AllocationAnalysisAvailable[];
  weeks: models.AllocationAnalysisWeek[];
  error: ProblemDetails | null;
}

const initialState: AllocationAnalysisState = {
  tabId: 0,
  productFilter: [],
  sizeFilter: [],
  wishListProductSizes: [],
  growingLocations: [],
  allocations: [],
  available: [],
  weeks: [],
  error: null
};

export const getAnalysis: AsyncThunk<AllocationAnalysisResponse, number, {state: RootState}> = createAsyncThunk(
  'allocation-analysis/getAnalysis',
  async (year, {rejectWithValue}) => {

    try {
      
      return await wishListApi.allocationAnalysis(year);

    } catch(e) {
      return rejectWithValue(e as ProblemDetails);
    }
  }
);

const getAnalysisFulfilled = createAction<AllocationAnalysisResponse>(getAnalysis.fulfilled.type),
  getAnalysisRejected = createAction<ProblemDetails>(getAnalysis.rejected.type);

const allocationAnalysisSlice = createSlice({
  name: 'allocation-analysis',
  initialState,
  reducers: {
    setTabId(state, {payload}: PayloadAction<number>) {
      state.tabId = payload;
    },
    setProductFilter(state, {payload}: PayloadAction<number[]>) {
      state.productFilter = payload;
    },
    setSizeFilter(state, {payload}: PayloadAction<number[]>) {
      state.sizeFilter = payload;
    },
    setError(state, {payload}: PayloadAction<ProblemDetails | null>) {
      state.error = payload;
    }
  },
  extraReducers: builder =>
    builder
      .addCase(getAnalysisFulfilled, (state, {payload}) => {
        const {analysis} = payload;

        state.wishListProductSizes = analysis.wishListProductSizes;
        state.growingLocations = analysis.growingLocations;
        state.allocations = analysis.allocations;
        state.available = analysis.available;
        state.weeks = analysis.weeks;
      })
      .addCase(getAnalysisRejected, (state, {payload}) => {
        state.error = payload;
      })
});

export const { setTabId, setProductFilter, setSizeFilter, setError } = allocationAnalysisSlice.actions;

export const selectTabId = (state: RootState) => state.allocationAnalysis.tabId;
export const selectProductFilter = (state: RootState) => state.allocationAnalysis.productFilter;
export const selectSizeFilter = (state: RootState) => state.allocationAnalysis.sizeFilter;
export const selectError = (state: RootState) => state.allocationAnalysis.error;
const selectAllAllocations = (state: RootState) => state.allocationAnalysis.allocations;
const selectAllAvailable = (state: RootState) => state.allocationAnalysis.available;
const selectAllWishListProductSizes = (state: RootState) => state.allocationAnalysis.wishListProductSizes;

export interface FilterItem {
  id: number;
  name: string;
}

export const selectProducts = createSelector(
  selectAllWishListProductSizes,
  wishListProductSizes => wishListProductSizes.reduce((memo, ps) => {
    if(!memo.some(m => m.id === ps.wishListProductId)) {
      memo.push({id: ps.wishListProductId, name: ps.productName});
    }
    return memo;
  }, [] as FilterItem[])
  .sort(sortByName)
);
export const selectSizes = createSelector(
  selectAllWishListProductSizes,
  selectProductFilter,
  (wishListProductSizes, productFilter) => wishListProductSizes
      .filter(a => !productFilter.length || productFilter.indexOf(a.wishListProductId) !== -1)
      .map(a => ({id: a.sizeId, name: a.sizeName}))
    .reduce((memo, p) => {
      if(!memo.find(m => m.id === p.id)) {
        memo.push(p);
      }
      return memo;
    }, [] as FilterItem[])
    .sort((a, b) => sortSizeName(a.name, b.name))
);
export const selectWishListProductSizes = createSelector(
  selectAllWishListProductSizes,
  selectProductFilter,
  selectSizeFilter,
  (wishListProductSizes, productFilter, sizeFilter) => wishListProductSizes
    .filter(a => !productFilter.length || productFilter.indexOf(a.wishListProductId) !== -1)
    .filter(a => !sizeFilter.length || sizeFilter.indexOf(a.sizeId) !== -1)
    .sort((a, b) => sortByName(a.productName, b.productName) || sortSizeName(a.sizeName, b.sizeName))
);


export default allocationAnalysisSlice.reducer;
