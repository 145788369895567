import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Loading() {
  return (
    <div className="mt-4 mx-auto text-center text-primary">
      <FontAwesomeIcon icon={['fad', 'spinner']} size="4x" spin />
      <h1 className="text-center text-primary mt-3">Loading&hellip;</h1>
    </div>
  );
}
